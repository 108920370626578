/* eslint-disable @typescript-eslint/no-empty-function */

import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import {
  email,
  iban,
  minLength,
  noNumbers,
  numbersOnly,
  phone,
  RangeValidator,
  required,
  requiredTrue,
  MinValidator,
  zipcode,
  maxLength,
  minMaxLength,
  requiredConditionalOr,
  requiredConditionalAnd,
  date,
  tin,
  password,
  dateBeforeOrAfter,
  total,
  exprInvalidIf,
  prohibitedNameValidator,
} from '@semmie/validators';
import { DynamicField, iDynamicForm, iGroupFormField } from '@semmie/schemas/components/dynamic-form';
import { JmespathService } from '@semmie/services/jmespath/jmespath.service';
import { EerService } from '@semmie/services/eer/eer.service';
import { Utils } from '@onyxx/utility/general';
import { Parser } from '@semmie/shared/parser';
import { DynamicFieldType } from '@semmie/schemas/components/dynamic-form/dynamic-form-types';
import {
  BackendError,
  iBackendFieldResponseDetails,
  iBackendFieldsErrorResponse,
} from '@semmie/schemas/bi/error/backend-field-error-response.schema';
import { InterpolateService } from '@semmie/services/interpolate/interpolate.service';
import { iDynamicFormValidations } from '@semmie/schemas/components/dynamic-form/dynamic-form-validations';
import { EerValidation } from '@onyxx/model/validation';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  readonly VALIDATORS = {
    exprinvalidif: exprInvalidIf,
    required,
    requiredtrue: requiredTrue,
    requiredconditionalor: requiredConditionalOr,
    requiredconditionaland: requiredConditionalAnd,
    minlength: minLength,
    maxlength: maxLength,
    minmaxlength: minMaxLength,
    numbersonly: numbersOnly,
    nonumbers: noNumbers,
    prohibitednamevalidator: prohibitedNameValidator,
    range: this.rangeValidator.range.bind(this.rangeValidator),
    min: this.minValidator.min.bind(this.minValidator),
    email,
    iban,
    tin,
    phone,
    zipcode,
    date,
    datebeforeorafter: dateBeforeOrAfter,
    password,
    total,
  } as const;

  constructor(
    private interpolateService: InterpolateService,
    private jmespathService: JmespathService,
    private eerService: EerService,
    private rangeValidator: RangeValidator,
    private minValidator: MinValidator,
  ) {}

  /**
   * Transform data through projections.
   *
   * The following projections are available:
   *  - unique: only return unique results in the data
   *
   * @param data the datasource to perform the projection(s) on
   * @param projections list of projection(s) that the data should go through
   * @returns the transformed data
   */
  parseProjections(data: any[], projections: string[]) {
    let dataCpy = ([] as any[]).concat(data);

    if (projections) {
      projections.forEach((projection) => {
        switch (projection.toLowerCase()) {
          case 'unique':
            dataCpy = data.filter((v, i, a) => a.findIndex((dv) => dv.value === v.value) === i);
            break;
        }
      });
    }

    return dataCpy;
  }

  /**
   * Parses validations and returns an Array of interpolated ValidatorFns.
   *
   * Used for form fields.
   *
   * @param validations an array of string validations
   * @param data the data to use for interpolation
   * @returns an Array of ValidatorFn
   */
  parseValidationRules(validations: Array<string | iDynamicFormValidations>, data: Record<string, unknown>): Array<ValidatorFn> {
    if (!validations?.length) return [];

    const rules: ValidatorFn[] = [];
    const functions = Parser.parseFunctions(validations);

    functions.forEach((rule) => {
      let params: Array<string | number | null> | undefined = undefined;

      if (rule.params) {
        params = rule?.params.map((p) => {
          if (typeof p !== 'number') {
            return this.jmespathService.interpolate(data, String(p)) ?? null;
          }

          return p;
        });
      }
      const validationRule: ValidatorFn = this.VALIDATORS[rule.name?.toLowerCase() ?? '']?.(...(params ?? ''));

      if (validationRule !== undefined) {
        rules.push(validationRule);
      }
    });

    return rules;
  }

  /**
   * Parses validations and returns an Array of interpolated ValidatorFns.
   *
   * Used for FormPage-wide validation.
   *
   * @param validations an array of string validations
   * @param data the data to use for interpolation
   * @returns an Array of ValidatorFn
   */
  parseFormValidationRules(validations: Array<string>, data: Record<string, unknown>): any {
    if (!validations?.length) return [];

    const rules = {};
    const functions = Parser.parseFunctions(validations);

    functions.forEach((rule) => {
      if (rule.params) {
        rule.params = rule?.params.map((p) => {
          if (typeof p !== 'number') {
            return this.jmespathService.interpolate(data, String(p)) ?? null;
          }

          return p;
        });
      }

      switch (rule.name) {
        case EerValidation.Address:
          Object.assign(rules, { [EerValidation.Address]: this.eerService.validateAddress(...(rule.params ?? '')) });
          break;
        case EerValidation.Nationality:
          Object.assign(rules, { [EerValidation.Nationality]: this.eerService.validateNationality(...(rule.params ?? '')) });
          break;
      }
    });

    return rules;
  }

  /**
   * Returns the evaluation of all validations.
   *
   * @param validations an array of validation rules
   * @returns a boolean that sigifies whether all validations are met
   */
  evalFormValidationRules(validations: any): boolean {
    return Object.entries(validations).some(([, value]) => {
      if (!value) {
        return true;
      }
    });
  }

  /**
   * Check if conditions are met by comparing the expression(s) to the data.
   *
   * @param conditions an Array of string expressions
   * @returns boolean - the result of the expression(s)
   */
  meetsConditions(conditions?: Array<string>, data?: any): boolean {
    if (!conditions) return true;

    const result = conditions.map((expression) => {
      return this.jmespathService.interpolate(data, expression);
    });

    return result.every((r) => !!r);
  }

  conditionValueChanged(conditions?: Array<string>, prev?: any, curr?: any): boolean {
    if (!conditions) return false;

    const old = this.meetsConditions(conditions, prev);
    const latest = this.meetsConditions(conditions, curr);

    return !Utils.isEqual(old, latest);
  }

  /**
   * Translate and interpolate the field property.
   *
   * This block supports multiple use-cases.
   *
   * 1. If the property is not an object, then simply return the translation of the property.
   * 2. If the property is an object, then the property must contain a list of keys, which are
   * values of the reference the expression is to be compared to, and the value, which is a
   * translation key to be translated. The aforementioned expression is the `param`,
   * which is necessary to make the comparison.
   *
   * For #2, consider the following use-case:
   * Assume the label of a field should respond to data. For example when a textbox should respond
   * to the selected country and should show the country in the label.
   *
   * Defining the 'label' property as such:
   *
   * ```json
   * {
   *   "label": {
   *     "translation": {
   *       "BEL": "core.fields.tin.label.BEL",
   *       "default": "core.fields.tin.label.default"
   *     },
   *     "param": "country_tax.iso3"
   *   }
   * }
   * ```
   *
   * When `country_tax.iso3` equals 'BEL', the value will be a interpolated translation of its value.
   *
   * @param property property to translate
   * @returns translated and interpolated property
   */
  translateFieldProperty(property: string, field: DynamicField | iDynamicForm, data: any): string {
    const fieldProp = field[property];

    if (!Utils.isObject(fieldProp)) {
      if (Utils.isNil(fieldProp) || fieldProp === '') return fieldProp as string;
      return fieldProp;
    }

    const translationKeys = fieldProp?.translation;
    const translationParam = fieldProp?.param ?? '';
    const dataValue = translationParam ? this.jmespathService.interpolate(data, translationParam) : null;
    const translationKey = Object.keys(translationKeys).find((k) => k == dataValue) ?? 'default';

    return fieldProp?.translation[translationKey];
  }

  /**
   * Translate and interpolate the field property.
   *
   * This block supports multiple use-cases.
   *
   * 1. If the property is not an object, then simply return the translation of the property.
   * 2. If the property is an object with a path, return the interpolated path.
   * 3. If the property is an object with a translation, then the property must contain a list of keys, which are
   * values of the reference the expression is to be compared to, and the value, which is a
   * translation key to be translated. The aforementioned expression is the `param`,
   * which is necessary to make the comparison.
   * 4. If the property is an object with an expression, return the interpolated expression.
   *
   * @param property property to translate
   * @returns translated and interpolated property
   */
  interpolateFieldProperty(property: string, field: DynamicField | iDynamicForm, data: any): string | undefined {
    const fieldProp = field[property];

    if (!Utils.isObject(fieldProp)) {
      if (Utils.isNil(fieldProp) || fieldProp === '') return fieldProp as string;
      const interpolatedProp = this.interpolateService.renderString(fieldProp, data);
      return interpolatedProp;
    }

    if (fieldProp?.path) {
      return this.jmespathService.interpolate(data, fieldProp.path);
    } else if (fieldProp.translation) {
      return this.interpolateService.renderString(this.translateFieldProperty(property, field, data), data);
    } else if (fieldProp?.expression) {
      return this.interpolateService.renderString(fieldProp?.expression, data);
    }
  }

  /**
   * Returns the field type, which may remap types to render the appropriate field component.
   *
   * @returns the field type
   */
  getFieldType(field: DynamicField): string {
    switch (field.type) {
      case DynamicFieldType.Group:
        return field.layout ?? field.type;
    }

    return field.type;
  }

  /**
   * Returns the default field value depending on the type
   *
   * @returns the default field value
   */
  getDefaultFieldValueByType(type: string) {
    switch (type) {
      case DynamicFieldType.Checkbox:
        return [];
      case DynamicFieldType.Toggle:
        return false;
      default:
        return null;
    }
  }

  /**
   * Returns the FormGroup.
   *
   * @param field group field
   * @param form FormGroup
   * @returns UntypedFormGroup
   */
  getFormGroup(field: iGroupFormField, form: UntypedFormGroup): UntypedFormGroup {
    return (field as iGroupFormField)?.nested ? (form.get(field.name) as UntypedFormGroup) : form;
  }

  /**
   * Returns the FormArray.
   *
   * @param field group field
   * @param form FormGroup
   * @returns UntypedFormArray
   */
  getFormArray(field: iGroupFormField, form: UntypedFormGroup): UntypedFormArray {
    return form.get(field.name) as UntypedFormArray;
  }

  /**
   * Returns the FormControl.
   *
   * @param field field definition
   * @param form FormGroup
   * @param group group field
   * @returns UntypedFormControl
   */
  getFormControl(field: DynamicField, form: UntypedFormGroup, group?: iGroupFormField): UntypedFormControl | null {
    const groupField = group ?? field;
    const formGroup = this.getFormGroup(groupField, form);
    return formGroup.get(group?.repeat ? groupField.name : field.name) as UntypedFormControl | null;
  }

  /**
   * Returns the FormControl by index.
   *
   * @param groupField group field definition
   * @param field field definition
   * @param form FormGroup
   * @param index index
   * @returns UntypedFormControl
   */
  getFormControlByIndex(groupField: DynamicField, field: DynamicField, form: UntypedFormGroup, index: number): UntypedFormControl {
    const formArray = this.getFormArray(groupField, form);
    return formArray.at(index).get(field.name) as UntypedFormControl;
  }

  /**
   * Check if the field is a peristent data field.
   *
   * @param field field definition
   * @returns boolean
   */
  isPersistentDataField(field: DynamicField): boolean {
    return [DynamicFieldType.ExternalData, DynamicFieldType.Static].includes(field.type);
  }

  /**
   * Check if the field is a static field.
   *
   * @param field field definition
   * @returns boolean
   */
  isStaticField(field: DynamicField) {
    return [DynamicFieldType.ExternalData, DynamicFieldType.Static, DynamicFieldType.Label].includes(field.type);
  }

  /**
   * Handles and returns a translated string of the backend error
   *
   * @param fieldName field name
   * @param errors backend error
   */
  handleBackendErrors(fieldName: string, errorResponse: iBackendFieldsErrorResponse) {
    for (const key in errorResponse.details) {
      const errorMessage = this.getBackendErrorTranslations(fieldName, key, errorResponse.details[key]);
      if (Utils.isNotNil(errorMessage)) {
        return errorMessage;
      }
    }
    return null;
  }

  private getBackendErrorTranslations(fieldName: string, key: string, errorDetails: Array<iBackendFieldResponseDetails>) {
    let errorKey = key;
    // Get first error detail
    const errorDetail = errorDetails[0];

    // BE returns address.zipcode, address.street_number, etc. but the form only has zipcode, street_number, etc.
    if (errorKey.includes('address.')) {
      errorKey = key.replace('address.', '');
    }

    if (fieldName === errorKey) {
      switch (errorDetail.error) {
        case BackendError.Blank:
          return $localize`:@@validation.backend.blank:This field cannot be empty.`;
        case BackendError.DateAfterOrEqualTo:
          return $localize`:@@validation.backend.date-after-or-equal-to:Date must be after or equal to ${errorDetail.date}.`;
        case BackendError.DateBeforeOrEqualTo:
          return $localize`:@@validation.backend.date-before-or-equal-to:Date must be before or equal to ${errorDetail.date}.`;
        case BackendError.Invalid:
          return $localize`:@@validation.backend.invalid:Invalid value.`;
        case BackendError.InvalidBsn:
          return $localize`:@@validation.backend.invalid-bsn:The Citizen Service Number (BSN) you entered is not valid.`;
        case BackendError.InvalidZipCode:
          return $localize`:@@validation.backend.invalid-zipcode:Invalid zip code.`;
        case BackendError.NotADate:
          return $localize`:@@validation.backend.not-a-date:Invalid date.`;
        case BackendError.NotANumber:
          return $localize`:@@validation.backend.not-a-number:Value must be a number.`;
        case BackendError.TooLong:
          return $localize`:@@validation.backend.too-long:This field must contain at most ${errorDetail.count} characters.`;
        case BackendError.TooShort:
          return $localize`:@@validation.backend.too-short:This field must contain at least ${errorDetail.count} characters.`;
        default:
          return $localize`:@@validation.backend.default:The value seems incorrect: ${errorDetail.error}.`;
      }
    }
  }
}
