import { Injectable } from '@angular/core';
import { Organisation } from '@semmie/models/bi/organisation/organisation.model';
import { UploadDocument } from '@semmie/models/bi/upload-document/upload-document.model';
import { BaseProvider } from '@semmie/providers/_abstract/base-provider';
import { iOrganisationPerson } from '@semmie/schemas';
import { OrganisationPersonRole, iOrganisation } from '@semmie/schemas/bi/organisation';
import { iUploadDocument } from '@semmie/schemas/bi/upload-document/upload-document.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OrganisationProvider extends BaseProvider<iOrganisation> {
  constructor() {
    super('organisations');
  }

  get(id: string): Observable<Organisation> {
    // eslint-disable-next-line no-constant-binary-expression -- legacy code
    return super.__query(`organisations/${id}`).pipe(map((response) => new Organisation(response.organisation) || null));
  }

  getOrganisationDocuments(id: string): Observable<UploadDocument[]> {
    return super.__query<{ documents: iUploadDocument[] }>(`organisations/${id}/documents`).pipe(
      map((response) => response?.documents ?? []),
      map((documents) => documents.map((d) => new UploadDocument(d))),
    );
  }

  list(): Observable<Organisation[]> {
    return super.__list('organisations', {}).pipe(
      map((response) => {
        const organisations = response?.organisations || null;
        organisations?.map((o: iOrganisation) => new Organisation(o));
        return organisations;
      }),
    );
  }

  create(accountId: string, kvk_number: string): Observable<Organisation> {
    const payload = {
      organisation: {
        kvk_number,
      },
    };
    return super.__post(`accounts/${accountId}/organisations`, payload).pipe(
      map((response) => {
        return new Organisation(response?.organisation);
      }),
    );
  }

  createSubOrganisation(organisationId: string, kvk_number: string): Observable<Organisation> {
    const payload = {
      organisation: {
        kvk_number,
      },
    };
    return super.__post(`organisations/${organisationId}/organisations`, payload).pipe(
      map((response) => {
        return new Organisation(response?.organisation);
      }),
    );
  }

  createOrganisationPerson(organisationId: string, role: OrganisationPersonRole, personId?: string): Observable<iOrganisationPerson> {
    const payload = {
      organisation_person: {
        person: personId
          ? {
              id: personId,
            }
          : undefined,
        role,
      },
    };
    return super.__post(`organisations/${organisationId}/organisation_people`, payload).pipe(map((op) => op.organisation_person));
  }

  updateOrganisationPerson(
    organisationId: string,
    organisationPersonId: string,
    organisation_person: Partial<iOrganisationPerson>,
  ): Observable<boolean> {
    return super
      .__patch(`organisations/${organisationId}/organisation_people/${organisationPersonId}`, { organisation_person })
      .pipe(map(() => true));
  }

  deleteOrganisationPerson(organisationId: string, personId: string): Observable<boolean> {
    return super.__delete(`organisations/${organisationId}/organisation_people/${personId}`).pipe(map(() => true));
  }

  update(organisationId: string, organisation: Partial<Organisation>): Observable<Organisation> {
    return super.__patch(`organisations/${organisationId}`, { organisation }).pipe(
      map((response) => {
        return new Organisation(response?.organisation);
      }),
    );
  }

  refresh(organisationId: string): Observable<Organisation> {
    return super.__patch(`organisations/${organisationId}/refresh`, { organisation: {} }).pipe(
      map((response) => {
        return new Organisation(response?.organisation);
      }),
    );
  }

  finish(organisationId: string): Observable<Organisation> {
    return super.__patch(`organisations/${organisationId}/finish`, null).pipe(
      map((response) => {
        return new Organisation(response?.organisation);
      }),
    );
  }
}
