import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { personFeature } from './person.reducer';
import { personApiActions } from './person-api.actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Person } from '@onyxx/model/person';
import { Actions, ofType } from '@ngrx/effects';
import { UserStoreFacade } from '@semmie/store/user';
import { personCommonActions } from './person-common.actions';

@Injectable({
  providedIn: 'root',
})
export class PersonStoreFacade {
  readonly store = inject(Store);
  readonly actions = inject(Actions);
  readonly userStoreFacade = inject(UserStoreFacade);

  readonly person$ = this.store.select(personFeature.selectPerson);

  readonly editBusy$ = this.store.select(personFeature.selectEditBusy);
  readonly editSuccess$ = this.actions.pipe(ofType(personApiActions.editSuccess));

  /**
   * Edit the detail of the person associated with teh current user.
   */
  dispatchEdit(person: Partial<Person>) {
    this.store.dispatch(personApiActions.edit({ person }));
  }

  /**
   * Update the NgRx store with person data. It will only update if the person passed
   * has the same id as the person currently in the store
   * @param person Person to update
   */
  dispatchUpdateInStore(person: Person) {
    this.store.dispatch(personCommonActions.checkToUpdateInStore({ id: person.id, person }));
  }
}
