import { createFeature, createReducer } from '@ngrx/store';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface State {}

const initialState: State = {};

const reducer = createReducer(initialState);

export const boostersFeature = createFeature({
  name: 'boosters',
  reducer,
});
