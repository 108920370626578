import { Component, Host, Input, Optional } from '@angular/core';
import { ImpactStyle } from '@capacitor/haptics';

import { CheckboxGroupComponent } from '@semmie/components/presentational/core/checkbox-group/checkbox-group.component';
import { BaseComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';

@Component({
  selector: 'semmie-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: false,
})
export class CheckboxComponent extends BaseComponent {
  /** Disables the field and makes it read-only. */
  @Input() isDisabled: boolean;

  /** The display text of the field */
  @Input() label: string;

  /** The display description of the field */
  @Input() description: string;

  /** The name of the form control in the parent component */
  @Input() name: string;

  /** The value of this specific radio button (not the form control value) */
  @Input() value: any;

  @Input() layout?: string;

  readonly Icon = Icon;

  get checked() {
    return this.checkGroup.value?.includes(this.value);
  }

  constructor(
    @Optional() @Host() public checkGroup: CheckboxGroupComponent,
    private hapticFeedbackService: HapticFeedbackService,
  ) {
    super();
  }

  toggleButton(): void {
    if (this.isDisabled) return;
    this.hapticFeedbackService.interact(ImpactStyle.Light);
    if (this.checkGroup && !this.checkGroup.value) {
      this.checkGroup.value = [];
    }
    if (this.checkGroup.value.includes(this.value)) {
      this.checkGroup.value = this.checkGroup.value.filter((value) => value !== this.value);
    } else {
      this.checkGroup.value = [...this.checkGroup.value, this.value];
    }
  }
}
