import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Optional, Self, SimpleChanges, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RangeCustomEvent } from '@ionic/angular';
import { Utils } from '@onyxx/utility/general';

import { BaseFormComponent } from '@semmie/components/_abstract';
import { SemmiePercentagePipe } from '@semmie/pipes/percentage/percentage.pipe';
import { iSliderFormField } from '@semmie/schemas/components/dynamic-form';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'semmie-slider',
  template: `<div class="input-wrapper">
    <div class="flex">
      <label *ngIf="label">
        <semmie-label [text]="label"></semmie-label>
      </label>
      <div class="value" [class.negative]="layout === 'negative-percentage' && value > 0">
        <semmie-label [text]="displayValue$ | async" align="right"></semmie-label>
      </div>
    </div>

    <ion-range [min]="min" [max]="max" [snaps]="true" (ionInput)="getDisplayValue($any($event))" [(ngModel)]="value"></ion-range>
  </div>`,
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SliderComponent extends BaseFormComponent implements OnInit, OnChanges {
  @ViewChild('input', { static: true, read: NgControl }) input: NgControl;

  /**
   * Sets the label of the field.
   */
  @Input() label?: string;
  @Input() layout: iSliderFormField['layout'];
  @Input() options: iSliderFormField['options'];

  @Input() get min(): iSliderFormField['min'] {
    return this._min;
  }

  set min(value: number | null | undefined) {
    if (Utils.isNotNil(value)) {
      this._min = value;
    } else {
      this._min = 0;
    }
  }

  @Input() get max(): iSliderFormField['max'] {
    return this._max;
  }

  set max(value: number | null | undefined) {
    if (Utils.isNotNil(value)) {
      this._max = value;
    } else {
      this._max = 100;
    }
  }

  displayValue$$: BehaviorSubject<string> = new BehaviorSubject('');
  displayValue$ = this.displayValue$$.asObservable();

  private _min: number;
  private _max: number;

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private semmiePercentagePipe: SemmiePercentagePipe,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getDisplayValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.options) {
      this.setDefaultValue(changes?.options?.currentValue);
    }
  }

  getDisplayValue(event?: RangeCustomEvent) {
    const value = event?.detail?.value ?? this.value;

    switch (this.layout) {
      case 'percentage':
        this.displayValue$$.next(this.semmiePercentagePipe.transform(value));
        break;
      case 'negative-percentage':
        this.displayValue$$.next(this.semmiePercentagePipe.transform(`-${value}`));
        break;
      case 'text':
        this.displayValue$$.next((this.options?.find((o) => o.value === value)?.label || this.options?.[0]?.label) ?? '');
        break;
    }
  }

  private setDefaultValue(options: iSliderFormField['options']): void {
    if (Utils.isNotNil(this.value)) return;

    let value = 0;

    if (Array.isArray(options) && Utils.arrayIsNotEmpty(options)) {
      value = options[0].value;
    }

    this.value = value;
  }
}
