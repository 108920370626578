import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { Referral } from '@semmie/models/bi/referral/referral.model';
import { ReferralState } from '@semmie/schemas/bi/referral';
import { RewardKind } from '@semmie/schemas/bi/reward';
import { CardModule } from '@semmie/components/containers/card/card.module';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { ProgressCircleComponent } from '@semmie/components/presentational/core/progress-circle/progress-circle.component';
import { CurrencyModule } from '@semmie/pipes/currency/currency-pipe.module';
import { SharedModule } from '@semmie/shared/shared.module';
import { ContentToStringComponent } from '@onyxx/ui/content-to-string';

@Component({
  imports: [SharedModule, CardModule, ProgressCircleComponent, LabelModule, CurrencyModule, ContentToStringComponent],
  selector: 'semmie-referral-card',
  templateUrl: 'referral-card.component.html',
  styleUrls: ['./referral-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferralCardComponent extends BaseComponent {
  @Input() referral: Referral;
  readonly ReferralState = ReferralState;
  readonly RewardKind = RewardKind;
  readonly referralStateLabel$$ = signal<string | null>(null);
  readonly rewardLabel$$ = signal<string | null>(null);
}
