import { createActionGroup, emptyProps, props } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Person } from '@onyxx/model/person';

export const personCommonActions = createActionGroup({
  source: 'person common',
  events: {
    Initialize: emptyProps(),
    Clear: emptyProps(),
    'Check to update in store': props<{ id: string; person: Person }>(),
    'Update in store': props<{ person: Person }>(),
  },
});
