import { Injectable } from '@angular/core';

import { EMPTY, Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { Goal, Person } from '@semmie/models';
import { Account, AccountAdditionalData, AccountHelpers } from '@onyxx/model/account';

import { AccountPerson } from '@semmie/models/bi/person/account-person';
import { iUser } from '@onyxx/model/user';

import { BaseOnboardingService, NavigationCallback } from './__abstract/base-onboarding.service';
import { Utils } from '@semmie/shared/utils';
import { CommonQueryParams } from '@semmie/views/shared/common-query-params.enum';
import { InvitationStep } from '@semmie/models/bi/invitation';
import { MainRouteNames } from '@onyxx/model/main';
import { OnboardingRouteNames } from '@semmie/views/onboarding/onboarding.common';

@Injectable({
  providedIn: 'root',
})
export class ChildOnboardingService extends BaseOnboardingService {
  private child: Person;

  override initialize(
    account: Account,
    navigateCallback: NavigationCallback,
    person: Person,
    user: iUser,
    goal?: Goal,
    additionalData?: AccountAdditionalData,
  ): void {
    this.account = account;
    this.person = person;
    this.user = user;
    this.accountAdditionalData = additionalData;

    this.handleAdvisorOnboarding();

    // because of the previous line
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const accountPerson = this.account.people.find((p) => p.id === this.user!.person?.id);
    this.accountPerson = new AccountPerson(accountPerson);

    const child = AccountHelpers.child(this.account);

    this.personService
      .getPersonById(child?.id, false, false)
      .pipe(take(1), filter(Utils.isNonNullOrUndefined))
      .subscribe((child) => {
        this.child = child;
        this.handleOnboarding(navigateCallback);
      });
  }

  handleOnboarding(navigateCallback: NavigationCallback): void {
    this.hasFinishedAccount()
      .pipe(
        switchMap((finished) => this.handleFinishedAccount(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedPerson(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedIdentification(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedChildData(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedQuestionnaire(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedStrategy(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedPlan(navigateCallback, finished)),
        switchMap((finished) => {
          if (this.accountPerson.isInvitee()) {
            return this.inviteeOnboarding(navigateCallback, finished);
          } else {
            return this.initiatorOnboarding(navigateCallback, finished);
          }
        }),
      )
      .subscribe(() => {
        navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.AccountCreated]);
      });
  }

  handleFinishedIdentification(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      navigateCallback(
        [
          ...this.onboardingPathPrefix(this.account.id),
          OnboardingRouteNames.Identification,
          {
            step: 'identification_type',
          },
        ],
        {
          queryParams: this.isAdvisorOnboarding
            ? {
                [CommonQueryParams.PersonId]: this.person?.id,
              }
            : undefined,
        },
      );

      return EMPTY;
    }

    return this.hasFinishedChildData();
  }

  handleFinishedChildData(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      navigateCallback([
        ...this.onboardingPathPrefix(this.account.id),
        OnboardingRouteNames.Child,
        this.child.id,
        {
          step: this.currentStep.value,
        },
      ]);

      return EMPTY;
    }

    return this.hasFinishedQuestionnaire();
  }

  handleFinishedPlan(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('plan');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Plan], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    if (this.accountPerson.isInvitee()) {
      return this.hasBeenWidValidated();
    }

    return this.hasFinishedFirstPayment();
  }

  handleInvitationCompleted(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('invite');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Invitation, { step: InvitationStep.INVITE }], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    if (this.accountAdditionalData?.skipInvitation) return this.hasFinishedSigning('me');

    return this.hasFinishedSigning('other');
  }

  handleInviteeHasSigned(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (this.accountAdditionalData?.skipInvitation) return this.hasFinishedSummary();

    if (!finished) {
      if (this.account.invitation?.id) {
        navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Invitation, this.account.invitation.id], {
          clearOnboardingNavigationStack: true,
        });
      } else {
        navigateCallback([this.onboardingPathPrefix(this.account.id), MainRouteNames.Onboarding, this.account.id]);
      }

      return EMPTY;
    }

    return this.hasFinishedSummary();
  }

  handleCompletedWidPayment(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'wid' }], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return this.hasFinishedSummary();
  }

  override handleFinishedFirstPayment(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    if (!finished) {
      this.currentStep.next('payment');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'first' }], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return this.hasFinishedSendingInvitation();
  }

  private hasFinishedChildData(): Observable<boolean> {
    if (!this.child.name_casual || !this.child.birth_at || !this.child.gender) {
      this.currentStep.next('basic');
    } else if (!this.child.hasCompletedAddress()) {
      this.currentStep.next('address');
    } else if (!this.child.hasCompletedNationality(this.multiLingualFeatures$$().relationNLorBE) || !this.child.question_tax_netherlands) {
      this.currentStep.next('nationality');
    } else if (!this.child.hasCompletedBsn() || !this.child.finished_at) {
      this.currentStep.next('details');
    } else {
      this.currentStep.next(null);
    }

    return of(!!this.child.finished_at);
  }

  private initiatorOnboarding(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    return this.handleFinishedFirstPayment(navigateCallback, finished).pipe(
      switchMap((finished) => this.handleFinishedSendingInvitation(navigateCallback, finished)),
      switchMap((finished) => this.handleInvitationCompleted(navigateCallback, finished)),
      switchMap((finished) => this.handleInviteeHasSigned(navigateCallback, finished)),
      switchMap((finished) => this.handleFinishedSummary(navigateCallback, finished)),
      switchMap((finished) => this.handleFinishedSigning(navigateCallback, finished)),
    );
  }

  private inviteeOnboarding(navigateCallback: NavigationCallback, finished: boolean): Observable<any> {
    return this.handleCompletedWidPayment(navigateCallback, finished).pipe(
      switchMap((finished) => this.handleFinishedSummary(navigateCallback, finished)),
      switchMap((finished) => this.handleFinishedSigning(navigateCallback, finished)),
    );
  }
}
