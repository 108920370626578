import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextarea as PrimeInputTextarea } from 'primeng/inputtextarea';

import { TextareaComponent } from './textarea.component';
import { FormInputLabelComponent } from '@semmie/components/containers/form-input-label/form-input-label.component';

@NgModule({
  declarations: [TextareaComponent],
  imports: [CommonModule, FormsModule, PrimeInputTextarea, FormInputLabelComponent],
  exports: [TextareaComponent],
})
export class TextareaModule {}
