/** The bank codes should be the first 4 digits of the bank Swift/BIC */
export enum BankCode {
  ABNA,
  ASNB,
  BUNQ,
  INGB,
  KNAB,
  MOYO,
  RABO,
  RBRB,
  SNSB,
  TRIO,
  FVLB,
  HAND,
  REVO,
  NNBA,
  // Belgium banks
  BELF,
  BNPB,
  BPOST,
  CENT,
  CITI,
  FINT,
  KBCB,
  ARSP,
  NICA,
  BBRU,
  DELE,
  AXAB,
  CTBK,
  BSCH,
  KEYT,
  // German banks
  NTSB,
  DEFAULT,
}
