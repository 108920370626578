import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { appLoadingStatusFeature } from './app-loading-status.reducer';
import { appLoadingStatusCommonActions } from './app-loading-status-common.actions';

@Injectable({
  providedIn: 'root',
})
export class AppLoadingStatusStoreFacade {
  readonly store = inject(Store);

  readonly loading$ = this.store.select(appLoadingStatusFeature.selectLoading);

  dispatchDoneLoading() {
    this.store.dispatch(appLoadingStatusCommonActions.updateLoading({ loading: false }));
  }
  dispatchShowLoading() {
    this.store.dispatch(appLoadingStatusCommonActions.updateLoading({ loading: true }));
  }

  dispatchHideSplashScreen() {
    this.store.dispatch(appLoadingStatusCommonActions.hideSplashscreen());
  }
}
