import { unAuthenticatedGuard } from '@onyxx/feature/auth';
import { UnauthenticatedAreaModule } from './unauthenticated-area.module';
import { unauthenticatedAreaRoutes } from './unauthenticated-area.routes';
import { areaMatcher } from '../../area-matcher';
import { Route } from '@angular/router';

export const unauthenticatedArea = (): Route => {
  return {
    matcher: areaMatcher(unauthenticatedAreaRoutes),
    loadChildren: () => UnauthenticatedAreaModule,
    canActivate: [unAuthenticatedGuard()],
  };
};
