export enum Bic {
  ABNA = 'ABNANL2A',
  ASNB = 'ASNBNL21',
  BUNQ = 'BUNQNL2A',
  INGB = 'INGBNL2A',
  KNAB = 'KNABNL2H',
  MOYO = 'MOYONL21',
  RABO = 'RABONL2U',
  RBRB = 'RBRBNL21',
  SNSB = 'SNSBNL2A',
  TRIO = 'TRIONL2U',
  FVLB = 'FVLBNL22',
  HAND = 'HANDNL2A',
  REVO = 'REVOLT21',
  NNBA = 'NNBANL2G',
  BELF = 'GKCCBEBB',
  BNPB = 'GEBABEBB',
  BPOST = 'BPOTBEB1',
  CITI = 'CITINL2X',
  KBCB = 'KREDBEBB',
  ARSP = 'ARSPBE22',
  NICA = 'NICABEBB',
  BBRU = 'BBRUBEBB',
  DELE = 'DELEBE22NRA',
  AXAB = 'AXABBE22',
  CTBK = 'CTBKBEBX',
  BSCH = 'BSCHBEBB',
  KEYT = 'KEYTBEBB',
  NTSB = 'NTSBDEB1',
}
