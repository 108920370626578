import { ChangeDetectionStrategy, Component, inject, signal, ViewChild } from '@angular/core';
import { PlatformService } from '@semmie/services';
import { Illustration } from '@semmie/shared/globals';
import { IntroContentModule } from '../../components/containers/intro-content/intro-content.module';
import { IonicModule } from '@ionic/angular';
import { firstValueFrom, interval, map, mergeMap, switchMap, takeWhile, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MaintenanceProvider } from '@semmie/providers/maintenance/maintenance.provider';
import { UserStoreFacade } from '@semmie/store/user';
import { ChatService } from '@semmie/services/chat';
import { IntroContentComponent } from '@semmie/components';
import { ActivatedRoute } from '@angular/router';
import { ConfigProvider } from '@semmie/providers';

export enum MaintenanceComponentParams {
  Mode = 'mode',
}

export enum MaintenanceComponentModes {
  Manual = 'manual',
}
@Component({
  selector: 'semmie-maintenance',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ion-content>
      <semmie-intro-content
        i18n-label="@@maintenance.subtitle"
        label="Important"
        i18n-title="@@maintenance.title"
        title="Scheduled maintenance"
        [description]="description$$()"
        [image]="Image"
        i18n-button="@@maintenance.button"
        button="Try again"
        [buttonLoading]="buttonLoading$$()"
        (onButtonClick)="onButtonClickHandler()"
      ></semmie-intro-content>
    </ion-content>
  `,
  imports: [IonicModule, IntroContentModule],
})
export class MaintenanceComponent {
  @ViewChild(IntroContentComponent) introContent: IntroContentComponent;

  private readonly chatService = inject(ChatService);
  private readonly configProvicer = inject(ConfigProvider);
  private readonly maintenanceProvider = inject(MaintenanceProvider);
  private readonly platformService = inject(PlatformService);
  private readonly userFacade = inject(UserStoreFacade);
  private readonly activatedRoute = inject(ActivatedRoute);

  private readonly isManual$ = this.activatedRoute.queryParams.pipe(
    map((params) => params[MaintenanceComponentParams.Mode] === MaintenanceComponentModes.Manual),
  );
  private readonly inMaintenance$ = this.isManual$.pipe(
    switchMap((manual) =>
      manual
        ? this.configProvicer.get().pipe(map((config) => !!config.config?.features?.maintenance?.enabled))
        : this.maintenanceProvider.getApiUpStatus().pipe(map((status) => status !== 200)),
    ),
  );
  private readonly pollCheck$ = interval(10000).pipe(
    mergeMap(() => this.inMaintenance$),
    tap((maintenance) => {
      if (!maintenance) {
        // using window.location to also execute app startup checks
        window.location.href = '/';
      }
    }),
    takeWhile((maintenance) => !maintenance),
    takeUntilDestroyed(),
  );

  readonly Image = this.platformService.resolveAssetPath(Illustration.CONE);
  readonly buttonLoading$$ = signal(false);
  /** Using a signal, because the {{#openChat}} syntax cannot be set via the template */
  readonly description$$ = signal(
    $localize`:@@maintenance.description:<div>We are currently undergoing maintenance. Please try again later. For questions, you can reach us {{#openChat}}via the chat{{/openChat}}.</div>`,
  );

  constructor() {
    this.initializeChat();
    this.pollCheck$.subscribe();
  }

  async onButtonClickHandler() {
    this.buttonLoading$$.set(true);
    const inMaintenance = await firstValueFrom(this.inMaintenance$);

    if (!inMaintenance) {
      // using window.location to also execute app startup checks
      window.location.href = '/';
    }

    this.buttonLoading$$.set(false);
  }

  async initializeChat() {
    const user = await firstValueFrom(this.userFacade.user$);

    await this.chatService.initialize(user?.intercom?.id ?? null);
  }
}
