import { iOrigin } from './origin.interface';
import { iUser, UserMetaDataProperties } from './user';
import { iUserContact } from './user-contact.interface';
import { iUserMailingConfiguration, IUserMeta, iUserNotificationsConfiguration, iUserPreferences, UserQuestionnaires } from './user';
import { iUserPerson } from './user-person.interface';
import { UserPlan } from './user-plan.enum';
import { UserRole } from './user-role';
import { UserTaskCount } from './user-task-count.interface';
import { Language } from '@onyxx/model/user-language';

type UserMetadata = {
  premium_theme_enabled?: boolean;
  notificationPanelEnabled?: boolean;
  multiLingualEnabled?: boolean;
  additionalLanguages?: string[];
};

// TODO: Replace this class with just the interface and functions

export class User implements iUser {
  id?: string;
  email?: string;
  name_casual?: string;
  avatar?: string;
  created_at?: string;
  contact?: iUserContact;
  plan?: UserPlan;
  total_deposit?: string;
  notification_email?: boolean;
  notification_push?: boolean;
  /** @deprecated: use values from `user.task_count`  */
  open_task_count?: number;
  /** @deprecated: use values from `user.task_count`  */
  open_critical_task_count?: number;
  unread_inbox_count?: number;
  open_reward_count?: number;
  person?: iUserPerson;
  meta?: IUserMeta;
  preferences?: iUserPreferences;
  mailings?: iUserMailingConfiguration;
  questionnaires?: UserQuestionnaires;
  notifications?: iUserNotificationsConfiguration;
  intercom?: { id?: string };
  number?: number;
  fee_percentage?: string;
  origin?: iOrigin;
  role?: UserRole;
  can_create_new_account?: boolean;
  two_factor_enabled?: boolean;
  task_count?: UserTaskCount;
  language?: Language;

  constructor(props?: Partial<User>) {
    Object.assign(this, props);
  }

  isPremium(): boolean {
    return this.plan === UserPlan.PREMIUM;
  }

  getFormattedUserNumber(): string {
    return [this.number?.toString().slice(0, 3), this.number?.toString().slice(3, 6)].join(' ');
  }

  get metaData(): UserMetadata {
    return JSON.parse(this.meta?.data || '{}');
  }

  get premiumThemeEnabled(): boolean {
    return this.metaData[UserMetaDataProperties.PremiumThemeEnabled] ?? true;
  }
}
