import { NgModule, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { forceLogoutAreaRoutes } from './force-logout-area.routes';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, map, distinctUntilChanged, filter } from 'rxjs';
import { AppLoadingStatusStoreFacade } from '@onyxx/store/app-loading-status';
import { AuthFacade } from '@onyxx/store/auth';
import { UserActivityStoreFacade } from '@onyxx/store/user-activity';
import { UserLanguageStoreFacade } from '@onyxx/store/user-language';

@NgModule({
  imports: [RouterModule.forChild(forceLogoutAreaRoutes)],
})
export class ForceLogoutAreaModule {
  private readonly authFacade = inject(AuthFacade);
  private readonly appLoadingStatusStoreFacade = inject(AppLoadingStatusStoreFacade);
  private readonly userActivityStoreFacade = inject(UserActivityStoreFacade);
  private readonly userLanguageStoreFacade = inject(UserLanguageStoreFacade);

  private readonly doneLoading$ = combineLatest([
    this.authFacade.ready$,
    this.userActivityStoreFacade.ready$,
    this.userLanguageStoreFacade.ready$,
  ]).pipe(
    map(([authReady, userActivityReady, userLanguageReady]) => authReady && userActivityReady && userLanguageReady),
    distinctUntilChanged(),
    filter((ready) => ready),
  );

  constructor() {
    this.load();

    this.doneLoading$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.appLoadingStatusStoreFacade.dispatchDoneLoading();
    });
  }

  async load() {
    this.authFacade.dispatchInitialize();
    this.userActivityStoreFacade.dispatchInitialize({ skipNavigationOnLogout: true });
    this.appLoadingStatusStoreFacade.dispatchHideSplashScreen();
  }
}
