import { iDropdownImageStyle } from '@semmie/schemas/components/dropdown/dropdown-option.interface';
import {
  DynamicField,
  DynamicFieldType,
  ExternalDataMethod,
  iDropdownFormField,
  iDynamicForm,
  iExternalDataFormField,
  iGroupFormField,
  iImageFormField,
  iLabelFormField,
  ValuePathType,
} from '@semmie/schemas/components/dynamic-form';
import { Illustration } from '@semmie/shared/globals';
import { PersonLanguageFormReference } from '@semmie/shared/onboarding/person/person-language-form.config';

export const PersonLanguageForm = (): iDynamicForm => {
  return {
    name: 'onboarding_person_language',
    reference: PersonLanguageFormReference,
    descriptionSize: 'base_2',
    titleSize: 'h2',
    useHeaderIntro: true,
    label: $localize`:@@onboarding.person.language.label:Important`,
    title: $localize`:@@onboarding.person.language.title:Display language`,
    description: `{{{language_settings.headerDescription}}}`,
    button: $localize`:@@wizard.yes-agree:Yes, I agree`,
    flat: true,
    fields: [
      {
        name: 'language_settings',
        type: DynamicFieldType.ExternalData,
        service: {
          name: 'languageSelectionService',
          method: ExternalDataMethod.getLanguageSettings,
        },
      } as iExternalDataFormField as DynamicField,
      {
        name: 'image',
        type: DynamicFieldType.Image,
        width: 240,
        src: Illustration.SIGN,
        styles: {
          display: 'flex',
          'justify-content': 'center',
          padding: '24px',
        },
        conditions: [`language_settings.languageOptions | length(@) == \`1\``],
      } as iImageFormField as DynamicField,
      {
        name: 'language-group',
        type: DynamicFieldType.Group,
        cardStyle: 'neutralElevated',
        label: $localize`:@@onboarding.person.language.formal-language:Formal language`,
        nested: false,
        hidden: [`language_settings.languageOptions | length(@) == \`1\``],
        fields: [
          {
            name: 'title',
            type: DynamicFieldType.Label,
            description: $localize`:@@onboarding.person.language.formal-language:Formal language`,
            labelType: 'text',
            size: 'h3',
            styles: {
              display: 'block',
              'margin-bottom': '.875rem',
            },
          } as iLabelFormField as DynamicField,
          {
            name: 'language',
            type: DynamicFieldType.Dropdown,
            label: $localize`:@@onboarding.person.language.formal-language.label:Select a language`,
            options: {
              source: `language_settings.languageOptions`,
              label: 'title',
              value: 'value',
              image: '{{{image}}}',
              imageStyle: iDropdownImageStyle.Rounded,
            },
            valuePath: 'language_settings.selectedValue',
            valuePathType: ValuePathType.DEFAULT_VALUE,
            required: true,
            preselect: true,
          } as iDropdownFormField as DynamicField,
        ],
      } as iGroupFormField as DynamicField,
    ],
  };
};
