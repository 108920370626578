import { formatPercent } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DynamicLocaleId } from '@onyxx/model/user-language';

@Pipe({
  name: 'semmiePercentage',
  standalone: false,
})
export class SemmiePercentagePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: DynamicLocaleId) {}

  transform(value: number | string | null = 0, digitsInfo?: string, showPositiveNotation = false) {
    value = Number(String(value).replace('%', '').replace(',', '.')) / 100 || 0;
    let prefix = '';

    const isNegative = value < 0;
    const positiveSymbol = showPositiveNotation ? '+' : '';
    value = Math.abs(value);
    if (value !== 0) {
      prefix = isNegative ? '-' : positiveSymbol;
    }
    return `${prefix}${formatPercent(value, this.locale.toString(), digitsInfo || '1.0-2')}`;
  }
}
