import * as moment from 'moment';

export const formatTimeDifferenceInMinutesHumanized = (start: number, end: number, locale: string) => {
  moment.locale(locale);
  const endDate = moment(end);
  const duration = endDate.diff(moment(start));

  if (duration <= 0) return moment.duration(1).humanize(true, { ss: -1 });

  return moment.duration(duration).humanize(true, { ss: -1, s: 91, m: 999999999 });
};
