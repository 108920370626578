/* eslint-disable quotes */
import {
  DynamicField,
  DynamicFieldType,
  UploadLayout,
  iDynamicForm,
  iExternalDataFormField,
  iUploadFormField,
} from '@semmie/schemas/components/dynamic-form';

export const PersonIdentificationPassportForm = (): iDynamicForm => ({
  name: 'onboarding_person_identification_passport',
  reference: 'passport',
  descriptionSize: 'base_2',
  titleSize: 'h2',
  legacyEventId: 'person-upload-passport',
  title: $localize`:@@onboarding.person-identification.passport.title:Passport`,
  description: $localize`:@@onboarding.person-identification.passport.description:Upload the front and back of your passport. It must be fully readable and in colour for us to correctly identify you.`,
  fields: [
    {
      name: 'person',
      type: DynamicFieldType.ExternalData,
      store: {
        name: 'PersonStore',
        source: 'person',
      },
    } as iExternalDataFormField as DynamicField,
    {
      name: 'passport_front',
      type: DynamicFieldType.Upload,
      layout: UploadLayout.Document,
      cardStyle: 'neutralElevated',
      uploadAfterCreation: "person.identifications[?kind == 'passport' && side == 'front'] | length(@) != `0`",
      refresh: 'PersonStore',
      label: $localize`:@@form.field.upload.front:Front`,
      method: 'POST',
      fileTypes: ['document', 'image'],
      url: 'people/{{ person.id }}/identification',
      valuePath: "person.identifications[?kind == 'passport' && side == 'front'] | [0]",
      params: {
        'identification[side]': 'front',
        'identification[kind]': 'passport',
        'identification[file]': {
          source: 'passport_front',
        },
      },
      validations: ['required'],
    } as iUploadFormField as DynamicField,
    {
      name: 'passport_back',
      type: DynamicFieldType.Upload,
      layout: UploadLayout.Document,
      cardStyle: 'neutralElevated',
      uploadAfterCreation: "person.identifications[?kind == 'passport' && side == 'back'] | length(@) != `0`",
      refresh: 'PersonStore',
      label: $localize`:@@form.field.upload.back:Back`,
      method: 'POST',
      fileTypes: ['document', 'image'],
      url: 'people/{{ person.id }}/identification',
      valuePath: "person.identifications[?kind == 'passport' && side == 'back'] | [0]",
      params: {
        'identification[side]': 'back',
        'identification[kind]': 'passport',
        'identification[file]': {
          source: 'passport_back',
        },
      },
      validations: ['required'],
    } as iUploadFormField as DynamicField,
  ],
});
