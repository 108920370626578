/* eslint-disable quotes */
import {
  DynamicField,
  DynamicFieldType,
  UploadLayout,
  iDynamicForm,
  iExternalDataFormField,
  iUploadFormField,
} from '@semmie/schemas/components/dynamic-form';

export const PersonIdentificationIdCardForm = (): iDynamicForm => ({
  name: 'onboarding_person_identification_id_card',
  reference: 'id-card',
  descriptionSize: 'base_2',
  titleSize: 'h2',
  legacyEventId: 'person-upload-id-card',
  title: $localize`:@@onboarding.person-identification.id-card.title:Identity card`,
  description: $localize`:@@onboarding.person-identification.id-card.description:Upload the front and back of your identity card. It must be fully readable and in colour for us to correctly identify you.`,
  fields: [
    {
      name: 'person',
      type: DynamicFieldType.ExternalData,
      store: {
        name: 'PersonStore',
        source: 'person',
      },
    } as iExternalDataFormField as DynamicField,
    {
      name: 'idcard_front',
      type: DynamicFieldType.Upload,
      layout: UploadLayout.Document,
      cardStyle: 'neutralElevated',
      uploadAfterCreation: "person.identifications[?kind == 'idcard' && side == 'front'] | length(@) != `0`",
      refresh: 'PersonStore',
      label: $localize`:@@form.field.upload.front:Front`,
      method: 'POST',
      fileTypes: ['document', 'image'],
      url: 'people/{{ person.id }}/identification',
      valuePath: "person.identifications[?kind == 'idcard' && side == 'front'] | [0]",
      params: {
        'identification[side]': 'front',
        'identification[kind]': 'idcard',
        'identification[file]': {
          source: 'idcard_front',
        },
      },
      validations: ['required'],
    } as iUploadFormField as DynamicField,
    {
      name: 'idcard_back',
      type: DynamicFieldType.Upload,
      layout: UploadLayout.Document,
      cardStyle: 'neutralElevated',
      uploadAfterCreation: "person.identifications[?kind == 'idcard' && side == 'back'] | length(@) != `0`",
      refresh: 'PersonStore',
      label: $localize`:@@form.field.upload.back:Back`,
      method: 'POST',
      fileTypes: ['document', 'image'],
      url: 'people/{{ person.id }}/identification',
      valuePath: "person.identifications[?kind == 'idcard' && side == 'back'] | [0]",
      params: {
        'identification[side]': 'back',
        'identification[kind]': 'idcard',
        'identification[file]': {
          source: 'idcard_back',
        },
      },
      validations: ['required'],
    } as iUploadFormField as DynamicField,
  ],
});
