import { Pipe, PipeTransform } from '@angular/core';
import { PlatformService } from '@semmie/services/platform/platform.service';

@Pipe({
  name: 'assetPath',
  pure: true,
  standalone: false,
})
export class AssetPathPipe implements PipeTransform {
  constructor(private platformService: PlatformService) {}

  transform(path: string): any {
    return this.platformService.resolveAssetPath(path);
  }
}
