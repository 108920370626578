import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Icon } from '@semmie/schemas';
import { BaseComponent } from '@semmie/components/_abstract';

@Component({
  selector: 'semmie-steps',
  template: `
    <ul class="checklist">
      <ng-container *ngFor="let step of steps; let i = index">
        <li [class.disabled]="!isActive(i)">
          <semmie-label [weight]="isActive(i) ? 'medium' : 'regular'" [text]="step"></semmie-label>
          <semmie-label *ngIf="subtitle[i] as text" weight="regular" size="small" class="checklist-subtitle" [text]="text"></semmie-label>
        </li>
      </ng-container>
    </ul>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StepsComponent extends BaseComponent {
  @Input() steps: string[] = [];
  @Input() currentStep = 0;
  @Input() subtitle: (string | undefined)[] = [];

  readonly Icon = Icon;

  constructor() {
    super();
  }

  isActive(index: number): boolean {
    return index < this.currentStep;
  }
}
