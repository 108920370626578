import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Language } from '@onyxx/model/user-language';

export const userLanguageCommonActions = createActionGroup({
  source: 'User Language common',
  events: {
    Initialize: emptyProps(),
    Reload: emptyProps(),
    'Set selected language': props<{ language: Language }>(),
    'Set available languages': props<{ languages: Language[] }>(),
    'Initialize done': emptyProps(),
  },
});
