import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';
import { iDynamicFormIntro } from '@semmie/schemas/components/dynamic-form';

@Component({
  selector: 'semmie-intro-content',
  templateUrl: 'intro-content.component.html',
  styleUrls: ['./intro-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IntroContentComponent extends BaseComponent implements OnInit, OnChanges {
  @ContentChild('template', { static: false }) templateRef: TemplateRef<any>;

  @Input() pageHeader: TemplateRef<any> | null = null;
  @Input() intro?: iDynamicFormIntro;
  @Input() label: string;
  @HostBinding('attr.title') _title = '';
  @Input() title? = '';
  @Input() confirm: string;
  @Input() description: string;
  @Input() image?: string | null;
  @Input() imageWidth = 240;
  @Input() imageElevation = false;
  @Input() padding: 'px-0' | 'px-4' | 'px-6' | 'px-10' = 'px-10';

  /**
   * Label for the LinkLabel
   * Currently set by the JSON data.
   * !todo: revisit later; consider camelCase
   */
  @Input() link_label: string | null;

  @Input() button: boolean | string | object;
  @Input() buttonDisabled: boolean;
  @Input() buttonLoading?: boolean = false;

  @Input() footer: { image?: string; text?: string };

  @Output() onLinkLabelClick: EventEmitter<any> = new EventEmitter();
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.intro) {
      Object.assign(this, this.intro);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      Object.assign(this, changes?.intro?.currentValue);
    }
  }

  onButtonClickHandler($event: any): void {
    this.onButtonClick.emit($event);
  }

  onLinkLabelClickHandler($event: any): void {
    this.onLinkLabelClick.emit($event);
  }
}
