import { DOCUMENT } from '@angular/common';
import { CSP_NONCE, Injectable, RendererFactory2, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { ChatService } from './chat.service';
import { UserLanguageStoreFacade } from '@onyxx/store/user-language';
import { combineLatest, concatMap, defer, filter, firstValueFrom, map, of } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { INTERCOM_APP_ID } from '@semmie/tokens';

@Injectable({
  providedIn: 'root',
})
export class ChatWebService implements ChatService {
  private readonly document = inject(DOCUMENT);
  private readonly cspNonce = inject(CSP_NONCE);
  private readonly renderFactory = inject(RendererFactory2);
  private readonly userLanguageStoreFacade = inject(UserLanguageStoreFacade);
  private readonly intercomAppId = inject(INTERCOM_APP_ID);

  private readonly userLanguage$ = combineLatest([
    this.userLanguageStoreFacade.ready$,
    this.userLanguageStoreFacade.selectedLanguage$,
  ]).pipe(
    filter(([ready]) => ready),
    map(([, language]) => {
      return language;
    }),
  );

  constructor() {
    this.userLanguage$
      .pipe(
        takeUntilDestroyed(),
        concatMap((language) => {
          if (!this.chatIsInitialized()) return of(null);

          return defer(async () => {
            await (window as any)['Intercom']('update', { language_override: language });
          });
        }),
      )
      .subscribe();
  }

  async initialize(intercomUserId: string | null): Promise<void> {
    // Do not initialize Intercom on local development
    if (!environment.production) return;
    /**
     * Quick fix to update the user with their intercomUserId if it is supplied
     */
    if (this.chatIsInitialized() && intercomUserId) {
      const language = await firstValueFrom(this.userLanguage$);

      await (window as any)['Intercom']('update', {
        user_id: intercomUserId,
        language_override: language,
      });
      return;
    }

    const renderer = this.renderFactory.createRenderer(null, null);
    const script = renderer.createElement('script');
    script.setAttribute('nonce', this.cspNonce);

    script.type = 'text/javascript';

    const language = await firstValueFrom(this.userLanguage$);
    const intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: this.intercomAppId,
      language_override: language,
      ...(intercomUserId ? { user_id: intercomUserId } : {}),
    };

    script.text = `window.intercomSettings = ${JSON.stringify(
      intercomSettings,
    )};(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${
      intercomSettings.app_id
    }';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

    renderer.appendChild(this.document.body, script);
  }

  async openChat(): Promise<void> {
    if (!this.chatIsInitialized()) return;
    (window as any)['Intercom']('show');
  }

  async closeChat(): Promise<void> {
    if (!this.chatIsInitialized()) return;
    (window as any)['Intercom']('hide');
  }

  async logout(): Promise<void> {
    if (!this.chatIsInitialized()) return;
    (window as any)['Intercom']('shutdown');
  }

  /**
   * Check if the Intercom script from initialize() has been fully loaded to prevent unnecessary undefined errors.
   * Caused e.g. by (probably a race condition) when logging in from Advisor Portal to CP where logout is being triggered.
   * Intercom is still working after the error, so this check is to prevent it being logged by Sentry.
   */
  private chatIsInitialized(): boolean {
    return !!(window as any)['Intercom'];
  }
}
