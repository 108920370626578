import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { QuestionnaireScenarioService } from '@semmie/services/questionnaire/questionnaire-scenario.service';
import { QuestionnaireService } from '@semmie/services/questionnaire/questionnaire.service';

@Component({
  selector: 'semmie-projection-card',
  templateUrl: './projection-card.component.html',
  styleUrls: ['./projection-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectionCardComponent extends BaseComponent implements OnInit {
  @Input() selected = false;
  @HostBinding('attr.title') _title = '';
  @Input() title: string;
  @Input() subtitle: string;
  @Input() detailsTitle: string;
  @Input() detailsSubTitle: string;
  @Input() deposit: number;
  @Input() form: any;
  @Input() projection: { from: number; to: number };
  @Input() icon: Icon = Icon.SECURITY2;
  @Input() value: string;
  @Input() scenario: string;

  get depositFrom(): string {
    return ((this.deposit * (this.projection?.from + 100)) / 100).toString();
  }

  get depositTo(): string {
    return ((this.deposit * (this.projection?.to + 100)) / 100).toString();
  }

  constructor(
    private questionnaireService: QuestionnaireService,
    private questionnaireScenarioService: QuestionnaireScenarioService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.retrieveScenario();
    const depositData: any = Object.values(this.questionnaireService.questionnaireData?.deposits).find(
      (deposit: any) => deposit.question === this.scenario,
    );
    this.deposit = this.deposit ?? depositData?.deposit ?? 0;
  }

  retrieveScenario(): void {
    const scenario = this.questionnaireScenarioService.scenarioByValue(this.value);
    this.detailsTitle = this.detailsTitle || scenario.header;
    this.detailsSubTitle = this.detailsSubTitle || scenario.description;
    this.projection = this.projection || {
      from: scenario.projection.from,
      to: scenario.projection.to,
    };
  }
}
