/* eslint-disable quotes */
import { EerValidation } from '@onyxx/model/validation';
import { DynamicFieldType, iDynamicForm } from '@semmie/schemas/components/dynamic-form';
import {
  DynamicField,
  ExternalDataSource,
  GroupLayout,
  iDropdownFormField,
  iExternalDataFormField,
  iGroupFormField,
  iTextBoxFormField,
  ValuePathType,
} from '@semmie/schemas/components/dynamic-form/form-fields';

export const OnboardingPersonNationalityForm = (relationNLorBE: boolean): iDynamicForm => {
  return {
    name: 'onboarding_person_nationality',
    reference: 'nationality',
    legacyEventId: 'person-nationality',
    descriptionSize: 'base_2',
    titleSize: 'h1',
    title: $localize`:@@form.personal-nationality.title:Nationality`,
    flat: true,
    validations: [
      `${EerValidation.Address}(person.address.country)`,
      `${EerValidation.Nationality}(person.address.country, country_nationality, relationNLorBE)`,
    ],
    fields: [
      {
        name: 'relationNLorBE',
        type: DynamicFieldType.Static,
        value: relationNLorBE,
      } as DynamicField,
      {
        name: 'person',
        type: DynamicFieldType.ExternalData,
        store: {
          name: 'PersonStore',
          source: ExternalDataSource.Person,
        },
      } as iExternalDataFormField as DynamicField,
      {
        name: 'questions_src',
        type: DynamicFieldType.ExternalData,
        once: true,
        store: {
          name: 'QuestionsStore',
          source: ExternalDataSource.Questions,
        },
      } as iExternalDataFormField as DynamicField,
      {
        name: 'countries_src',
        type: DynamicFieldType.ExternalData,
        once: true,
        store: {
          name: 'CountriesStore',
          source: ExternalDataSource.Countries,
        },
      } as iExternalDataFormField as DynamicField,
      {
        name: 'country_birth',
        type: DynamicFieldType.Dropdown,
        selection: {
          type: 'modal',
          search: true,
        },
        label: $localize`:@@form.field.country-birth.label:Country of birth`,
        placeholder: $localize`:@@form.field.country.placeholder:Select a country`,
        options: {
          source: 'countries_src',
          label: 'title',
          value: '$',
          image: 'img/flags/{{iso3}}.png',
          imageStyle: 'rounded',
        },
        valuePathType: ValuePathType.DEFAULT_VALUE,
        valuePath: 'countries_src | [0]',
        validations: ['required'],
      } as iDropdownFormField as DynamicField,
      {
        name: 'country_nationality',
        type: DynamicFieldType.Dropdown,
        selection: {
          type: 'modal',
          search: true,
        },
        label: $localize`:@@form.field.country-nationality.label:Nationality`,
        placeholder: $localize`:@@form.field.country.placeholder:Select a country`,
        options: {
          source: 'countries_src',
          label: 'title',
          value: '$',
          image: 'img/flags/{{iso3}}.png',
          imageStyle: 'rounded',
        },
        valuePathType: ValuePathType.DEFAULT_VALUE,
        valuePath: 'countries_src | [0]',
        validations: ['required'],
      } as iDropdownFormField as DynamicField,
      {
        name: 'question_country_other_reason',
        type: DynamicFieldType.Dropdown,
        label: $localize`:@@form.fields.question-country-other-reason.label:What is the reason for residing in ${'{{ address.country.title}}'}?`,
        placeholder: $localize`:@@form.fields.question-country-other-reason.placeholder:Select an option`,
        validations: ['required'],
        conditions: [
          "country_nationality.iso3 == 'NLD' || country_nationality.iso3 == 'BEL'",
          'person.address.country.iso3 != country_nationality.iso3',
        ],
        options: {
          source: 'questions_src.person.question_country_other_reason',
          label: 'title',
          value: 'value',
        },
      } as iDropdownFormField as DynamicField,
      {
        name: 'question_relation_netherlands',
        type: DynamicFieldType.Dropdown,
        label: relationNLorBE
          ? $localize`:@@form.fields.question-relation-netherlands-or-belgium.label:What is your connection to the Netherlands or Belgium?`
          : $localize`:@@form.fields.question-relation-netherlands.label:What is your connection to the Netherlands?`,
        placeholder: $localize`:@@form.fields.question-relation-netherlands.placeholder:Select an option`,
        validations: ['required'],
        conditions: relationNLorBE
          ? [
              "person.address.country.iso3 != 'NLD'",
              "person.address.country.iso3 != 'BEL'",
              "country_nationality.iso3 != 'NLD'",
              "country_nationality.iso3 != 'BEL'",
            ]
          : ["person.address.country.iso3 != 'NLD'", "country_nationality.iso3 != 'NLD'"],
        options: {
          source: 'questions_src.person.question_relation_netherlands',
          label: 'title',
          value: 'value',
        },
      } as iDropdownFormField as DynamicField,
      {
        name: 'question_relation_netherlands_answer',
        type: DynamicFieldType.Text,
        label: $localize`:@@form.fields.question-relation-netherlands.answer.label:Namely`,
        placeholder: $localize`:@@form.fields.question-relation-netherlands.answer.placeholder:Enter a reason`,
        conditions: relationNLorBE
          ? [
              "person.address.country.iso3 != 'NLD'",
              "person.address.country.iso3 != 'BEL'",
              "country_nationality.iso3 != 'NLD'",
              "country_nationality.iso3 != 'BEL'",
              'question_relation_netherlands == `3`',
            ]
          : ["person.address.country.iso3 != 'NLD'", "country_nationality.iso3 != 'NLD'", 'question_relation_netherlands == `3`'],
        validations: ['required'],
      } as iTextBoxFormField as DynamicField,
      {
        name: 'question_tax_netherlands',
        layout: 'toggle',
        type: DynamicFieldType.Group,
        nested: false,
        cardStyle: 'neutralElevated',
        label: $localize`:@@form.fields.question-tax-netherlands.label:Tax resident of the Netherlands`,
        valuePath: '`true`',
        valuePathType: ValuePathType.DEFAULT_VALUE,
        validations: ['requiredConditionalOr(question_tax_netherlands, question_tax_other)'],
        fields: [
          {
            name: 'tin_bsn',
            type: DynamicFieldType.Text,
            label: $localize`:@@form.field.tin.label.NLD:Burgerservicenummer`,
            placeholder: $localize`:@@form.field.tin.placeholder.NLD:BSN (citizen service number)`,
            conditions: ['question_tax_netherlands', "country_nationality.iso3 != 'NLD'"],
            validations: ['required', "tin('NLD')"],
          } as iTextBoxFormField as DynamicField,
        ],
      } as iGroupFormField as DynamicField,
      {
        name: 'question_tax_other',
        type: DynamicFieldType.Group,
        layout: GroupLayout.Toggle,
        cardStyle: 'neutralElevated',
        label: $localize`:@@form.fields.question-tax-other.label:(also) Tax resident of another country`,
        nested: false,
        validations: ['requiredConditionalOr(question_tax_netherlands, question_tax_other)'],
        fields: [
          {
            name: 'country_tax',
            type: DynamicFieldType.Dropdown,
            selection: {
              type: 'modal',
              search: true,
            },
            label: $localize`:@@form.fields.country-tax.label:Tax residency`,
            placeholder: $localize`:@@form.field.country.placeholder:Select a country`,
            options: {
              source: 'countries_src[?iso3 != `NLD`]',
              label: 'title',
              value: '$',
              image: 'img/flags/{{iso3}}.png',
              imageStyle: 'rounded',
            },
            conditions: ['question_tax_other'],
            validations: ['required'],
          } as iDropdownFormField as DynamicField,
          {
            name: 'tin_other',
            type: DynamicFieldType.Text,
            inputMode: 'text',
            label: {
              translation: {
                BEL: $localize`:@@form.field.tin.label.BEL:Enter your rijksregisternummer`,
                default: $localize`:@@form.field.tin.label.default:Enter your Tax Identification Number (TIN) for ${'{{ country_tax.title }}'}`,
              },
              param: 'country_tax.iso3',
            },
            placeholder: {
              translation: {
                BEL: $localize`:@@form.field.tin.placeholder.BEL:Rijksregisternummer`,
                default: $localize`:@@form.field.tin.placeholder.default:Tax Identification Number`,
              },
              param: 'country_tax.iso3',
            },
            conditions: ["country_tax && country_tax.iso3 !== 'NLD' && question_tax_other"],
            validations: ['required', 'tin(country_tax.iso3)'],
          } as iTextBoxFormField as DynamicField,
        ],
      } as iGroupFormField as DynamicField,
    ],
  };
};
