import { Component, Input } from '@angular/core';

import { BaseListComponent } from '@semmie/components/_abstract/base-list.component';
import { BaseSize } from '@semmie/schemas';
import { ButtonThemeEnum } from '@semmie/schemas/common/button';

/**
 *  TODO: refactor this (and the base) class after implementing ngrx for the store
 *  split into two parts, one thats only concerned with rendering,
 *  and one which is responsible for the pagination logic, elf can also help us there with a common format
 *  Maybe use multi-slotted content projection for the stamp template and the empty template.
 *  If templates, create a (structural?) directive to find the proper template instead of relying on the #template name. https://angular.io/guide/structural-directives#improving-template-type-checking-for-custom-directives
 *  Ask the question if inheritance helps us here or it's unnecessary. Will more components inherit from the BaseListComponent?
 */
@Component({
  selector: 'semmie-scrollable-list',
  templateUrl: './scrollable-list.component.html',
  styleUrls: ['./scrollable-list.component.scss'],
  standalone: false,
})
export class ScrollableListComponent<T> extends BaseListComponent<T> {
  @Input() itemHeight: any;
  @Input() itemGap: any;
  @Input() useColumns = false;
  @Input() pullToRefresh = true;

  @Input() loadModeButtonConfig: {
    theme?: ButtonThemeEnum;
    fullWidth?: boolean;
    size?: BaseSize;
    padding?: 'px-4' | 'px-0';
  } = {};
}
