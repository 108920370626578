/* eslint-disable quotes */
import { EerValidation } from '@onyxx/model/validation';
import { DynamicFieldType, iDynamicForm } from '@semmie/schemas/components/dynamic-form';
import {
  DynamicField,
  ExternalDataSource,
  iDropdownFormField,
  iExternalDataFormField,
  iTextBoxFormField,
  ValuePathType,
} from '@semmie/schemas/components/dynamic-form/form-fields';

export const OnboardingPersonAddressForm = (): iDynamicForm => {
  return {
    name: 'onboarding_person_address',
    reference: 'address',
    legacyEventId: 'person-address',
    descriptionSize: 'base_2',
    titleSize: 'h1',
    title: $localize`:@@form.person-address.title:Where do you live?`,
    intro: {
      label: $localize`:@@form.person-address.intro.label:Let's get started`,
      title: $localize`:@@form.person-address.intro.title:Your details`,
      description: $localize`:@@form.person-address.intro.description:To open your first account, we need some information from you once.`,
      image: 'illustrations/notepad.png',
      button: 'Volgende',
      leftAction: true,
      rightAction: true,
    },
    validations: [`${EerValidation.Address}(country)`],
    fields: [
      {
        name: 'address_src',
        type: DynamicFieldType.ExternalData,
        flat: true,
        url: '{{apiHost}}/addresses/lookup?zipcode={{zipcode}}&street_number={{street_number}}&street_number_extra={{street_number_extra}}',
        ignoreValues: [null, ''],
        conditions: ["country.iso3 === 'NLD'"],
      } as iExternalDataFormField as DynamicField,
      {
        name: 'countries_src',
        type: DynamicFieldType.ExternalData,
        once: true,
        store: {
          name: 'CountriesStore',
          source: ExternalDataSource.Countries,
        },
      } as iExternalDataFormField as DynamicField,
      {
        name: 'country',
        type: DynamicFieldType.Dropdown,
        selection: {
          type: 'modal',
          search: true,
        },
        label: $localize`:@@form.field.country.label:Country`,
        placeholder: $localize`:@@form.field.country.placeholder:Select a country`,
        options: {
          source: 'countries_src',
          label: 'title',
          value: '$',
          image: 'img/flags/{{iso3}}.png',
          imageStyle: 'rounded',
        },
        valuePathType: ValuePathType.DEFAULT_VALUE,
        valuePath: 'countries_src | [0]',
        validations: ['required'],
      } as iDropdownFormField as DynamicField,
      {
        name: 'zipcode',
        type: DynamicFieldType.Text,
        inputMode: 'zipcode',
        countryCode: '{{country.iso3}}',
        label: $localize`:@@form.field.address.zipcode.label:Postal code`,
        placeholder: $localize`:@@form.field.address.zipcode.placeholder:Postal code`,
        validations: ["required('zipCode')", 'zipcode(country)', 'minLength(`4`)'],
      } as iTextBoxFormField as DynamicField,
      {
        name: 'street_number',
        type: DynamicFieldType.Text,
        label: $localize`:@@form.field.address.street-number.label:Street number`,
        placeholder: $localize`:@@form.field.address.street-number.placeholder:Street number`,
        validations: ["required('streetNumber')", 'numbersonly'],
      } as iTextBoxFormField as DynamicField,
      {
        name: 'street_number_extra',
        type: DynamicFieldType.Text,
        inputMode: 'uppercase',
        label: $localize`:@@form.field.address.street-number_extra.label:Street number extra`,
        placeholder: $localize`:@@form.field.address.street-number_extra.placeholder:Street number extra`,
      } as iTextBoxFormField as DynamicField,
      {
        name: 'street',
        type: DynamicFieldType.Text,
        label: $localize`:@@form.field.address.street.label:Street`,
        placeholder: $localize`:@@form.field.address.street.placeholder:Street`,
        validations: ['required', 'minmaxlength(`2`, `255`)'],
      } as iTextBoxFormField as DynamicField,
      {
        name: 'city',
        type: DynamicFieldType.Text,
        label: $localize`:@@form.field.address.city.label:City`,
        placeholder: $localize`:@@form.field.address.city.placeholder:City`,
        validations: ['required', 'nonumbers', 'minmaxlength(`2`, `255`)'],
      } as iTextBoxFormField as DynamicField,
    ],
  };
};
