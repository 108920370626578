/* eslint-disable quotes */
import { DynamicFieldType, iDynamicForm } from '@semmie/schemas/components/dynamic-form';
import {
  DynamicField,
  GroupLayout,
  iDateFormField,
  iDropdownFormField,
  iGroupFormField,
  iTextBoxFormField,
} from '@semmie/schemas/components/dynamic-form/form-fields';

export const OnboardingPersonIdentificationDetailsForm = (): iDynamicForm => {
  return {
    name: 'onboarding_person_identification_details',
    reference: 'details',
    legacyEventId: 'person-details',
    descriptionSize: 'base_2',
    titleSize: 'h1',
    title: $localize`:@@form.person-identification-details.title:Check your details`,
    button: $localize`:@@common.labels.confirm:Confirm`,
    flat: true,
    fields: [
      {
        name: 'name_first',
        type: DynamicFieldType.Text,
        label: $localize`:@@form.field.name-first.label:Given names`,
        placeholder: $localize`:@@form.field.name-first.placeholder-identification:Given names as on your passport`,
        validations: ['required', 'nonumbers', 'prohibitednamevalidator', 'minLength(`2`)'],
      } as iTextBoxFormField as DynamicField,
      {
        name: 'name_middle_and_last',
        type: DynamicFieldType.Text,
        label: $localize`:@@form.field.name-last.label:Surname`,
        placeholder: $localize`:@@form.field.name-last.placeholder:Surname`,
        validations: ['required', 'nonumbers', 'prohibitednamevalidator', 'minLength(`2`)'],
      } as iTextBoxFormField as DynamicField,
      {
        name: 'gender',
        type: DynamicFieldType.Dropdown,
        label: $localize`:@@form.field.gender.label:Gender`,
        placeholder: $localize`:@@form.field.gender.placeholder:Select gender`,
        options: [
          {
            label: $localize`:@@form.field.gender.options.male:Male`,
            value: 'male',
          },
          {
            label: $localize`:@@form.field.gender.options.female:Female`,
            value: 'female',
          },
        ],
        validations: ['required'],
      } as iDropdownFormField as DynamicField,
      {
        name: 'birth_at',
        type: DynamicFieldType.Date,
        label: $localize`:@@form.field.birth-at.label:Birthdate`,
        placeholder: $localize`:@@form.field.date.placeholder:DD-MM-YYYY`,
        validations: [
          "required('birthAt')",
          'date',
          "dateBeforeOrAfter('after', '01/01/1900', 'DD-MM-YYYY')",
          "dateBeforeOrAfter('before', 'now', 'DD-MM-YYYY', 'tooYoung', 'subtract', `18`, 'year')",
        ],
      } as iDateFormField as DynamicField,
      {
        name: 'bsn',
        type: DynamicFieldType.Text,
        inputMode: 'text',
        label: {
          translation: {
            NLD: $localize`:@@form.field.tin.label.NLD:Burgerservicenummer`,
            BEL: $localize`:@@form.field.tin.label.BEL:Enter your rijksregisternummer`,
            default: $localize`:@@form.field.tin.label.default:Enter your Tax Identification Number (TIN) for ${'{{ country_nationality.title }}'}`,
          },
          param: 'country_nationality.iso3',
        },
        placeholder: {
          translation: {
            NLD: $localize`:@@form.field.tin.placeholder.NLD:BSN (citizen service number)`,
            BEL: $localize`:@@form.field.tin.placeholder.BEL:Rijksregisternummer`,
            default: $localize`:@@form.field.tin.placeholder.default:Tax Identification Number`,
          },
          param: 'country_nationality.iso3',
        },
        validations: ['required', 'tin(country_nationality.iso3)'],
      } as iTextBoxFormField as DynamicField,
      {
        name: 'identification_expires_at',
        type: DynamicFieldType.Date,
        inputMode: 'text',
        label: $localize`:@@form.field.identification-expiry.label:Passport or identity card valid until`,
        placeholder: $localize`:@@form.field.identification-expiry.placeholder:DD-MM-YYYY`,
        validations: [
          'required',
          'date',
          "dateBeforeOrAfter('after', 'now', 'DD-MM-YYYY')",
          "dateBeforeOrAfter('before', 'now', 'DD-MM-YYYY', 'identification', 'add', `10`, 'year')",
        ],
      } as iDateFormField as DynamicField,
      {
        name: 'identification_number',
        type: DynamicFieldType.Text,
        inputMode: 'uppercase',
        label: $localize`:@@form.field.identification-number.label:Document number of passport of identity card`,
        placeholder: $localize`:@@form.field.identification-number.placeholder:Document number`,
        validations: ['required', 'minLength(`6`)'],
      } as iTextBoxFormField as DynamicField,
      {
        name: 'birth_city',
        type: DynamicFieldType.Text,
        label: $localize`:@@form.field.birth-city.label:Place of birth`,
        placeholder: $localize`:@@form.field.birth-city.placeholder:Place of birth`,
        validations: ['required', 'nonumbers', 'minLength(`2`)'],
      } as iTextBoxFormField as DynamicField,
      {
        name: 'confirm',
        type: DynamicFieldType.Group,
        layout: GroupLayout.Toggle,
        nested: false,
        fields: [],
        cardStyle: 'neutralElevated',
        label: $localize`:@@form.field.confirm.message:My details are correct`,
        validations: ['requiredtrue'],
      } as iGroupFormField as DynamicField,
    ],
  };
};
