import { ApplicationEnvironment } from '@onyxx/model/application-environment';

export const environment: ApplicationEnvironment = {
  production: true,
  universalLink: 'mijn.semmie.nl',
  iosScheme: 'semmie',
  androidIntentScheme: 'semmie',
  apiUrl: 'https://api.onyxx.io/v1',
  configUrl: 'https://xdhg2pd1wb.execute-api.eu-west-1.amazonaws.com/prod/config', // TODO: replace with prod url
  resourcesUrls: {
    i18n: 'https://static.assets.semmie.nl/i18n-ota',
  },
  appStorageKey: '__onyxx_app_storage__',
  showAccountCreation: false,
  name: 'production',
  sentryDsn: 'https://f12e9773b6d042f281396f621bd33d39@o356582.ingest.sentry.io/4504247855939584',
  advisorPortalUrl: 'https://adviseurs.semmie.nl',
  appStoreId: '1470201052',
  googlePlayStoreId: 'nl.semmie.semmieapp',
  gtmId: 'GTM-M5KZ68',
};
