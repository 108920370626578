import { PersonHelpers } from '@onyxx/model/person';
import { Language, LanguageHelpers } from '@onyxx/model/user-language';
import { Utils } from '@onyxx/utility/general';
import { Person } from '../person.interface';
import { Person as LegacyPerson } from '@semmie/models';

export const isLanguageValidContractLanguage = (person: Partial<Person | LegacyPerson>, language: Language): boolean => {
  const contractCountry = PersonHelpers.contractCountry(person);
  if (Utils.isNil(contractCountry)) return false;

  return LanguageHelpers.formalLanguagesByCountry(contractCountry).includes(language);
};
