import { Injectable, inject } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { loadTranslations } from '@angular/localize';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { SentryService } from '@semmie/services/sentry/sentry.service';

import { environment } from 'environments/environment';
import { Utils } from '@onyxx/utility/general';
import { UserLanguageStoreConfig } from '@onyxx/store/user-language';
import { Language } from '@onyxx/model/user-language';
import { updateLocale } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UserLanguageStoreConfigService implements UserLanguageStoreConfig {
  private readonly httpClient = inject(HttpClient);
  private readonly sentry = inject(SentryService);

  async onLoadLanguage(language: Language) {
    try {
      const translationFileLocaleCode = (() => {
        switch (language) {
          case Language.nl:
            return 'nl';
          case Language.fr:
            return 'fr';
          case Language.en:
          default:
            return 'en-GB';
        }
      })();

      const [translations, externalTranslations] = await Promise.all([
        this.fetchLocalTranslations(translationFileLocaleCode),
        this.fetchOtaTranslations(translationFileLocaleCode).catch((err) => {
          this.sentry.captureException(err);
          return {};
        }),
      ]);

      // TODO: remove this method when localizely can export a runtime ARB file (without the @/metadata keys)
      this.filterAtKeys(translations);
      loadTranslations(translations);

      if (Utils.isNotNil(externalTranslations)) {
        this.filterAtKeys(externalTranslations);
        loadTranslations(externalTranslations);
      }

      this.setupMomentLocale(language);
    } catch (err) {
      this.sentry.captureException(err);
      console.warn(`Failed to load translation for locale file: ${language}`);
    } finally {
      await this.initializeAngularLocale(language);
    }
  }

  private async initializeAngularLocale(localeId: string) {
    try {
      const localeModule = await import(
        /* webpackMode: "lazy-once", webpackInclude: /(en-GB|en|nl)\.mjs$/, webpackChunkName: "angular-locales" */
        `node_modules/@angular/common/locales/${localeId}.mjs`
      );

      const localeExtraModule = await import(
        /* webpackMode: "lazy-once", webpackInclude: /(en-GB|en|nl)\.mjs$/, webpackChunkName: "angular-locales-extra" */
        `node_modules/@angular/common/locales/extra/${localeId}.mjs`
      );

      return registerLocaleData(localeModule.default, localeId, localeExtraModule.default);
    } catch {
      const dutchLocale = await import(
        /* webpackMode: "lazy-once" */
        'node_modules/@angular/common/locales/nl.mjs'
      );

      const dutchLocaleExtra = await import(
        /* webpackMode: "lazy-once"*/
        'node_modules/@angular/common/locales/extra/nl.mjs'
      );

      return registerLocaleData(dutchLocale.default, localeId, dutchLocaleExtra.default);
    }
  }

  private filterAtKeys(obj: Record<string, unknown>): void {
    for (const key in obj) {
      if (key.startsWith('@') && !key.startsWith('@@')) {
        delete obj[key];
      }
    }
  }

  private fetchLocalTranslations(locale: 'en-GB' | 'nl' | 'fr') {
    return firstValueFrom(this.fetchTranslations(`assets/i18n/locale-${locale}.arb`));
  }
  private fetchOtaTranslations(locale: 'en-GB' | 'nl' | 'fr') {
    return firstValueFrom(this.fetchTranslations(`${environment.resourcesUrls.i18n}/locale-${locale}.arb`));
  }

  private fetchTranslations(translationFileLocation: string) {
    return this.httpClient.get<Record<string, string>>(translationFileLocation, { responseType: 'json' });
  }

  private setupMomentLocale(language: Language) {
    if (language === Language.nl) {
      updateLocale(language, {
        monthsShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
      });
    } else {
      updateLocale(language.toString(), {});
    }
  }
}
