import { CommonModule } from '@angular/common';
import { Component, Host, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from '@semmie/components/_abstract';
import { RadioButtonModule as PRadioButtonModule } from 'primeng/radiobutton';

import { HapticOnTapDirective } from '@semmie/directives/haptic-on-tap.directive';
import { SharedModule } from '@semmie/shared/shared.module';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';
import { RadioButtonGroupComponent } from '@semmie/components/presentational/core/radio-button-group/radio-button-group.component'; // Don't load from barrel file
import { RadioButtonGroupLayout } from '@semmie/schemas/components/dynamic-form/form-fields';
import { SelectionCardComponent } from '@semmie/components/presentational/core/selection-card/selection-card.component';

@Component({
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PRadioButtonModule,
    SelectionCardComponent,
    HapticOnTapDirective,
    LabelModule,
  ],
  selector: 'semmie-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent extends BaseComponent {
  /** Disables the field and makes it read-only. */
  @Input() disabled: boolean;
  /** The display text of the field */
  @Input() label: string;
  /** The description of the field */
  @Input() description?: string;
  /** Mode to display the radiobutton */
  @Input() layout?: RadioButtonGroupLayout;
  /** The value of this specific radio button (not the form control value) */
  @Input() value: any;
  /** The form control name from the parent */
  @Input() name: string;
  /** The label when using card mode */
  @Input() cardLabel?: string;
  @Input() last = false;
  @Input() padding?: string;

  readonly RadioButtonGroupLayout = RadioButtonGroupLayout;

  constructor(@Host() public radioGroup: RadioButtonGroupComponent<string>) {
    super();
  }

  get isSelected(): boolean {
    return this.radioGroup.value === this.value;
  }

  selectButton(): void {
    if (this.disabled) return;
    this.radioGroup.changeValue(this.value);
  }
}
