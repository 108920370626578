import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from '@onyxx/utility/general';
import { BankService } from '@semmie/services/bank/bank.service';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { Sanitizer } from '@semmie/shared/sanitizer';

/**
 * Remove empty paragraphs.
 * - Note: paragraphs could contain return, breaks or only a `&nbsp;` in them.
 */
const EMPTY_PARAGRAPH_REGEX = /(<p>)(\n)?(\s)?(<br[/]?>)?(&nbsp;|\s)?(\n)?(\s)?(<[\\/]?p>)/gm;
const BANK_IMG_REGEX = /(src="\/images\/banks\/)((.*?)\.svg)/;
const SMALL_REGEX = /(<small>)(.*?)(<[\\/]?small>)/g;

@Directive({
  selector: '[semmieSafeHtml]',
  standalone: false,
})
export class SafeHtmlDirective implements OnInit {
  @Output()
  loaded = new EventEmitter<void>();

  @Input({ alias: 'semmieSafeHtml', required: true }) html: string;

  @Input() allowIframe = false;

  constructor(
    private host: ElementRef<HTMLElement>,
    private bankService: BankService,
    private platformService: PlatformService,
  ) {}

  ngOnInit(): void {
    if (Utils.isNotNil(this.html)) {
      const sanitizedHtml = this.prepare(this.html);
      this.host.nativeElement.innerHTML = sanitizedHtml;
      this.loaded.emit();
    }
  }

  private prepare(html: string): string {
    html = html.replace(EMPTY_PARAGRAPH_REGEX, '');

    // Get correct path for bank images to show direct debit info
    const containsBankImage = html.match(BANK_IMG_REGEX);
    const accountNumber = html.split(SMALL_REGEX)[2];
    if (Utils.isNotNil(containsBankImage) && Utils.isNotNil(accountNumber)) {
      const bankLogo = this.bankService.determineBankLogo(accountNumber);
      const path = this.platformService.resolveAssetPath(`img/banks/svg/${bankLogo}.svg`);
      html = html.replace(BANK_IMG_REGEX, `src="${path}`);
    }

    return Sanitizer.sanitize(html, { allowIframe: this.allowIframe });
  }
}
