import { Component, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'semmie-carousel-slide',
  templateUrl: './carousel-slide.component.html',
  standalone: false,
})
export class CarouselSlideComponent {
  @ContentChild('carouselSlideContent', { read: TemplateRef }) template!: TemplateRef<HTMLElement>;
}
