import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Host, Input, OnInit, Optional, Self, inject, signal } from '@angular/core';
import { NgControl, UntypedFormGroup } from '@angular/forms';
import { PayoutCalculation, PayoutFrequency } from '@onyxx/model/payout';
import { RxLet } from '@rx-angular/template/let';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { CardModule } from '@semmie/components/containers/card/card.module';
import { FormInputComponent } from '@semmie/components/containers/form-input/form-input.component';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { DynamicFieldType } from '@semmie/schemas/components/dynamic-form';
import { Observable, ReplaySubject, delay, filter, map, of, startWith, switchMap, tap } from 'rxjs';
import { CurrencyModule } from '../../../../pipes/currency/currency-pipe.module';
import { LoadingRippleModule } from '@semmie/components/presentational/core/loading-ripple/loading-ripple.module';
import { ImageModule } from '@semmie/components/presentational/core/image';
import { Utils } from '@semmie/shared/utils';
import { InfoModalComponent } from '@semmie/components/containers/modals/info-modal/info-modal.component';
import { ModalSize } from '@semmie/schemas/components/modal';
import { ModalService } from '@semmie/services';
import { InfoModalIconComponent } from '@onyxx/ui/info-modal-icon';

@Component({
  selector: 'semmie-payout-summary',
  templateUrl: './payout-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RxLet, CardModule, LabelModule, CurrencyModule, LoadingRippleModule, ImageModule, InfoModalIconComponent],
  styleUrls: ['./payout-summary.component.scss'],
})
export class PayoutSummaryComponent extends BaseFormComponent implements OnInit {
  private modalService = inject(ModalService);
  @Input() form: UntypedFormGroup;
  isLoading$$ = signal<boolean>(true);
  valuePath$$ = new ReplaySubject<string>();

  payout$$ = this.valuePath$$.pipe(
    switchMap((valuePath) => {
      return this.formInputComponent?.actualFormComponent?.getDataStreams(DynamicFieldType.ExternalData, valuePath).pipe(
        startWith([{ stream: true }]),
        filter((f) => !!f[0].stream),
        tap(() => this.isLoading$$.set(true)),
        switchMap((streams) => {
          // handle loading inidicator for the startWith value
          if (streams[0].stream === true) {
            return of({} as unknown as PayoutCalculation).pipe(
              delay(2000),
              tap(() => {
                this.isLoading$$.set(false);
              }),
            );
          }
          // handle calculate streams
          return (streams[0].stream as Observable<any>).pipe(
            filter((stream) => {
              return Utils.isNonNullOrUndefined(stream) && !('status' in stream);
            }), // filter out pending stream state
            map((stream) => {
              return stream as unknown as PayoutCalculation;
            }),
            delay(1000), // Add delay to show loading preventing a flickering effect
            tap(() => {
              this.isLoading$$.set(false);
            }),
          );
        }),
      );
    }),
  );

  constructor(
    @Optional() @Self() ngControl: NgControl,
    @Optional() @Host() private formInputComponent: FormInputComponent,
  ) {
    super(ngControl);
  }

  ngOnInit(): void {
    this.valuePath$$.next(this.valuePath);
  }

  frequencyDisplay(frequency: PayoutFrequency) {
    // TODO: take these units out into a central file dedicated for time and unit common i18n
    switch (frequency) {
      case PayoutFrequency.MONTH:
        return $localize`:@@payout.frequency.month:month`;
      case PayoutFrequency.QUARTER:
        return $localize`:@@payout.frequency.quarter:quarter`;
      case PayoutFrequency.YEAR:
        return $localize`:@@payout.frequency.year:year`;
      default:
        return '';
    }
  }

  async openInfoModal(): Promise<void> {
    await this.modalService.open(
      InfoModalComponent,
      {
        componentProps: {
          title: $localize`:@@payout.summary.info-modal.title:Expected gross benefit`,
          description: $localize`:@@payout.summary.info-modal.description:<div>Your expected gross benefit per period is calculated using the U-yield. The U-yield is an interest rate based on laws and regulations that financial institutions are required to use in forecasts.<br/><br/>While we pay out your annuity, we continue to invest the rest of your pension assets. Your actual benefit will therefore be determined by your investment results and will always differ from the estimate.<br/><br/><strong>Continue investing annuity</strong><br/>This also means that the benefit will differ per period. You will receive the assets periodically, which means that the value of your portfolio will gradually decrease.<br/><br/>The estimate of your periodic benefit has been made based on the information we currently have, such as the payment period, but also the amount of any transfer of value, which may ultimately differ.<br/><br/>Finally, take into account the difference between gross and net (the amount you receive in your bank account). You pay tax on the annuity payment. We withhold this tax from your benefit and pay it to the Tax Authorities for you. This is a pre-levy, a kind of advance. The annual tax return will definitively calculate how much tax you still have to pay or will receive back.</div>`,
          button: $localize`:@@modal.button.close:Close`,
        },
      },
      { size: ModalSize.Auto },
    );
  }
}
