import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { SharedModule } from '@semmie/shared/shared.module';

@Component({
  imports: [SharedModule, LabelModule],
  selector: 'semmie-content-intro-text',
  template: '<semmie-label class="content-intro-text" type="text" size="base_2" align="center" [htmlContent]="htmlContent"></semmie-label>',
  styleUrls: ['./content-intro-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentIntroTextComponent extends BaseComponent {
  @Input() htmlContent?: string | null;
}
