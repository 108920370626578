import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';

@Component({
  selector: 'semmie-content-container-bottom',
  template: '<ng-content></ng-content>',
  styleUrls: ['./content-container-bottom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ContentContainerBottomComponent extends BaseComponent {
  @HostBinding('class') @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'full' = 'xs';
}
