import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'semmie-loading-ripple',
  template: `<div class="ripple-container" [ngClass]="position">
    <div class="ripple"></div>
    <div class="ripple"></div>
  </div>`,
  styles: [
    `
      .ripple-container {
        display: inline-block;
        position: relative;
        width: 100%;
        padding-bottom: 100%;
      }

      .ripple {
        position: absolute;
        border-width: 1px;
        border-style: solid;
        border-color: var(--color-loading-ripple);
        opacity: 1;
        border-radius: 50%;
        animation: ripple-animation 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .ripple:nth-child(2) {
        animation-delay: -0.5s;
      }

      :host(.fixed) {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      :host(.fixed) .ripple-container {
        width: 70px;
        height: 70px;
        padding-bottom: 0;
      }

      @keyframes ripple-animation {
        0% {
          top: 50%;
          left: 50%;
          width: 0;
          height: 0;
          opacity: 1;
        }
        100% {
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    `,
  ],
  standalone: false,
})
export class LoadingRippleComponent {
  @Input() position: 'inline' | 'fixed' = 'fixed';

  @HostBinding('class') get positionClass() {
    return this.position;
  }
}
