import { createFeature, createReducer, on } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Person } from '@onyxx/model/person';
import { personApiActions } from './person-api.actions';
import { personCommonActions } from './person-common.actions';

export interface State {
  person: Person | null;
  editBusy: boolean;
}

const initialState: State = {
  person: null,
  editBusy: false,
};

const reducer = createReducer(
  initialState,
  on(personApiActions.loadSuccess, (state, { person }): State => ({ ...state, person })),
  on(personCommonActions.clear, (): State => initialState),

  on(personApiActions.edit, (state): State => {
    return { ...state, editBusy: true };
  }),
  on(personApiActions.editSuccess, (state, { person }): State => ({ ...state, person, editBusy: false })),
  on(personCommonActions.updateInStore, (state, { person }): State => ({ ...state, person: { ...person } })),
  on(
    personApiActions.editFailure,
    (state): State => ({
      ...state,
      editBusy: false,
    }),
  ),
);

export const personFeature = createFeature({
  name: 'person',
  reducer,
});
