import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { BaseSize, Icon, IconSize } from '@semmie/schemas';
import { ButtonTheme, ButtonThemeEnum } from '@semmie/schemas/common/button';

@Component({
  imports: [CommonModule, IconComponent],
  selector: 'semmie-fab-button',
  template: `
    <button class="button" [ngClass]="getStyling()" (click)="onClickHandler($event)" type="button">
      <div class="content">
        <semmie-icon *ngIf="icon" [icon]="icon" [size]="iconSize"></semmie-icon>
      </div>
    </button>
  `,
  styleUrls: ['./fab-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FabButtonComponent extends BaseComponent implements OnInit {
  @Input() size: BaseSize = 'md';
  @Input() theme: ButtonTheme = ButtonThemeEnum.DEFAULT;
  @Input() icon: Icon;
  @Input() iconSize: IconSize | 'current' = 'xl';
  @Input() class: string;
  @HostBinding('class.disabled')
  @Input()
  disabled: boolean | null = false;
  @HostBinding('class.clickable')
  @Input()
  clickable: boolean | null = true;
  @Input() position: 'relative' | 'absolute' = 'relative';

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    if (this.class) this.class += ' ';
  }

  onClickHandler($event: any): void {
    if (this.disabled || !this.clickable) return;
    this.onClick.emit($event);
    $event.stopPropagation();
  }

  getStyling() {
    const classes = new Array<string>();

    if (this.size) classes.push(this.size);
    if (this.theme) classes.push(this.theme);
    if (this.position) classes.push(this.position);
    if (this.class) this.class.split(' ').forEach((c) => classes.push(c));

    return classes.join(' ');
  }
}
