import { Component, ElementRef, EventEmitter, Input, OnDestroy, output, Output } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { UrlService } from '@semmie/services/url/url.service';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'semmie-html-render',
  template: ' <div class="semmie-html" [semmieSafeHtml]="html ?? \'\'" [allowIframe]="allowIframe" (loaded)="onHtmlLoaded()"></div> ',
  styleUrls: ['./html-render.component.scss'],
  standalone: false,
})
export class HtmlRenderComponent extends BaseComponent implements OnDestroy {
  @Input()
  html?: string | null;

  @Input()
  allowIframe = false;

  @Output()
  anchorClicked = new EventEmitter<string>();

  readonly loaded = output<void>();

  private destroy$ = new Subject<void>();

  constructor(
    private host: ElementRef<HTMLElement>,
    private urlService: UrlService,
  ) {
    super();
  }

  onHtmlLoaded() {
    const anchorElements = this.host.nativeElement.querySelectorAll('a');

    merge(...Array.from(anchorElements).map((anchor) => fromEvent(anchor, 'click')))
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        let element = event.target as HTMLElement;
        const anchorHref = (element as HTMLAnchorElement).href;

        if (element.localName !== 'a') {
          while (element.localName !== 'a' && element.parentElement) {
            element = element.parentElement;
          }
        }

        this.anchorClicked.emit(anchorHref);
        if (!anchorHref.includes('taskpaymentlink')) {
          this.urlService.handleAnchor(anchorHref);
        }
      });

    this.loaded.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
