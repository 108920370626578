import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { createGesture, Gesture } from '@ionic/angular';

import { PlatformService } from '@semmie/services/platform/platform.service';

@Directive({
  selector: '[disableSwipeOnTouch]',
  standalone: false,
})
export class DisableSwipeOnTouchDirective implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  private swipeGesture?: Gesture;

  constructor(
    private ref: ElementRef,
    private platformService: PlatformService,
  ) {}

  ngOnInit(): void {
    if (this.platformService.isiOS) {
      this.createSwipeGesture();
      this.bindEvents();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    this.swipeGesture?.destroy();
  }

  private createSwipeGesture() {
    this.swipeGesture = createGesture({
      el: this.ref.nativeElement,
      threshold: 0,
      gestureName: 'back-gesture',
      gesturePriority: 40.5, // priority of swipe to go back is 40
    });
  }

  private bindEvents() {
    const touchStart$ = fromEvent(this.ref.nativeElement, 'touchstart', { passive: false });
    const touchEnd$ = fromEvent(this.ref.nativeElement, 'touchend', { passive: true });

    touchEnd$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.swipeGesture?.enable(false);
    });

    touchStart$.pipe(takeUntil(this.destroy$)).subscribe((e: TouchEvent) => {
      this.swipeGesture?.enable(true);
      e.preventDefault();
      e.stopPropagation();
    });
  }
}
