import { Injectable } from '@angular/core';
import { iCountry } from '@onyxx/model/countries';
import { Utils } from '@onyxx/utility/general';

@Injectable({
  providedIn: 'root',
})
export class EerService {
  /**
   * Check if the `address.country` is part of the EER
   */
  validateAddress(...[country]: any): boolean {
    const countryAddress = country as iCountry;
    return countryAddress && countryAddress.eer;
  }

  /**
   * With `relationNLorBE` config:
   ** `address.country` is `NLD` or `BEL` ||
   ** `address.country` is part of the EER && `country_nationality` is `NLD` or `BEL`
   *
   * Without `relationNLorBE` config:
   ** `address.country` is `NLD` ||
   ** `address.country` is part of the EER && `country_nationality` is `NLD` or `BEL`
   */
  validateNationality(...[country, nationality, relationNLorBE]: any): boolean {
    const countryAddress = country as iCountry;
    const countryNationality = nationality as iCountry;
    const relationNLorBEConfig = relationNLorBE as boolean;
    if (Utils.isNil(countryAddress) || Utils.isNil(countryNationality)) return false;
    if (relationNLorBEConfig) {
      return ['NLD', 'BEL'].includes(countryAddress.iso3) || (countryAddress?.eer && ['NLD', 'BEL'].includes(countryNationality.iso3));
    }
    return ['NLD'].includes(countryAddress.iso3) || (countryAddress?.eer && ['NLD', 'BEL'].includes(countryNationality.iso3));
  }
}
