import { Utils } from '@onyxx/utility/general';
import { Person } from '../person.interface';
import { Person as LegacyPerson } from '@semmie/models';

/**
 * Get the contract country (ISO3) based on the persons nationality and residence information. If we
 * don't have enough information yet to make a determination, we return null.
 */
export const contractCountry = (person: Partial<Person | LegacyPerson>): string | null => {
  if (person.question_tax_netherlands === true) {
    return 'NLD';
  }

  if (Utils.isNil(person.question_tax_netherlands) || Utils.isNil(person.country_tax)) {
    // we don't have enough information to determine the country
    return null;
  }

  if (person.question_tax_other && person.country_tax.iso3 === 'BEL') return 'BEL';

  return 'NLD';
};
