import { Language } from '../language.enum';

export const getIso2FromLanguage = (language: Language): string => {
  switch (language) {
    case Language.nl:
      return 'nl';
    case Language.en:
      return 'en';
    case Language.fr:
      return 'fr';
  }
};
