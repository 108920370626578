import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PlatformService } from '@semmie/services/platform/platform.service';
import { UrlService } from '@semmie/services/url/url.service';
import { BaseComponent } from '@semmie/components/_abstract';
import { ThemeService } from '@semmie/services/theme/theme.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';

export interface iImageSrc {
  default: string;
  themed: string | null;
  isSvg: boolean;
}

@Component({
  selector: 'semmie-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ImageComponent extends BaseComponent implements OnInit {
  @Input() width: number | string;
  @Input() height: number | string;
  @Input() styling = '';
  @Input() shadow: boolean;
  @Input() clickable: boolean;
  @Input() alt = '';
  @Input() useObject = false;
  @Input() fallbackSrc: string;
  @Input() useThemeVariant = true;

  @Input() set src(value: string | undefined | null) {
    if (value) {
      this.imageValue$.next(value);
    }
  }

  @Input() placeholder: boolean;
  @Input() loadMode: 'sync' | 'async' = 'async';
  @Input() objectFit: 'none' | 'objectCover' | 'objectFill' | 'objectContain' | 'objectScaleDown' = 'objectContain';
  @Input() objectPosition: string;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  placeholderImage = this.platformService.resolveAssetPath('img/logos/logo_dark.svg');

  readonly imageValue$ = new BehaviorSubject<string | null>(null);
  readonly imageSrc$ = combineLatest([this.imageValue$, this.themeService.currentTheme$]).pipe(
    distinctUntilChanged(),
    map(([value, { useDarkTheme }]) => {
      const isLocalImage = value && !this.urlService.isUrl(value);
      return {
        default: isLocalImage ? this.platformService.resolveAssetPath(value) : value,
        themed: this.useThemeVariant && isLocalImage ? this.platformService.resolveAssetPath(value, { darkTheme: useDarkTheme }) : null,
        isSvg: value && value.endsWith('.svg'),
      };
    }),
  );
  readonly imageError$ = new BehaviorSubject(false);

  constructor(
    private platformService: PlatformService,
    private urlService: UrlService,
    private themeService: ThemeService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.onClick.observers.length) {
      this.clickable = true;
    }
  }

  onClickHandler(): void {
    this.onClick.emit();
  }

  onImageError() {
    this.imageError$.next(true);
  }
}
