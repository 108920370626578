import { ChangeDetectionStrategy, Component, inject, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { iIconCardsFormField } from '@semmie/schemas/components/dynamic-form';
import { iIconCardOption } from '@semmie/schemas/components/icon-card-option/icon-card-options.interface';
import { PlatformService } from '@semmie/services';

@Component({
  selector: 'semmie-icon-cards',
  template: `
    <div class="icon-cards">
      <div
        class="icon-card clickable"
        *ngFor="let option of options"
        [class.selected]="option.value === value"
        [class.enabled]="!option.disabled"
        [class.allowHover]="platformService.isDesktop"
        hapticOnTap
        (click)="selectOption(option)"
      >
        <div class="image">
          <semmie-image [height]="64" [src]="option.image"></semmie-image>
        </div>
        <semmie-label class="label" [text]="option.label" align="center" size="base_3"></semmie-label>
      </div>
    </div>
  `,
  styleUrls: ['./icon-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IconCardsComponent extends BaseFormComponent implements OnInit {
  readonly platformService = inject(PlatformService);

  @Input() options: iIconCardsFormField['options'];

  constructor(@Optional() @Self() ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  selectOption(option: iIconCardOption): void {
    if (!option.disabled) {
      this.writeValue(option.value);
    }
  }
}
