import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Icon } from '@semmie/schemas';
import { FileError, MAX_UPLOAD_SIZE_KB } from '@semmie/schemas/bi/file';
import { UploadService } from '@semmie/services/upload/upload.service';
import { Utils } from '@onyxx/utility/general';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { HttpMethod } from '@semmie/schemas/generics/http/http-method.enum';
import { HttpProgressStatus } from '@semmie/schemas/generics/http/http-progress-status.enum';
import { catchError, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedModule } from '@semmie/shared/shared.module';
import { IconComponent, ImageModule, LabelModule } from '@semmie/components';

@Component({
  imports: [SharedModule, IconComponent, ImageModule, LabelModule],
  selector: 'semmie-user-profile-avatar-uploader',
  templateUrl: 'user-profile-avatar-uploader.component.html',
  styleUrls: ['./user-profile-avatar-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileAvatarUploaderComponent implements OnDestroy {
  @Output()
  onClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  avatarUpdated = new EventEmitter<string>();

  @Input()
  readonly = false;

  @Input()
  avatarUrl: string | null = null;

  errorKey$ = new BehaviorSubject<string | null>(null);
  progress$ = new BehaviorSubject<number>(0);

  readonly Icon = Icon;

  private destroy$ = new Subject<void>();

  constructor(private uploadService: UploadService) {}

  handleFiles(files: FileList | null): void {
    this.reset();

    const file = files?.item(0);
    if (Utils.isNil(file)) {
      return;
    }

    if (file.size / 1_000 > MAX_UPLOAD_SIZE_KB) {
      this.handleError(this.uploadService.getFileErrorMessage(FileError.TooLarge));
      return;
    }

    this.uploadAvatar(file);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private uploadAvatar(file: File) {
    this.uploadService
      .upload('/user/avatar', HttpMethod.PATCH, {
        'user[avatar]': file,
      })
      .pipe(
        takeUntil(this.destroy$),
        catchError((err: HttpErrorResponse) => {
          let errorMessage: string;
          if (err.status === 413) {
            errorMessage = this.uploadService.getFileErrorMessage(FileError.TooLarge);
          } else if (err.status === 422) {
            errorMessage = this.uploadService.getFileErrorMessage(err.error.details?.avatar?.[0].error as FileError);
          } else {
            errorMessage = err.statusText;
          }

          this.handleError(errorMessage);
          return EMPTY;
        }),
      )
      .subscribe(({ progress, state, response }) => {
        if (state === HttpProgressStatus.DONE) {
          this.avatarUpdated.emit(response.user.avatar);
          this.reset();
          return;
        }

        this.progress$.next(progress);
      });
  }

  private reset() {
    this.progress$.next(0);
    this.errorKey$.next(null);
  }

  private handleError(error: string) {
    this.progress$.next(0);
    this.errorKey$.next(error);
  }
}
