import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { SharedModule } from '@semmie/shared/shared.module';

@Component({
  imports: [SharedModule, LabelModule],
  selector: 'semmie-content-footer-text',
  template: `
    <semmie-label
      class="content-footer-text"
      [class.contrast]="contrast"
      type="text"
      size="base_2"
      align="center"
      [htmlContent]="htmlContent"
      [useContentProjection]="useContentProjection"
    >
      <ng-content></ng-content>
    </semmie-label>
  `,
  styleUrls: ['./content-footer-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentFooterTextComponent extends BaseComponent {
  @Input() htmlContent?: string | null;
  @Input() contrast?: boolean;
  @Input() useContentProjection = false;
}
