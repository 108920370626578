import { publicAreaRoutes } from './public-area.routes';
import { areaMatcher } from '../area-matcher';
import { PublicAreaModule } from './public-area.module';
import { Route } from '@angular/router';
import { userLanguageReadyGuard } from '../../areas/guards/user-language-ready.guard';

export const publicArea = (): Route => {
  return {
    matcher: areaMatcher(publicAreaRoutes),
    loadChildren: () => PublicAreaModule,

    // The guard prevents the components in the public routes from constructing until the user language is ready,
    // otherwise they get rendered using the wrong local.
    canActivate: [userLanguageReadyGuard()],
  };
};
