import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { LabelSize, TextSize } from '@semmie/schemas';

@Component({
  selector: 'semmie-header',
  template: `
    <semmie-label [attr.id]="'header-label'" *ngIf="label" type="text" size="base_2" [text]="label"></semmie-label>
    <semmie-label [attr.id]="'header-title'" *ngIf="title" type="heading" [size]="titleSize" class="mb-3.5" [text]="title"></semmie-label>
    <semmie-label
      [attr.id]="'header-description'"
      *ngIf="description"
      type="text"
      [size]="descriptionSize"
      [text]="!descriptionIsHtml ? description : undefined"
      [htmlContent]="descriptionIsHtml ? description : undefined"
    ></semmie-label>
  `,
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class HeaderComponent {
  @Input() label: string;
  @HostBinding('attr.title') _title = '';
  @Input() title?: string;
  @Input() titleSize: LabelSize = 'h1';
  @Input() descriptionSize: TextSize = 'base_2';

  @Input() set description(value: string | null | undefined) {
    this._description = value ?? '';
    this.descriptionIsHtml = /<\/?[a-z][\s\S]*>/i.test(value ?? '');
  }

  get description(): string {
    return this._description;
  }

  descriptionIsHtml: boolean;

  private _description: string;
}
