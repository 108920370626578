import { inject, Injectable } from '@angular/core';
import { PersonHelpers } from '@onyxx/model/person';
import { LanguageHelpers } from '@onyxx/model/user-language';
import { CountriesStoreFacade } from '@onyxx/store/countries';
import { PersonStoreFacade } from '@onyxx/store/person';
import { UserLanguageStoreFacade } from '@onyxx/store/user-language';
import { filterNil } from '@onyxx/utility/observables';
import { OnyxxLanguageTranslatePipe } from '@onyxx/utility/translations';
import { combineLatest, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageSelectionService {
  private personStoreFacade = inject(PersonStoreFacade);
  private userLanguageStoreFacade = inject(UserLanguageStoreFacade);
  private countriesStoreFacade = inject(CountriesStoreFacade);
  private onyxxLanguageTranslatePipe = inject(OnyxxLanguageTranslatePipe);

  getLanguageSettings(): Observable<{
    headerDescription: string;
    languageOptions: { title: string; value: string; image: string }[];
    selectedValue: string;
  }> {
    return combineLatest([
      this.personStoreFacade.person$.pipe(filterNil()),
      this.userLanguageStoreFacade.selectedLanguage$,
      this.countriesStoreFacade.countries$.pipe(filterNil()),
    ]).pipe(
      map(([person, userLanguage, countries]) => {
        const contractCountry = PersonHelpers.contractCountry(person) ?? 'NLD';
        const displayLanguage = this.onyxxLanguageTranslatePipe.transform(userLanguage);

        // Language options for the dropdown
        const languageOptions = LanguageHelpers.formalLanguagesByCountry(contractCountry).map((value) => ({
          value: LanguageHelpers.getIso2FromLanguage(value),
          title: this.onyxxLanguageTranslatePipe.transform(value),
          image: `img/flags/${LanguageHelpers.getIso3FromLanguage(value).toLowerCase()}.png`,
        }));

        // The selected value in the dropdown, including a fallback for when no dropdown is shown. Make sure the
        // value on the person is a valid option
        let selectedValue = languageOptions[0].value;
        if (person.language && languageOptions.some((option) => option.value === person.language) && languageOptions.length > 1) {
          selectedValue = person.language;
        }

        // The form page header description
        let headerDescription = '';
        if (languageOptions.length > 1) {
          headerDescription = $localize`:@@onboarding.person.language.description.selection:<p>Your display language is ${displayLanguage}, but certain parts of Semmie's services are not available in this language.</p><p>Although the app remains available in ${displayLanguage}, formal documents and messages are displayed in a different language due to laws and regulations. Please select your formal language below.</p>`;
        } else {
          const countryLabel = countries.find((country) => country.iso3 === contractCountry)?.title ?? '';
          const formalLanguage = this.onyxxLanguageTranslatePipe.transform(LanguageHelpers.formalLanguagesByCountry(contractCountry)[0]);
          headerDescription = $localize`:@@onboarding.person.language.description.forced:<p>Your display language is ${displayLanguage}, but because you are opening a contract in ${countryLabel}, certain parts of Semmie's service are only available in ${formalLanguage}.</p><p>Although the app remains available in ${displayLanguage}, formal documents and messages are displayed in ${formalLanguage} due to legal requirements.</p>`;
        }

        return {
          headerDescription,
          selectedValue,
          languageOptions,
        };
      }),
    );
  }
}
