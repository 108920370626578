import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { UntypedFormGroup, NgControl } from '@angular/forms';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { iRadioButtonGroupFormField, iRadioButtonGroupOption } from '@semmie/schemas/components/dynamic-form';

@Component({
  selector: 'semmie-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  standalone: false,
})
export class RadioButtonGroupComponent<T> extends BaseFormComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  /** The display text of the field */
  @Input() label: iRadioButtonGroupFormField<T>['label'];
  @Input() layout: iRadioButtonGroupFormField<T>['layout'];
  @Input() options: iRadioButtonGroupOption<T>[];
  @Input() itemPadding?: string;

  constructor(@Optional() @Self() ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  changeValue(value: any): void {
    this.value = value;
  }
}
