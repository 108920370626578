import { BankCode } from './banks.enum';

export const bankLogoName = {
  [BankCode.ABNA]: 'abnanl2a',
  [BankCode.ASNB]: 'asnbnl21',
  [BankCode.BUNQ]: 'bunqnl2a',
  [BankCode.INGB]: 'ingbnl2a',
  [BankCode.KNAB]: 'knabnl2h',
  [BankCode.MOYO]: 'moyonl21',
  [BankCode.RABO]: 'rabonl2u',
  [BankCode.RBRB]: 'rbrbnl21',
  [BankCode.SNSB]: 'snsbnl2a',
  [BankCode.TRIO]: 'trionl2u',
  [BankCode.FVLB]: 'fvlbnl22',
  [BankCode.HAND]: 'handnl2a',
  [BankCode.REVO]: 'revolt21',
  [BankCode.NNBA]: 'nnbanl2g',
  [BankCode.BELF]: 'gkccbebb',
  [BankCode.BNPB]: 'gebabebb',
  [BankCode.BPOST]: 'bpotbeb1',
  [BankCode.CITI]: 'citinl2x',
  [BankCode.KBCB]: 'kredbebb',
  [BankCode.ARSP]: 'arspbe22',
  [BankCode.NICA]: 'nicabebb',
  [BankCode.BBRU]: 'bbrubebb',
  [BankCode.DELE]: 'delebe22nra',
  [BankCode.AXAB]: 'axabbe22',
  [BankCode.CTBK]: 'ctbkbebx',
  [BankCode.BSCH]: 'bschbebb',
  [BankCode.KEYT]: 'keytbebb',
  [BankCode.NTSB]: 'ntsbdeb1',
  [BankCode.DEFAULT]: 'semmie_logomark',
} as const;

export const bankNames = {
  [BankCode.ABNA]: 'ABN Amro Bank',
  [BankCode.ASNB]: 'ASN Bank',
  [BankCode.BUNQ]: 'bunq',
  [BankCode.INGB]: 'ING',
  [BankCode.KNAB]: 'Knab',
  [BankCode.MOYO]: 'Moneyou',
  [BankCode.RABO]: 'Rabobank',
  [BankCode.RBRB]: 'RegioBank',
  [BankCode.SNSB]: 'SNS Bank',
  [BankCode.TRIO]: 'Triodos Bank',
  [BankCode.FVLB]: 'Van Lanschot Bankiers',
  [BankCode.HAND]: 'Handelsbanken',
  [BankCode.REVO]: 'Revolut',
  [BankCode.NNBA]: 'Nationale Nederlanden',
  [BankCode.BELF]: 'Belfius Bank',
  [BankCode.BNPB]: 'BNP Paribas',
  [BankCode.BPOST]: 'bpost bank',
  [BankCode.CITI]: 'Citibank',
  [BankCode.KBCB]: 'KBC Bank',
  [BankCode.ARSP]: 'Argenta Bank',
  [BankCode.NICA]: 'Crelan',
  [BankCode.BBRU]: 'ING Belgium',
  [BankCode.DELE]: 'Delen Private Bank',
  [BankCode.AXAB]: 'AXA Bank Belgium',
  [BankCode.CTBK]: 'Beobank',
  [BankCode.BSCH]: 'Santander',
  [BankCode.KEYT]: 'Keytrade Bank',
  [BankCode.NTSB]: 'N26',
  [BankCode.DEFAULT]: 'Onbekend',
} as const;
