import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, NgModule, Output } from '@angular/core';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { BaseComponent } from '@semmie/components/_abstract';
import { Icon, LabelSize } from '@semmie/schemas';
import { SharedModule } from '@semmie/shared/shared.module';
import { Utils } from '@onyxx/utility/general';

@Component({
  selector: 'semmie-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ModalHeaderComponent extends BaseComponent {
  @HostBinding('attr.title') _title = '';
  @Input() title?: string;
  @Input() titleSize: LabelSize = 'h2';
  @Input() hideCloseButton = false;

  @Output() onCloseClick: EventEmitter<void> = new EventEmitter();
  @HostBinding('class') get padding() {
    if (Utils.isNil(this.title)) {
      return 'pt-7 pb-11';
    }
    return this.titleSize == 'h3' ? 'pt-7 pb-3' : 'pt-7 pb-6';
  }

  readonly Icon = Icon;

  closeClick(): void {
    this.onCloseClick.emit();
  }
}

@NgModule({
  declarations: [ModalHeaderComponent],
  imports: [SharedModule, LabelModule, IconComponent],
  exports: [ModalHeaderComponent],
})
export class ModalHeaderModule {}
