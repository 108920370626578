import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
  Optional,
  Self,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { RxLet } from '@rx-angular/template/let';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { BaseFormComponent } from '@semmie/components/_abstract';
import { CarouselSlideModule } from '@semmie/components/presentational/core/carousel-slide/carousel-slide.module';
import { CarouselModule } from '@semmie/components/presentational/core/carousel/carousel.module';
import { ImageModule } from '@semmie/components/presentational/core/image';
import { LabelModule } from '@semmie/components/presentational/core/label';
import { UploadComponent } from '@semmie/components/presentational/core/upload/upload.component';
import { Icon } from '@semmie/schemas';
import { UploadLayout, iUploadFormField } from '@semmie/schemas/components/dynamic-form';
import { iCoverImageSelectorField } from '@semmie/schemas/components/dynamic-form/form-fields';
import { HttpMethod } from '@semmie/schemas/generics/http/http-method.enum';
import { ThemeService } from '@semmie/services/theme/theme.service';
import { placeholderImages } from '@semmie/shared/account/placeholder-image-urls';
import { ReplaySubject, combineLatest, map } from 'rxjs';
import { Account } from '@onyxx/model/account';

@Component({
  selector: 'semmie-cover-image-selector',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UploadComponent, CarouselModule, CarouselSlideModule, ImageModule, LabelModule, RxLet, IconComponent],
  animations: [
    trigger('checkEnter', [
      transition('void => *', [
        style({ transform: 'translateY(10px)', opacity: 0 }),
        animate(200, style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    trigger('activeSlide', [
      state(
        'active',
        style({
          opacity: 1,
        }),
      ),
      state(
        'inactive',
        style({
          transform: 'scale(0.90)',
          opacity: 0.6,
        }),
      ),
      transition('active => inactive', [animate('200ms')]),
      transition('inactive => active', [animate('200ms')]),
    ]),
  ],
  templateUrl: './cover-image-selector.component.html',
})
export class CoverImageSelectorComponent extends BaseFormComponent implements OnInit {
  @ViewChild(UploadComponent, { static: false }) upload: UploadComponent;

  @Input({ required: true }) url: iCoverImageSelectorField['url'];
  @Input({ required: true }) formDataParamName: string;

  /** Indicates if the file should be uploaded after the entity is created */
  @Input() uploadAfterCreation: iCoverImageSelectorField['uploadAfterCreation'] = false;

  readonly HttpMethod = HttpMethod;
  readonly UploadLayout = UploadLayout;
  readonly Icon = Icon;

  private themeService = inject(ThemeService);

  // observable that keeps track of the current value
  private value$$ = new ReplaySubject<Account['cover']>(1);

  private placeholderImages$ = this.themeService.premiumThemeAvailable$.pipe(
    map((premiumThemeAvailable) => placeholderImages(premiumThemeAvailable)),
  );

  readonly placeholderImages$$ = toSignal(this.placeholderImages$, { initialValue: [] });

  readonly selectedIndex$ = combineLatest([this.placeholderImages$, this.value$$.asObservable()]).pipe(
    map(([images, value]) => {
      const index = images.findIndex(({ id }) => id === value.index);
      if (index < 0) {
        return 0;
      }

      const newIndex = value.url == null ? index : index + 1;
      return newIndex;
    }),
  );

  readonly uploadParams$$ = signal<iUploadFormField['params']>({});
  readonly uploadedFileUrl$$ = signal<string | undefined>(undefined);
  readonly hasCustomFile$$ = computed(() => this.uploadedFileUrl$$() != null);
  readonly selectedIndex$$ = toSignal(this.selectedIndex$, { initialValue: 0 });
  readonly selectedImageId$$ = computed(() => {
    return this.valueIndexFromCarouselIndex(this.selectedIndex$$());
  });
  readonly uploadImageLabel$$ = computed(() =>
    this.hasCustomFile$$()
      ? $localize`:@@cover-image-selector.edit:Change image`
      : $localize`:@@cover-image-selector.upload:Upload your own image`,
  );
  /* eslint-enable @typescript-eslint/member-ordering */

  private destroyRef = inject(DestroyRef);
  // keep track whether the file should be uploaded on form submit
  private fileShouldBeUploaded = false;

  constructor(@Optional() @Self() ngControl: NgControl) {
    super(ngControl);
  }

  uploadCustomImage() {
    return this.upload.upload();
  }

  customFileSelected() {
    return this.fileShouldBeUploaded;
  }

  ngOnInit(): void {
    super.ngOnInit();
    const defaultValue: Account['cover'] = { url: null, index: 0 };
    this.value$$.next(this.safeValue);
    this.uploadedFileUrl$$.set(this.safeValue.url ?? undefined);

    this.formControl.valueChanges?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: Account['cover'] | null) => {
      this.value$$.next(value || defaultValue);
    });
  }

  async selectionChanged(carouselIndex: number) {
    this.writeValue({ ...this.safeValue, index: this.valueIndexFromCarouselIndex(carouselIndex) });
  }

  onCustomFileSelected(file: File) {
    const uploadParams = {};
    uploadParams[this.formDataParamName] = file;
    this.uploadParams$$.set(uploadParams);

    // load file to preview locally
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      this.fileShouldBeUploaded = true;
      this.uploadedFileUrl$$.set(e.target?.result as string);

      this.writeValue({ ...this.safeValue, url: file.name, index: 0 });
    };
    reader.readAsDataURL(file);
  }

  onCustomFileDeleted() {
    this.fileShouldBeUploaded = false;
    this.writeValue({ ...this.safeValue, url: null });
    this.uploadedFileUrl$$.set(undefined);
  }

  private valueIndexFromCarouselIndex(carouselIndex: number) {
    if (this.hasCustomFile$$() && carouselIndex === 0) return 0;

    const image = this.placeholderImages$$()[this.hasCustomFile$$() ? carouselIndex - 1 : carouselIndex];
    return image?.id ?? 0;
  }

  private get safeValue(): Account['cover'] {
    return this.value || { url: null, index: 0 };
  }
}
