import { Component } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';

@Component({
  selector: 'semmie-modal-scroll-content',
  template: '<ng-content></ng-content>',
  styleUrls: ['./modal-scroll-content.component.scss'],
  standalone: false,
})
export class ModalScrollContentComponent extends BaseComponent {}
