import { Person, PersonHelpers } from '@onyxx/model/person';
import { Language } from '@onyxx/model/user-language';
import { Person as LegacyPerson } from '@semmie/models';

export const PersonLanguageFormReference = 'language';

/**
 * Check if the language form should be shown during the person onboarding
 */
export const shouldShowLanguageForm = (person: Partial<Person | LegacyPerson>, userLanguage: Language = Language.nl): boolean => {
  return !PersonHelpers.isLanguageValidContractLanguage(person, userLanguage);
};
