import { DatePicker, DatePickerOptions } from '@pantrist/capacitor-date-picker';
import moment from 'moment';
import { DatePickerResult, DatePickerService } from './datepicker.service';
import { inject, LOCALE_ID } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ThemeService } from '@semmie/services/theme/theme.service';
import { DynamicLocaleId } from '@onyxx/model/user-language';

export class DatePickerNativeService extends DatePickerService {
  private themeService = inject(ThemeService);
  private currentTheme = toSignal(this.themeService.currentTheme$);
  private locale = inject<DynamicLocaleId>(LOCALE_ID);

  present(options: DatePickerOptions): Promise<DatePickerResult> {
    const params: DatePickerOptions = {
      mode: 'date',
      locale: this.locale.toString(),
      format: 'yyyy/MM/dd',
      date: moment().format('yyyy/MM/DD'),
      theme: this.currentTheme()?.useDarkTheme ? 'dark' : 'light',
      doneText: $localize`:@@date-picker.done:Ok`,
      cancelText: $localize`:@@date-picker.cancel:Cancel`,
      ...options,
    };

    return DatePicker.present(params);
  }
}
