import { EnvironmentProviders, Provider, ProviderToken } from '@angular/core';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { UserLanguageStoreFacade } from './user-language.facade';
import { userLanguageFeature } from './user-language.reducer';
import { UserLanguageEffects } from './user-language.effects';
import { UserLanguageStoreConfig } from './user-language-store-config.interface';
import { USER_LANGUAGE_STORE_CONFIG } from './user-language-store-config.token';

export const provideUserLanguageState = (config: ProviderToken<UserLanguageStoreConfig>): (EnvironmentProviders | Provider)[] => {
  return [
    provideState(userLanguageFeature),
    provideEffects(UserLanguageEffects),
    UserLanguageStoreFacade,
    {
      provide: USER_LANGUAGE_STORE_CONFIG,
      useValue: config,
    },
  ];
};

export * from './user-language.facade';
export { UserLanguageStoreConfig } from './user-language-store-config.interface';
