export type Scenario = {
  id: number;
  header: string;
  description: string;
  title: string;
  layout?: string;
  icon?: string;
  projection: {
    from: number;
    to: number;
  };
};

export const getScenarios = (): Map<
  number,
  {
    description: string;
    scenarios: Scenario[];
  }
> => {
  return new Map([
    [
      214,
      {
        description: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.description:Suppose you have just deposited {{depositDisplay}} into your Semmie account. The world economy is stable and so is the stock market. How would you invest in this situation?`,
        scenarios: [
          {
            id: 550,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.defensive.header:Risk of loss: Lower`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.defensive.description:You want to limit risk and prefer stability over higher returns.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.defensive.title:Defensive`,
            projection: {
              from: 0,
              to: 3,
            },
          },
          {
            id: 551,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.neutral.header:Risk of loss: Average`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.neutral.description:You take less risk, but this also limits the potential return.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.neutral.title:Neutral`,
            projection: {
              from: -1,
              to: 6,
            },
          },
          {
            id: 552,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.offensive.header:Risk of loss: Higher`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.offensive.description:By taking greater risk of loss, a higher return is possible.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.boarding.offensive.title:Offensive`,
            projection: {
              from: -3,
              to: 9,
            },
          },
        ],
      },
    ],
    [
      215,
      {
        description: $localize`:@@questionnaire.risk-appetite.scenarios.stable.description:Since your first deposit, the market has risen significantly. You have a return of {{{percentageDisplay}}} and your balance is {{depositDisplay}}. Would you invest differently in this situation?`,
        scenarios: [
          {
            id: 553,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.stable.defensive.header:Risk of loss: Lower`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.stable.defensive.description:You do not want to lose this earned return. Stability above all.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.stable.defensive.title:Defensive`,
            projection: {
              from: 0,
              to: 5,
            },
          },
          {
            id: 554,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.stable.neutral.header:Risk of loss: Average`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.stable.neutral.description:You seek a balance between maintaining balance and return. Benefit, but with limited risk.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.stable.neutral.title:Neutral`,
            projection: {
              from: -1,
              to: 9,
            },
          },
          {
            id: 555,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.stable.offensive.header:Risk of loss: Higher`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.stable.offensive.description:You want to fully benefit from the rising market. High risk, higher return.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.stable.offensive.title:Offensive`,
            projection: {
              from: -5,
              to: 13,
            },
          },
        ],
      },
    ],
    [
      216,
      {
        description: $localize`:@@questionnaire.risk-appetite.scenarios.panic.description:A recession is looming and the stock market is collapsing. Due to this setback, your balance is now {{depositDisplay}}. This makes your total return {{{percentageDisplay}}}. With what approach will you continue to invest?`,
        scenarios: [
          {
            id: 556,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.panic.defensive.header:Risk of loss: Lower`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.panic.defensive.description:You want to avoid a negative return above all, as far as possible.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.panic.defensive.title:Defensive`,
            projection: {
              from: -4,
              to: 0,
            },
          },
          {
            id: 557,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.panic.neutral.header:Risk of loss: Average`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.panic.neutral.description:You do not want to lose too much due to this dip, but also hope to earn something again when the economy recovers.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.panic.neutral.title:Neutral`,
            projection: {
              from: -12,
              to: 10,
            },
          },
          {
            id: 558,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.panic.offensive.header:Risk of loss: Higher`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.panic.offensive.description:By taking greater risk of loss, a higher return is possible.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.panic.offensive.title:Offensive`,
            projection: {
              from: -28,
              to: 25,
            },
          },
        ],
      },
    ],
    [
      217,
      {
        description: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.description:The recession turned out to be less severe. The indices are stable again and the prices have recovered. Your return is now {{{percentageDisplay}}} and your balance is {{depositDisplay}}. With what approach will you continue to invest?`,
        scenarios: [
          {
            id: 559,
            layout: 'projection',
            icon: 'low',
            header: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.defensive.header:Risk of loss: Lower`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.defensive.description:Missing potential return is less important than losing balance.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.defensive.title:Defensive`,
            projection: {
              from: -2,
              to: 3,
            },
          },
          {
            id: 560,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.neutral.header:Risk of loss: Average`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.neutral.description:You take less risk, but this also limits the potential return.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.neutral.title:Neutral`,
            projection: {
              from: -4,
              to: 6,
            },
          },
          {
            id: 561,
            header: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.offensive.header:Risk of loss: Higher`,
            description: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.offensive.description:If the market rises further, you want to benefit from it. High risk is acceptable.`,
            title: $localize`:@@questionnaire.risk-appetite.scenarios.recovery.offensive.title:Offensive`,
            projection: {
              from: -8,
              to: 12,
            },
          },
        ],
      },
    ],
  ]);
};
