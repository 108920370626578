import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { userLanguageFeature } from './user-language.reducer';
import { filterNil } from '@onyxx/utility/observables';
import { userLanguageCommonActions } from './user-language-common.actions';

@Injectable()
export class UserLanguageStoreFacade {
  private readonly store = inject(Store);

  readonly ready$ = this.store.select(userLanguageFeature.selectReady);
  readonly selectedLanguage$ = this.store.select(userLanguageFeature.selectSelectedLanguage);
  readonly availableLanguages$ = this.store.select(userLanguageFeature.selectAvailableLanguages).pipe(filterNil());

  /** When the language store is used outside of the user areas, it has to be initialized manually using this dispatch function */
  dispatchInitialize() {
    this.store.dispatch(userLanguageCommonActions.initialize());
  }
}
