import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {
  CSP_NONCE,
  DEFAULT_CURRENCY_CODE,
  EnvironmentProviders,
  ErrorHandler,
  LOCALE_ID,
  Provider,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { RouteReuseStrategy, Routes } from '@angular/router';

import { provideEffects } from '@ngrx/effects';
import { FullRouterStateSerializer, NavigationActionTiming, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { StoreDevtoolsConfig, provideStoreDevtools } from '@ngrx/store-devtools';

import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { IonicRouteStrategy } from '@ionic/angular';

import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';

import * as SentryAngular from '@sentry/angular-ivy';

import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio/ngx';
import { CriticalTaskGuard, IsAppGuard, IsMobileGuard } from '@semmie/guards';
import { AuthInterceptor } from '@semmie/interceptors/auth/auth.interceptor';
import { ConfigProvider } from '@semmie/providers';
import { AccountsProvider } from '@semmie/providers/accounts/accounts.provider';
import { BoosterProvider } from '@semmie/providers/booster/booster.provider';
import { InstrumentProvider } from '@semmie/providers/instrument/instrument.provider';
import { InvitationProvider } from '@semmie/providers/invitation/invitation.provider';
import { PersonProvider } from '@semmie/providers/person/person.provider';
import { QuestionnaireProvider } from '@semmie/providers/questionnaire/questionnaire.provider';
import { ReferralProvider } from '@semmie/providers/referral/referral.provider';
import { SigningProvider } from '@semmie/providers/signing/signing.provider';
import { TaskProvider } from '@semmie/providers/task/task.provider';
import { TransactionsProvider } from '@semmie/providers/transactions/transactions.provider';
import { UploadProvider } from '@semmie/providers/upload/upload.provider';
import { UserProvider } from '@onyxx/provider/user';
import { HTTP_IMPLEMENTATION } from '@onyxx/model/http';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { ConfigService } from '@semmie/services';
import { DynamoDBMarshallService } from '@semmie/services/aws/dynamodb/dynamodb-marshall.service';
import { ChatNativeService, ChatService, ChatWebService } from '@semmie/services/chat';
import { ToastService } from '@semmie/services/toast/toast.service';

import { TasksProvider } from '@semmie/providers/tasks/tasks.provider';
import { WithdrawalsProvider } from '@semmie/providers/withdrawals/withdrawals.provider';

import { SemmieCurrencyPipe } from '@semmie/pipes/currency/currency.pipe';
import { SemmiePercentagePipe } from '@semmie/pipes/percentage/percentage.pipe';

import { InitializerGuard } from '@semmie/guards/authentication/initializer.guard';
import { MaintenanceInterceptor } from '@semmie/interceptors/maintenance/maintenance.interceptor';
import { RequestErrorInterceptor } from '@semmie/interceptors/request-error/request-error.interceptor';

import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { SemmiePhonePipe } from '@semmie/pipes/phone/phone.pipe';
import { DatePickerNativeService, DatePickerService, DatePickerWebService } from '@semmie/services/datepicker';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { environment } from '../environments/environment';
import { provideAuthState } from '@onyxx/store/auth';
import { AuthStoreConfigService } from '@semmie/services/auth-store-config/auth-store-config.service';

import { providePushNotificationsState } from '@onyxx/store/push-notifications';
import { PushNotificationsConfigService } from '@semmie/services/push-notifications-config/push-notifications-config.service';
import { OnyxxDatePipe } from '@onyxx/utility/date-pipe';
import { userArea, publicArea } from '@semmie/areas';
import { UserTaskProvider } from '@onyxx/provider/user-task';
import {
  OnyxxAccountKindTranslatePipe,
  OnyxxLanguageTranslatePipe,
  OnyxxRiskProfileAdjectiveTranslatePipe,
} from '@onyxx/utility/translations';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import { provideUserLanguageState, UserLanguageStoreFacade } from '@onyxx/store/user-language';
import { toSignal } from '@angular/core/rxjs-interop';
import { DynamicLocaleId } from '@onyxx/model/user-language';
import { UserLanguageStoreConfigService } from '@semmie/services/user-language-store-config/user-language-store-config.service';
import { INTERCOM_APP_ID } from '@semmie/tokens';

// TODO: move critical tasks to own feature
export const enum CriticalTaskRouteNames {
  PhoneVerification = 'phone-verification',
}

export const enum AppRouteParameterNames {
  Token = 'token',
  UnlockToken = 'unlocktoken',
  Id = 'id',
}

export const routes: Routes = [
  publicArea(),
  userArea(),
  {
    path: '**',
    redirectTo: '',
  },
];

export const getStoreDevtoolsOptions = () =>
  ({
    name: 'Semmie client portal',
    logOnly: !isDevMode(),
    features: { test: false, reorder: false, skip: false, jump: true },
    autoPause: true,
    trace: true,
    traceLimit: 75,
  }) as StoreDevtoolsConfig;

export const getStoreConfig = () =>
  [
    { router: routerReducer },
    {
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: false,
        strictActionTypeUniqueness: true,
        strictActionSerializability: false,
      },
    },
  ] as const;

class LocalId extends DynamicLocaleId {
  private readonly userLanguageStoreFacade = inject(UserLanguageStoreFacade);
  private readonly selectedLanguage$$ = toSignal(this.userLanguageStoreFacade.selectedLanguage$.pipe(), {
    requireSync: true,
  });

  toString() {
    const language = this.selectedLanguage$$();
    return language.toString();
  }

  language() {
    return this.selectedLanguage$$();
  }
}

export const providers: (EnvironmentProviders | Provider)[] = [
  {
    provide: LOCALE_ID,
    useClass: LocalId,
  },
  {
    provide: CSP_NONCE,
    useValue: import.meta.env.NG_APP_NONCE,
  },
  {
    provide: DEFAULT_CURRENCY_CODE,
    useValue: 'EUR',
  },
  {
    provide: 'googleTagManagerId',
    useValue: environment.gtmId,
  },
  {
    provide: 'googleTagManagerMode',
    useValue: 'silent',
  },
  {
    provide: 'googleTagManagerCSPNonce',
    useValue: import.meta.env.NG_APP_NONCE,
  },
  {
    provide: INTERCOM_APP_ID,
    useValue: import.meta.env.NG_APP_INTERCOM_APP_ID,
  },
  provideAppInitializer(() => {
    const initializerFn = (
      (configService: ConfigService) => () =>
        configService.loadConfig()
    )(inject(ConfigService));
    return initializerFn();
  }),
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MaintenanceInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_IMPLEMENTATION,
    useClass: HttpClient,
  },
  {
    provide: APPLICATION_ENVIRONMENT,
    useValue: environment,
  },
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  {
    provide: ChatService,
    useFactory: (platformService: PlatformService) => {
      return platformService.isApp ? new ChatNativeService() : new ChatWebService();
    },
    deps: [PlatformService],
  },
  {
    provide: DatePickerService,
    useFactory: (platformService: PlatformService) => {
      return platformService.isApp ? new DatePickerNativeService() : new DatePickerWebService();
    },
    deps: [PlatformService],
  },
  ...(environment.production
    ? [
        {
          provide: ErrorHandler,
          useValue: SentryAngular.createErrorHandler(),
        },
      ]
    : []),
  {
    provide: APP_BASE_HREF,
    useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
    deps: [PlatformLocation],
  },
  FingerprintAIO,
  ConfigProvider,
  AccountsProvider,
  UserProvider,
  PersonProvider,
  QuestionnaireProvider,
  TaskProvider,
  BoosterProvider,
  ReferralProvider,
  UploadProvider,
  DynamoDBMarshallService,
  ToastService,
  MessageService,
  DialogService,
  InvitationProvider,
  InstrumentProvider,
  TransactionsProvider,
  WebIntent,
  SigningProvider,
  WithdrawalsProvider,
  TasksProvider,
  CriticalTaskGuard,
  IsAppGuard,
  IsMobileGuard,
  SemmieCurrencyPipe,
  SemmiePercentagePipe,
  SemmiePhonePipe,
  OnyxxDatePipe,
  OnyxxAccountKindTranslatePipe,
  OnyxxRiskProfileAdjectiveTranslatePipe,
  OnyxxLanguageTranslatePipe,
  InitializerGuard,
  UserTaskProvider,

  // Store dependencies
  provideStore(...getStoreConfig()),
  provideStoreDevtools(getStoreDevtoolsOptions()),
  provideEffects(),
  provideRouterStore({
    navigationActionTiming: NavigationActionTiming.PostActivation,
    serializer: FullRouterStateSerializer,
  }),

  provideAuthState(AuthStoreConfigService),
  providePushNotificationsState(PushNotificationsConfigService),

  providePrimeNG({
    theme: {
      preset: Aura,
    },
  }),
  provideUserLanguageState(UserLanguageStoreConfigService),
];
