/* eslint-disable @typescript-eslint/member-ordering */
import { inject } from '@angular/core';

import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, repeat, switchMap, take, tap } from 'rxjs/operators';

import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { ChatService } from '@semmie/services/chat';
import { UserProvider } from '@onyxx/provider/user';

import { UserAPIActions } from './actions/user-api.actions';
import { UserCommonActions } from './actions/user-common.actions';

import { UserStoreFacade } from '@semmie/store/user/user.facade';
import { iUser } from '@onyxx/model/user';
import { AuthFacade } from '@onyxx/store/auth';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ToastService } from '@semmie/services';
import { GenericErrorToast, ToasterStyle } from '@semmie/schemas/components/toast';
import { Utils } from '@onyxx/utility/general';

export class UserEffects implements OnInitEffects {
  private readonly userStoreFacade = inject(UserStoreFacade);
  private readonly actions$ = inject(Actions);
  private readonly userProvider = inject(UserProvider);
  private readonly chatService = inject(ChatService);
  private readonly authFacade = inject(AuthFacade);
  private readonly toastService = inject(ToastService);

  readonly loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAPIActions.load),
      // TODO: remove this `switchMap` when we don't have to load the userState in the AppModule and can be put behind the init/auth guard
      switchMap(() => this.authFacade.isAuthenticated$.pipe(filter(Boolean))),
      exhaustMap(() => {
        return this.userProvider.get().pipe(
          take(1),
          map((user) => {
            if (user === null) throw new Error('User not found');

            return UserAPIActions.loadSuccess({ user });
          }),
          catchError((error) => of(UserAPIActions.loadFailure({ error }))),
        );
      }),
    );
  });

  readonly updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAPIActions.update),
      concatMap(({ user }) =>
        this.userProvider.patch(user).pipe(
          map((user) => {
            if (user === null) throw new Error('User not found');

            return UserAPIActions.updateSuccess({ user });
          }),
          catchError((error) => of(UserAPIActions.updateFailure({ error }))),
        ),
      ),
    );
  });

  readonly errorToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserAPIActions.updateFailure),
        tap(() => {
          this.toastService.show(GenericErrorToast());
        }),
      );
    },
    { dispatch: false },
  );

  readonly updateMetaData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAPIActions.updateMetadata),
      concatLatestFrom(() => this.userStoreFacade.user$),
      concatMap(([{ newData }, existingUser]) => {
        const existingMeta = existingUser?.meta ?? {};
        const existingData = JSON.parse(existingMeta.data || '{}');
        const mergedMetaData = { ...existingData, ...newData };
        const updatedUser: Partial<iUser> = {
          meta: {
            ...existingMeta,
            data: JSON.stringify(mergedMetaData),
          },
        };
        return of(UserAPIActions.update({ user: updatedUser }));
      }),
    );
  });

  readonly updateCredentials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAPIActions.updateCredentials),
      exhaustMap(({ payload }) =>
        this.userProvider.updateCredentials(payload).pipe(
          take(1),
          map(() => UserAPIActions.updateCredentialsSuccess({ payload })),
          catchError((error) => of(UserAPIActions.updateCredentialsFailure({ error }))),
        ),
      ),
    );
  });

  readonly initializeChat$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserAPIActions.loadSuccess),
        exhaustMap(({ user }) => this.chatService.initialize(user.intercom?.id ?? '').catch()),
        take(1),
        repeat({
          delay: () => this.actions$.pipe(ofType(UserCommonActions.clear)),
        }),
      );
    },
    { dispatch: false },
  );

  readonly sendTwoFactorAuthenticationCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAPIActions.sendTwoFactorAuthenticationCode),
      concatLatestFrom(() => this.userStoreFacade.twoFactorResendAllowedAt$),
      filter(([, twoFactorResendAllowedAt]) => Utils.isNil(twoFactorResendAllowedAt) || twoFactorResendAllowedAt < Date.now()),
      exhaustMap(() => {
        return this.userProvider
          .setupTwoFactorAuthentication({
            two_factor_enabled: true,
          })
          .pipe(
            map(() => UserAPIActions.sendTwoFactorAuthenticationSuccess()),
            catchError((error) => of(UserAPIActions.sendTwoFactorAuthenticationFailure({ error }))),
          );
      }),
    );
  });

  readonly updateTwoFactorAuthentication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAPIActions.updateTwoFactorAuthentication),
      exhaustMap((request) => {
        const requestBody = request.enabled
          ? { two_factor_enabled: true, two_factor_verification_code: request.code }
          : { two_factor_enabled: false };
        return this.userProvider.setupTwoFactorAuthentication(requestBody).pipe(
          map(({ two_factor_enabled: enabledResult }) => UserAPIActions.updateTwoFactorAuthenticationSuccess({ enabled: enabledResult })),
          catchError((error) => of(UserAPIActions.updateTwoFactorAuthenticationFailure({ error }))),
        );
      }),
    );
  });

  readonly showUpdateTwoFactorFailureToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserAPIActions.updateTwoFactorAuthenticationFailure),
        tap(({ error }) => {
          const errorStatus = error instanceof HttpErrorResponse ? error.status : '';
          const message =
            {
              [HttpStatusCode.TooManyRequests]: $localize`:@@settings.security.two-factor-authentication.update.error-toast.too-many-requests:You provided an incorrect code too many times, please try again later`,
              [HttpStatusCode.UnprocessableEntity]: $localize`:@@settings.security.two-factor-authentication.update.error-toast.incorrect-code:The entered code is incorrect`,
              '': null,
            }[errorStatus] ??
            $localize`:@@settings.security.two-factor-authentication.update.error-toast.general:Failed to enable SMS authentication`;

          this.toastService.show({
            message,
            id: '2fa-toast',
            style: ToasterStyle.DANGER,
          });
        }),
      );
    },
    { dispatch: false },
  );

  readonly showSendTwoFactorCodeFailureToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserAPIActions.sendTwoFactorAuthenticationFailure),
        tap(({ error }) => {
          const errorStatus = error instanceof HttpErrorResponse ? error.status : '';
          const message =
            {
              [HttpStatusCode.TooManyRequests]: $localize`:@@settings.security.two-factor-authentication.send-code.error-toast.too-many-requests:You resent the code too many times, please try again later.`,
              '': null,
            }[errorStatus] ??
            $localize`:@@settings.security.two-factor-authentication.send-code.error-toast.general:Failed to send SMS authentication code`;

          this.toastService.show({
            message,
            id: '2fa-toast',
            style: ToasterStyle.DANGER,
          });
        }),
      );
    },
    { dispatch: false },
  );

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => UserCommonActions.clear()));
  });

  readonly logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserCommonActions.clear),
        exhaustMap(() => this.chatService.logout().catch()),
      );
    },
    { dispatch: false },
  );

  ngrxOnInitEffects() {
    return UserAPIActions.load();
  }
}
