/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */

import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { BaseComponent } from '@semmie/components/_abstract/base-component.component';

@Component({
  selector: 'semmie-base-form-component',
  template: '<ng-content></ng-content>',
  standalone: false,
})
export abstract class BaseFormComponent extends BaseComponent implements ControlValueAccessor, OnInit {
  @Input() name: string;
  @Input() validations: Array<string>;
  @Input() conditions: Array<string>;

  /**
   * Sets value by an expression
   */
  @Input() valuePath: string;

  /**
   * Filter out a specific property from an object
   */
  @Input() valueKey: string;

  formControl: NgControl;

  protected baseValue: any;

  constructor(
    @Optional() private ngControl?: NgControl,
    @Optional() private _cdr?: ChangeDetectorRef,
  ) {
    super();

    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.ngControl) {
      this.formControl = this.ngControl;
    }
  }

  get disabled(): boolean {
    return this?.formControl?.disabled ?? false;
  }

  get value(): any {
    return this.baseValue;
  }

  set value(newValue: any) {
    this.baseValue = newValue;
    this.onChange(newValue);
    this.onTouched(newValue);

    this._cdr?.markForCheck();
  }

  get valid() {
    if (this.formControl.control) {
      return this.formControl.control?.valid;
    }

    return this.value ?? false;
  }

  /* ControlValueAccessor mandatory region */

  writeValue(newValue: any): void {
    this.value = newValue;
  }

  registerOnChange(func: any): void {
    this.onChange = func;
  }

  registerOnTouched(func: any): void {
    this.onTouched = func;
  }

  private onChange = (f: any) => {};
  private onTouched = (f: any) => {};
}
