import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { UserLanguageStoreFacade } from '@onyxx/store/user-language';
import { filter } from 'rxjs';

export function userLanguageReadyGuard(): CanActivateFn {
  return () => {
    const userLanguageStoreFacade = inject(UserLanguageStoreFacade);

    return userLanguageStoreFacade.ready$.pipe(filter((ready) => ready));
  };
}
