import { Component } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';

@Component({
  selector: 'semmie-content-container-top',
  template: '<ng-content></ng-content>',
  styleUrls: ['./content-container-top.component.scss'],
  standalone: false,
})
export class ContentContainerTopComponent extends BaseComponent {}
