import { ChangeDetectionStrategy, Component, HostBinding, Input, inject } from '@angular/core';
import { ReplaySubject, combineLatest, map } from 'rxjs';
import { ImageModule, LabelModule } from '@semmie/components';
import { SharedModule } from '@semmie/shared/shared.module';
import { TagComponent } from '@semmie/components/presentational/core/tag/tag.component';
import { Account } from '@onyxx/model/account';
import { placeholderImageForAccount } from '@semmie/shared/account/placeholder-image-urls';
import { ThemeService } from '@semmie/services/theme/theme.service';

@Component({
  imports: [SharedModule, ImageModule, LabelModule, TagComponent],
  selector: 'semmie-account-thumbnail',
  templateUrl: 'account-thumbnail.component.html',
  styleUrls: ['./account-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountThumbnailComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input({ required: true }) set cover(cover: Account['cover']) {
    this.cover$$.next(cover);
  }
  @HostBinding('attr.title') _title = '';
  @Input() title?: string;
  @Input() label: string;

  cover$$: ReplaySubject<Account['cover']> = new ReplaySubject(1);

  private themeService = inject(ThemeService);

  image$ = combineLatest([this.cover$$.asObservable(), this.themeService.premiumThemeAvailable$]).pipe(
    map(([cover, premiumThemeAvailable]) => {
      return placeholderImageForAccount(cover, premiumThemeAvailable);
    }),
  );
}
