import { createFeature, createReducer, on } from '@ngrx/store';
import { userLanguageCommonActions } from './user-language-common.actions';
import { Language } from '@onyxx/model/user-language';

export interface State {
  ready: boolean;
  availableLanguages: Language[] | null;
  selectedLanguage: Language;
  overrideLanguage: Language | null;
}

const initialState: State = {
  ready: false,
  availableLanguages: null,
  selectedLanguage: Language.nl,
  overrideLanguage: null,
};

const reducer = createReducer(
  initialState,
  on(userLanguageCommonActions.setAvailableLanguages, (state, { languages }): State => ({ ...state, availableLanguages: languages })),
  on(userLanguageCommonActions.setSelectedLanguage, (state, { language }): State => ({ ...state, selectedLanguage: language })),
  on(userLanguageCommonActions.initializeDone, (state): State => ({ ...state, ready: true })),
  on(userLanguageCommonActions.reload, (state): State => ({ ...state, ready: false, availableLanguages: null })),
);

export const userLanguageFeature = createFeature({
  name: 'user-language',
  reducer,
});
