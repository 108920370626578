import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
type safeType = 'html' | 'url';
@Pipe({
  name: 'safe',
  standalone: false,
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | null | undefined, type?: safeType) {
    if (!value) return '';
    if (type === 'html') {
      const html = value.replace(/\n/g, '');
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    if (type === 'url') {
      return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
  }
}
