import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';
import { Icon, LabelSize, MenuItemColor } from '@semmie/schemas';
@Component({
  selector: 'semmie-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MenuItemComponent extends BaseComponent {
  @Input() hidden?: boolean;
  @Input() disabled?: boolean = false;
  @Input() clickable?: boolean = true;
  @Input() last: boolean;
  @Input() icon?: Icon;
  @Input() text?: string;
  @Input() contentTpl: TemplateRef<any>;
  @Input() contentContext: any;
  @Input() textSize: LabelSize = 'base';
  @Input() color?: MenuItemColor = 'default';

  /** Whether the right content area is also disabled (faded) when the component is disabled */
  @Input({ required: false }) disableRightContent = true;

  @Output() onClick: EventEmitter<void> = new EventEmitter();
  @Output() onDisabledClick: EventEmitter<void> = new EventEmitter();

  onClickHandler(): void {
    if (this.clickable) {
      if (this.disabled) return this.onDisabledClick.emit();

      this.onClick.emit();
    }
  }
}
