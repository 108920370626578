import { Route } from '@angular/router';
import { AuthenticatedAreaModule } from './authenticated-area.module';
import { userLanguageReadyGuard } from '../../../areas/guards/user-language-ready.guard';

export const authenticatedArea = (): Route => {
  return {
    path: '',
    loadChildren: () => AuthenticatedAreaModule,
    // The language is reloaded on login (moving to the public area after the user are has been activated),
    // and we have to wait for it to be ready before we can activate the route and construct the components.
    canActivate: [userLanguageReadyGuard()],
  };
};
