/* eslint-disable quotes */
import { DynamicFieldType, iDynamicForm } from '@semmie/schemas/components/dynamic-form';
import {
  DynamicField,
  ExternalDataSource,
  iDropdownFormField,
  iExternalDataFormField,
  iTextBoxFormField,
  ValuePathType,
} from '@semmie/schemas/components/dynamic-form/form-fields';

export const OnboardingPersonPhoneForm = (): iDynamicForm => {
  return {
    name: 'onboarding_person_phone',
    reference: 'phone',
    legacyEventId: 'person-mobile-number',
    descriptionSize: 'base_2',
    titleSize: 'h1',
    title: $localize`:@@form.person-phone.title:Mobile number`,
    flat: true,

    description: $localize`:@@form.person-phone.description:For important actions within the app we ask for additional confirmation with an sms code. Therefore make sure your number is correct.`,
    fields: [
      {
        name: 'countries_src',
        type: DynamicFieldType.ExternalData,
        once: true,
        store: {
          name: 'CountriesStore',
          source: ExternalDataSource.Countries,
        },
      } as iExternalDataFormField as DynamicField,

      {
        name: 'country_phone',
        type: DynamicFieldType.Dropdown,
        selection: {
          type: 'modal',
          search: true,
        },
        label: $localize`:@@form.field.country.label.phone:Country of number`,
        placeholder: $localize`:@@form.field.country.placeholder:Select a country`,
        options: {
          source: 'countries_src',
          label: 'title',
          value: '$',
          image: 'img/flags/{{iso3}}.png',
          imageStyle: 'rounded',
        },
        valuePathType: ValuePathType.DEFAULT_VALUE,
        valuePath: 'countries_src | [0]',
        validations: ['required'],
      } as iDropdownFormField as DynamicField,
      {
        name: 'phone',
        type: DynamicFieldType.Text,
        inputMode: 'tel',
        label: $localize`:@@form.field.phone.label:Mobile number`,
        placeholder: $localize`:@@form.field.phone.placeholder:Mobile number`,
        validations: ['required', 'numbersonly', 'phone(country_phone.iso3)'],
      } as iTextBoxFormField as DynamicField,
    ],
  };
};
