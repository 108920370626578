import { Injectable, inject } from '@angular/core';
import { APPLICATION_ENVIRONMENT } from '@onyxx/model/application-environment';
import { HTTP_IMPLEMENTATION, SKIP_AUTHENTICATION } from '@onyxx/model/http';
import { Observable } from 'rxjs';
import { NewTokenRequest, AuthSession, RevokeTokenRequest, RegisterRequest, SetPasswordRequest } from '@onyxx/model/auth';
import { iUser } from '@onyxx/model/user';
import { HttpContext } from '@angular/common/http';
import { Utils } from '@onyxx/utility/general';

@Injectable({ providedIn: 'root' })
export class AuthProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly apiUrl = inject(APPLICATION_ENVIRONMENT).apiUrl;

  /**
   * Request a new token
   * @param request
   * @returns
   *
   * @throws {HttpErrorResponse} 400 - Bad Request - invalid password or account locked
   * @throws {HttpErrorResponse} 422 - Unprocessable - two factor code required
   */
  requestToken(request: NewTokenRequest) {
    return this.http.post<AuthSession>(`${this.apiUrl}/oauth/token`, request, {
      context: new HttpContext().set(SKIP_AUTHENTICATION, true),
    });
  }

  revokeToken(request: RevokeTokenRequest): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/oauth/revoke`, request, { context: new HttpContext().set(SKIP_AUTHENTICATION, true) });
  }

  register(request: RegisterRequest): Observable<iUser> {
    if (!Utils.isNil(request.preferredLanguage)) {
      return this.http.post<iUser>(
        `${this.apiUrl}/user`,
        { ...request, user: { ...request.user, language: request.preferredLanguage } },
        { context: new HttpContext().set(SKIP_AUTHENTICATION, true) },
      );
    }

    return this.http.post<iUser>(
      `${this.apiUrl}/user`,
      { ...request, user: { ...request.user } },
      { context: new HttpContext().set(SKIP_AUTHENTICATION, true) },
    );
  }

  requestPasswordReset(email: string): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/user/password_request`,
      {
        user: { email },
      },
      { context: new HttpContext().set(SKIP_AUTHENTICATION, true) },
    );
  }

  setPassword(request: SetPasswordRequest): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}/user/password_update`, request, {
      context: new HttpContext().set(SKIP_AUTHENTICATION, true),
    });
  }

  unlockUser(token: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/user/unlock`, { token }, { context: new HttpContext().set(SKIP_AUTHENTICATION, true) });
  }
}
