import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';

import { CommonModule } from '@angular/common';
import { Icon } from '@semmie/schemas';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { LabelModule } from '@semmie/components/presentational/core/label';

@Component({
  selector: 'semmie-empty-placeholder',
  template: `
    <div class="flex flex-col items-center py-8">
      <semmie-icon class="empty-placeholder-icon" [icon]="icon"></semmie-icon>
      <semmie-label *ngIf="text" class="empty-placeholder-text" size="base_3" align="center" [htmlContent]="text"></semmie-label>
    </div>
  `,
  imports: [CommonModule, IconComponent, LabelModule],
  styleUrls: ['./empty-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyPlaceholderComponent extends BaseComponent {
  @Input() text: string;
  @Input() icon = Icon.PAPERPLANE;
}
