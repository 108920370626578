import { Injectable } from '@angular/core';
import { ChatService } from '@semmie/services/chat/chat.service';
import { Utils } from '@semmie/shared/utils';
import { noop } from 'rxjs';

import * as Url from 'url-parse';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  constructor(private chatService: ChatService) {}
  /**
   *
   * @param url
   */
  isUrl(url: string): boolean {
    try {
      return Boolean(new URL(url));
    } catch {
      return false;
    }
  }

  /**
   * Check if the URL is from our services.
   * @param url string
   */
  isSemmieOrOnyxxUrl(url: string): boolean {
    const parsedUrl = new Url(url);
    const parsedHostName = parsedUrl.hostname.split('.');
    let hostname = '';

    if (parsedHostName) {
      hostname = parsedHostName.length > 2 ? parsedHostName.slice(-2).join('.') : parsedHostName.join('.');
    }

    if (hostname.indexOf('semmie') > -1 || hostname.indexOf('onyxx') > -1) {
      return true;
    }

    return false;
  }

  getHostName(url: string): string {
    let parsedUrl = new Url();

    try {
      parsedUrl = new Url(url);
    } catch {
      // TODO: Assess why this block was empty before.
      noop();
    }

    return parsedUrl.hostname;
  }

  getPath(url: string): string {
    let parsedUrl = new Url();

    try {
      parsedUrl = new Url(url);
    } catch {
      // TODO: Assess why this block was empty before.
      noop();
    }

    return parsedUrl.pathname;
  }

  handleAnchor(url: string): void {
    if (this.isUrl(url) && !url.includes('localhost')) {
      Utils.openUrl(url);
    } else if (url.startsWith('semmie://')) {
      const action = url.replace('semmie://', '');

      switch (action) {
        case 'chat': {
          this.chatService.openChat();
          break;
        }
      }
    }
  }
}
