import { iDynamicForm } from '@semmie/schemas/components/dynamic-form';
import { Illustration } from '@semmie/shared/globals';

export const PersonIdentificationIntroForm = (): iDynamicForm => ({
  name: 'onboarding_person_identification',
  reference: 'identification_type',
  descriptionSize: 'base_2',
  titleSize: 'h2',
  legacyEventId: 'person-identification-intro',
  flat: true,
  intro: {
    label: $localize`:@@onboarding.person-identification.intro.label:Just a quick check`,
    title: $localize`:@@onboarding.person-identification.intro.title:Verify Your Identity`,
    description: $localize`:@@onboarding.person-identification.intro.description:To open your account, we are required to request a copy of your passport or ID card and verify your identity.`,
    image: Illustration.IDENTIFICATION,
    button: $localize`:@@wizard.next:Next`,
    leftAction: true,
    rightAction: true,
    footer: {
      text: $localize`:@@onboarding.person-identification.intro.footer-text:<strong>Note:</strong> a driver's license is unfortunately not sufficient. Your ID must be fully legible and in color.`,
    },
  },
  fields: [],
});
