import { inject } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { EMPTY, catchError, filter, map, of, switchMap, tap } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PersonProvider } from '@onyxx/provider/person';
import { personCommonActions } from './person-common.actions';
import { UserStoreFacade } from '@semmie/store/user';
import { personApiActions } from './person-api.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@semmie/services';
import { GenericErrorToast, ToasterStyle } from '@semmie/schemas/components/toast';
import { filterNil } from '@onyxx/utility/observables';
import { Utils } from '@onyxx/utility/general';
import { PersonStoreFacade } from './person.facade';

export class PersonEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);
  private readonly personProvider = inject(PersonProvider);
  private readonly authFacade = inject(AuthFacade);
  private readonly userStoreFacade = inject(UserStoreFacade);
  private readonly toastService = inject(ToastService);
  private readonly personStoreFacade = inject(PersonStoreFacade);

  readonly loadUserPerson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personCommonActions.initialize),
      switchMap(() => this.userStoreFacade.user$),
      map((user) => user?.person?.id),
      filterNil(),
      map((personId) => personApiActions.loadPerson({ personId })),
    );
  });

  readonly loadPerson$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(personApiActions.loadPerson),
      switchMap(({ personId }) => {
        return this.personProvider.get(personId).pipe(
          map((person) => personApiActions.loadSuccess({ person })),
          catchError((error: Error) => {
            return of(personApiActions.loadFailure({ errorMsg: error.message }));
          }),
        );
      }),
    );
  });

  readonly editPerson = createEffect(() => {
    return this.actions$.pipe(
      ofType(personApiActions.edit),
      concatLatestFrom(() => this.userStoreFacade.user$),
      switchMap(([{ person }, user]) => {
        const personId = user?.person?.id;
        if (Utils.isNil(personId)) {
          return EMPTY;
        }
        const payload = { ...person, id: undefined };
        return this.personProvider.update(personId, payload).pipe(
          map((person) => personApiActions.editSuccess({ person })),
          catchError((error: Error) => of(personApiActions.editFailure({ error }))),
        );
      }),
    );
  });

  readonly showEditErrorToast$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(personApiActions.editFailure),
        tap(({ error }) => {
          if (error instanceof HttpErrorResponse && error.error.details.phone?.[0].error === 'invalid') {
            this.toastService.show({
              message: $localize`:@@edit-phone-number.error.invalid:The telephone number you have entered is not valid.`,
              style: ToasterStyle.DANGER,
            });
            return;
          }

          this.toastService.show(GenericErrorToast());
        }),
      );
    },
    { dispatch: false },
  );

  // Only update the store if the person ID matches the current stored person
  readonly updateInStore = createEffect(() => {
    return this.actions$.pipe(
      ofType(personCommonActions.checkToUpdateInStore),
      concatLatestFrom(() => this.personStoreFacade.person$),
      filter(([{ id }, personInStore]) => {
        return id === personInStore?.id;
      }),
      map(([{ person }]) => {
        return personCommonActions.updateInStore({ person });
      }),
    );
  });

  readonly clear$ = createEffect(() => {
    return this.authFacade.loggedOut$.pipe(map(() => personCommonActions.clear()));
  });

  ngrxOnInitEffects() {
    return personCommonActions.initialize();
  }
}
