/* eslint-disable quotes */
import { DynamicField, DynamicFieldType, iDynamicForm, iExternalDataFormField } from '@semmie/schemas/components/dynamic-form';
import { ExternalDataSource, iDropdownFormField, iTextAreaFormField } from '@semmie/schemas/components/dynamic-form/form-fields';

export const PersonFinanceForm = (): iDynamicForm => ({
  name: 'onboarding_person_finance',
  reference: 'financial',
  descriptionSize: 'base_2',
  titleSize: 'h2',
  legacyEventId: 'person-income-and-capital',
  title: $localize`:@@onboarding.person.finance.title:Income and capital`,
  flat: true,
  infoModals: [
    {
      id: 'equity',
      subtitle: $localize`:@@onboarding.person.info-modal.subtitle:Information`,
      title: $localize`:@@onboarding.person.finance.info-modal.equity.title:Available equity`,
      description: $localize`:@@onboarding.person.finance.info-modal.equity.description:
        The available equity is the total amount of all your investments and cash or savings balances.
        If you own a home and/or commercial property, do not include that value.`,
    },
    {
      id: 'monthly_saving',
      subtitle: $localize`:@@onboarding.person.info-modal.subtitle:Information`,
      title: $localize`:@@onboarding.person.finance.info-modal.monthly-savings.title:How to calculate correctly?`,
      description: $localize`:@@onboarding.person.finance.info-modal.monthly-savings.description:
        Take your monthly net income (after taxes) and subtract all periodic monthly expenses from it. Think of rent,
        mortgage, groceries, insurance, subscriptions, etc. The final amount indicates how much money you have available
        to spend each month.`,
    },
  ],
  fields: [
    {
      name: 'questions_src',
      type: DynamicFieldType.ExternalData,
      once: true,
      store: {
        name: 'QuestionsStore',
        source: ExternalDataSource.Questions,
      },
    } as iExternalDataFormField as DynamicField,
    {
      name: 'question_source_of_income',
      type: DynamicFieldType.Dropdown,
      label: $localize`:@@form.field.source-of-income.label:What is your source of income?`,
      placeholder: $localize`:@@form.field.source-of-income.placeholder:Make a choice`,
      options: {
        source: 'questions_src.person.question_source_of_income',
        label: 'title',
        value: 'value',
      },
      validations: ['required'],
    } as iDropdownFormField as DynamicField,
    {
      name: 'question_source_of_income_answer',
      type: DynamicFieldType.Text,
      label: $localize`:@@form.field.source-of-income-answer.label:Namely`,
      placeholder: $localize`:@@form.field.source-of-income-answer.placeholder:Fill in your source of income`,
      conditions: ['question_source_of_income == `11`'],
      validations: ['required'],
    } as iTextAreaFormField as DynamicField,
    {
      name: 'question_income',
      type: DynamicFieldType.Dropdown,
      label: $localize`:@@form.field.income.label:What is your gross annual income?`,
      placeholder: $localize`:@@form.field.income.placeholder:Make a choice`,
      options: {
        source: 'questions_src.person.question_income',
        label: 'title',
        value: 'value',
      },
      validations: ['required'],
    } as iDropdownFormField as DynamicField,
    {
      name: 'question_equity',
      type: DynamicFieldType.Dropdown,
      label: $localize`:@@form.field.equity.label:What is your available equity?`,
      placeholder: $localize`:@@form.field.equity.placeholder:Make a choice`,
      infoModal: 'equity',
      options: {
        source: 'questions_src.person.question_equity',
        label: 'title',
        value: 'value',
      },
      validations: ['required'],
    } as iDropdownFormField as DynamicField,
    {
      name: 'question_equity_invest_percentage',
      type: DynamicFieldType.Dropdown,
      label: $localize`:@@form.field.equity-invest-percentage.label:What part of your available equity are you going to invest?`,
      placeholder: $localize`:@@form.field.equity-invest-percentage.placeholder:Make a choice`,
      options: {
        source: 'questions_src.person.question_equity_invest_percentage',
        label: 'title',
        value: 'value',
      },
      validations: ['required'],
    } as iDropdownFormField as DynamicField,
    {
      name: 'question_source_of_wealth',
      type: DynamicFieldType.Dropdown,
      label: $localize`:@@form.field.source-of-wealth.label:What makes up the majority of your available assets?`,
      placeholder: $localize`:@@form.field.source-of-wealth.placeholder:Make a choice`,
      options: {
        source: 'questions_src.person.question_source_of_wealth',
        label: 'title',
        value: 'value',
      },
      validations: ['required'],
    } as iDropdownFormField as DynamicField,
    {
      name: 'question_monthly_saving',
      type: DynamicFieldType.Dropdown,
      label: $localize`:@@form.field.monthly-saving.label:How much money do you have left each month?`,
      placeholder: $localize`:@@form.field.monthly-saving.placeholder:Make a choice`,
      infoModal: 'monthly_saving',
      options: {
        source: 'questions_src.person.question_monthly_saving',
        label: 'title',
        value: 'value',
      },
      validations: ['required'],
    } as iDropdownFormField as DynamicField,
  ],
});
