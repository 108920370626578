import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '@onyxx/model/user-language';

@Pipe({
  name: 'onyxxLanguageTranslate',
  standalone: true,
  pure: true,
})
export class OnyxxLanguageTranslatePipe implements PipeTransform {
  transform(denotation: Language, mode: 'default' | 'setting' = 'default'): string {
    if (mode === 'default') {
      switch (denotation) {
        case Language.nl:
          return $localize`:@@language.nl:Dutch`;
        case Language.en:
          return $localize`:@@language.en-gb:English`;
        case Language.fr:
          return $localize`:@@language.fr:French`;
      }
    }
    switch (denotation) {
      case Language.nl:
        return $localize`:@@language.setting.nl:Dutch (Nederlands)`;
      case Language.en:
        return $localize`:@@language.setting.en-gb:English`;
      case Language.fr:
        return $localize`:@@language.setting.fr:French (Français)`;
    }
  }
}
