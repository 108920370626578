import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { userLanguageCommonActions } from './user-language-common.actions';
import { combineLatest, defer, distinctUntilChanged, filter, map, of, switchMap, take } from 'rxjs';
import { ConfigService } from '@semmie/services/config/config.service';
import { UserStoreFacade } from '@semmie/store/user';
import { Language } from '@onyxx/model/user-language';
import { Utils } from '@onyxx/utility/general';
import { Store } from '@ngrx/store';
import { userLanguageFeature } from './user-language.reducer';
import { filterNil } from '@onyxx/utility/observables';
import { concatLatestFrom } from '@ngrx/operators';
import { USER_LANGUAGE_STORE_CONFIG } from './user-language-store-config.token';
import { AppQueryParam } from '@onyxx/model/main';

const isValidType = (language: string): language is Language => Object.values(Language).includes(language as Language);

const isValidLanguage = (language: string, availableLanguages: Language[]): language is Language => {
  const isTypeMatch = isValidType(language);
  return isTypeMatch && availableLanguages.includes(language);
};

export class UserLanguageEffects {
  private readonly actions$ = inject(Actions);
  private readonly authFacade = inject(AuthFacade);
  private readonly userStoreFacade = inject(UserStoreFacade);
  private readonly configService = inject(ConfigService);
  private readonly store = inject(Store);
  private readonly configToken = inject(USER_LANGUAGE_STORE_CONFIG);
  private readonly config = inject(this.configToken);

  readonly initializeAvailableLanguages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userLanguageCommonActions.initialize),
      switchMap(() =>
        combineLatest([this.configService.config$, this.userStoreFacade.user$]).pipe(
          // don't re-evaluate when user changes. We don't support dynamic language changes
          // but instead the store is re-initialised in very specific times.
          take(1),
        ),
      ),
      map(([config, user]) => {
        const configuredLanguages = config.config.views.settings.display.availableLanguages;
        const additionalUserLanguages = user?.metaData?.additionalLanguages ?? [];
        // get only unique languages
        const requestedLanguages = [...new Set([...configuredLanguages, ...additionalUserLanguages])];

        const availableLanguages = requestedLanguages.filter(isValidType);
        return userLanguageCommonActions.setAvailableLanguages({ languages: availableLanguages });
      }),
    );
  });

  readonly initializeSelectedLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userLanguageCommonActions.initialize),
      // wait for the available languages to be ready
      switchMap(() => {
        return this.store.select(userLanguageFeature.selectAvailableLanguages).pipe(filterNil(), take(1));
      }),
      concatLatestFrom(() => this.userStoreFacade.user$),
      switchMap(([availableLanguages, user]) => {
        const systemLanguage = window.navigator.language;

        const queryParams = new URLSearchParams(window.location.search);
        const preferredLanguage = queryParams.get(AppQueryParam.Language);

        const systemBaseLanguage = systemLanguage.split('-')[0];
        const systemLanguageBestMatch = isValidLanguage(systemLanguage, availableLanguages) ? systemLanguage : systemBaseLanguage;
        const overrideLanguage =
          Utils.isNotNil(preferredLanguage) && isValidLanguage(preferredLanguage, availableLanguages) ? preferredLanguage : null;

        const selectedLanguage = overrideLanguage || user?.language || systemLanguageBestMatch;
        if (!isValidLanguage(selectedLanguage, availableLanguages)) {
          return of(userLanguageCommonActions.setSelectedLanguage({ language: availableLanguages[0] }));
        }

        return of(userLanguageCommonActions.setSelectedLanguage({ language: selectedLanguage }));
      }),
    );
  });

  readonly setAngularLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userLanguageCommonActions.setSelectedLanguage),
      switchMap(({ language }) => {
        return defer(() => this.config.onLoadLanguage(language));
      }),
      map(() => userLanguageCommonActions.initializeDone()),
    );
  });

  /**
   * When the authenticate state changes, the calculated language settings are invalid and should be re-calculated
   */
  readonly clear$ = createEffect(() => {
    return this.authFacade.isAuthenticated$.pipe(
      distinctUntilChanged(),
      map(() => {
        return userLanguageCommonActions.reload();
      }),
    );
  });

  readonly initialize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userLanguageCommonActions.reload),
      concatLatestFrom(() => this.authFacade.isAuthenticated$),
      switchMap(([, isAuthenticated]) => {
        // depending on the if it is an authenticated user or not, we wait for the user to load or not be available any more
        if (isAuthenticated) {
          return this.userStoreFacade.user$.pipe(filterNil(), take(1));
        }
        return this.userStoreFacade.user$.pipe(
          filter((user) => Utils.isNil(user)),
          take(1),
        );
      }),
      map(() => {
        return userLanguageCommonActions.initialize();
      }),
    );
  });
}
