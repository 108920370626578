import * as moment from 'moment';

import { GoalChartComponent } from './goal-chart.component';

import * as Highcharts from 'highcharts';

export const chartOptions = (_this: GoalChartComponent): Highcharts.Options => {
  return {
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: 'spline',
      height: '228px',
      backgroundColor: 'transparent',
      spacing: [24, 0, 24, 0],
      events: {
        load: function () {
          (this.yAxis[0] as any).plotLinesAndBands.forEach(function (lineOrBand) {
            const svg = lineOrBand.svgElem;
            if (!svg || !svg.d || !svg.d.length) return;

            const dArr = svg.d.split(' ');
            const additionalHeight = -2;

            dArr[dArr.length - 2] = Number(dArr[dArr.length - 2]) + additionalHeight;

            svg.attr({
              d: dArr.join(' '),
            });
          });
        },
        render: function () {
          // High chart does not position the yAxis labels correctly when using a custom font. This workaround
          // checks the expected and real widths of the yAxis labels and adjusts the position.
          //
          // This workaround does not work with HTML rendered labels.
          //
          // To be able to get the real and expected widths, we use the internal properties not exported in the
          // highcharts types. This might break when upgrading highcharts.
          try {
            const labelGroupElement: SVGElement = this.yAxis[0]['labelGroup'].element;
            const realYAxisWidth = labelGroupElement.getBoundingClientRect().width;
            const measuredYAxisWidth = this.yAxis[0]['maxLabelLength'];

            const widthDelta = realYAxisWidth - measuredYAxisWidth;
            labelGroupElement.setAttribute('style', `transform: translate(-${widthDelta}px, 0)`);
          } catch {
            // swallow exceptions to prevent the chart from crashing if the internal structure changes
          }
        },
      },
      style: {
        fontFamily: 'VAG Rounded Next',
      },
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: '',
      },
      crosshair: {
        color: 'var(--color-goal-chart-crosshair)',
        width: 1,
        zIndex: 8,
      },
      visible: true,
      labels: {
        enabled: false,
      },
      lineColor: 'transparent',
      tickWidth: 0,
      plotLines: [
        {
          width: 0,
          value: _this.dateNow,
          dashStyle: 'Dash',
          zIndex: 9,
          label: {
            style: { color: 'var(--color-goal-chart-labels)', fontSize: '10px', fontWeight: 'regular', textOverflow: 'clip' },
            text: $localize`:@@goal-chart.x-axis.now:Now`,
            rotation: 0,
            verticalAlign: 'bottom',
            align: 'left',
            y: 24,
          },
        },
        {
          width: 0,
          value: Number(moment().add(5, 'days').format('x')),
          dashStyle: 'Dash',
          zIndex: 9,
          label: {
            style: { color: 'var(--color-goal-chart-labels)', fontSize: '10px', fontWeight: 'regular', textOverflow: 'clip' },
            text: 'X ' + $localize`:@@goal-chart.x-axis.year:year`,
            rotation: 0,
            verticalAlign: 'bottom',
            align: 'right',
            y: 24,
          },
        },
      ],
    },
    yAxis: {
      title: {
        text: '',
      },
      min: 0,
      gridLineDashStyle: 'Dot',
      gridLineColor: 'var(--color-goal-chart-grid-line)',
      minPadding: 0,
      // maxPaddig: 0,
      labels: {
        style: { color: 'var(--color-goal-chart-labels)', fontSize: '10px', fontWeight: 'regular', textOverflow: 'clip' },
        format: '€ {text}',
        x: 6,
      },
      tickAmount: 5,
      tickPixelInterval: 45,
      opposite: true,
    },
    legend: {
      enabled: false,
      align: 'left',
      padding: 0,
      margin: 0,
      itemMarginTop: 50,
      itemMarginBottom: 8,
      itemStyle: {
        color: 'var(--color-goal-chart-labels)',
        fontSize: '10px',
        fontWeight: 'regular',
      },
      symbolRadius: 3,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#000000',
      // crosshairs: false,
      hideDelay: 300,
      borderWidth: 0,
      shadow: false,
      padding: 0,
      headerFormat: '',
      pointFormat: '',
      shared: true,
      valuePrefix: '€',
    },
    plotOptions: {
      series: {
        turboThreshold: 0,
        marker: {
          enabled: false,
          lineWidth: 3,
          radius: 4,
          symbol: 'circle',
        },
        states: {
          hover: {
            lineWidth: 0,
          },
        },
        events: {},
      },
    },
    series: [
      {
        type: 'arearange',
        data: undefined,
        lineWidth: 1,
        fillOpacity: 1,
        color: 'var(--color-goal-chart-realistic)',
        zIndex: 1,
        zoneAxis: 'x',
        animation: {
          duration: 800,
          easing: 'easeOut',
        },
        states: {
          hover: {
            lineWidthPlus: 0,
          },
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      {
        type: 'arearange',
        data: undefined,
        lineWidth: 0,
        fillOpacity: 1,
        color: 'var(--color-goal-chart-unrealistic)',
        zIndex: 0,
        zoneAxis: 'x',
        animation: {
          duration: 800,
          easing: 'easeOut',
        },
        states: {
          hover: {
            lineWidthPlus: 0,
          },
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    ],
  };
};
