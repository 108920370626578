import { Person } from '@semmie/models/bi/person';
import { BaseModel } from '@semmie/models/_abstract';
import { iOrganisationPerson } from '@semmie/schemas';
import { Address } from '@onyxx/model/address';
import { KvkForm } from '@semmie/schemas/bi/kvk/kvk-form';
import { SignAuthority, UboType, iOrganisation, iOrganisationBasic, iOrganisationQuestionnaires } from '@semmie/schemas/bi/organisation';
import { Utils } from '@onyxx/utility/general';
import { iCountry } from '@onyxx/model/countries';

export class Organisation extends BaseModel<iOrganisation> implements iOrganisation {
  id: string;
  address: Address;
  title?: string;
  kvk_number: string;
  kvk_lei: string;
  kvk_form: KvkForm;
  meta: { data?: string };
  organisation_people: iOrganisationPerson[];
  organisations: iOrganisationBasic[];
  country: iCountry;
  question_turnover: number;
  question_equity: number;
  question_source_of_wealth: number;
  question_source_of_investment: number;
  question_balance: number;
  question_equity_invest_percentage: number;
  question_profit_decrease: number;
  question_equity_invest_obligation: number;
  question_equity_debt_ubo: number;
  question_assets_pawned: number;
  question_equity_invest_pension: number;
  question_holding: boolean;
  question_participations: boolean;
  question_group: number;
  question_characteristic: number;
  question_activities: number;
  question_industry: number;
  question_interest: boolean;
  question_interest_answer?: string;
  question_employees: number;
  question_participations_count: number;
  question_sign_authority: SignAuthority;
  question_ubo: UboType;
  questionnaires: iOrganisationQuestionnaires;
  structure_document_needed: boolean;
  finished_at: string;

  constructor(props?: Partial<Organisation>) {
    super(props);

    if (props?.organisation_people) {
      this.organisation_people = props.organisation_people.map((p) => {
        p.person = new Person(p.person);
        p.share = p.share ?? '0'; //set share to 0 if it doesn't exist
        return p;
      });
    }

    /** @deprecated: meta data as fallback for old data */
    if (Utils.isNil(props?.question_sign_authority) && Utils.isNotNil(this.metaData.signAuthority)) {
      this.question_sign_authority = this.metaData.signAuthority;
    }

    /** @deprecated: meta data as fallback for old data and map to new enum */
    if (Utils.isNil(props?.question_ubo) && Utils.isNotNil(this.metaData.uboType)) {
      switch (this.metaData.uboType) {
        case 'fully-owner':
          this.question_ubo = UboType.FULLY_OWNER;
          break;
        case 'ubo':
          this.question_ubo = UboType.UBO;
          break;
        case 'no-ubo':
          this.question_ubo = UboType.NO_UBO;
          break;
      }
    }
  }

  get metaData(): any {
    return JSON.parse(this.meta?.data ?? '{}');
  }

  set metaData(value: object) {
    if (!this.meta) this.meta = {};
    this.meta['data'] = JSON.stringify(value);
  }

  get finished(): boolean {
    return !!this.finished_at;
  }

  get hasLei(): boolean {
    return !!this.kvk_lei;
  }
}
