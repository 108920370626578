import { inject, Injectable } from '@angular/core';
import { KvkForm } from '@semmie/schemas/bi/kvk/kvk-form';
import { QuestionnaireSubject } from '@onyxx/model/questionnaire';
import { OrganisationService } from '@semmie/services';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserRole } from '@onyxx/model/user';

import { BaseOnboardingService, NavigationCallback } from './__abstract/base-onboarding.service';
import { AccountHelpers } from '@onyxx/model/account';
import { SignAuthority } from '@semmie/schemas/bi/organisation';
import { InvitationStep } from '@semmie/models/bi/invitation';
import { MainRouteNames } from '@onyxx/model/main';
import { OnboardingRouteNames } from '@semmie/views/onboarding/onboarding.common';
import { PersonHelpers } from '@onyxx/model/person';

@Injectable({
  providedIn: 'root',
})
export class OrganisationOnboardingService extends BaseOnboardingService {
  private organisationService = inject(OrganisationService);

  handleOnboarding(navigateCallback: NavigationCallback): void {
    this.hasFinishedAccount()
      .pipe(
        switchMap((finished) => this.handleFinishedAccount(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedPerson(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedIdentification(navigateCallback, finished)),
        switchMap((finished) => {
          if (!this.isAdvisorOnboarding) {
            return this.handleFinishedQuestionnaire(navigateCallback, finished);
          }
          return this.handleFinishedOrganisation(navigateCallback, finished);
        }),
        switchMap((finished) => this.handleFinishedOrganisation(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedOrganisationQuestionnaire(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedStrategy(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedPlan(navigateCallback, finished)),
        switchMap((finished) => this.handleFinishedFirstPayment(navigateCallback, finished)),
        switchMap((finished) => {
          if (this.accountPerson.isInvitee()) {
            return this.inviteeOnboarding(navigateCallback, finished);
          } else {
            return this.initiatorOnboarding(navigateCallback, finished);
          }
        }),
      )
      .subscribe(() => {
        navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.AccountCreated]);
      });
  }

  override hasFinishedPerson(): Observable<boolean> {
    if (
      this.multiLingualFeatures$$().organisationChecks &&
      !AccountHelpers.allowedToOpen(this.account.kind, PersonHelpers.contractCountry(this.person ?? {}))
    ) {
      this.currentStep.next('nationality');
      return of(false);
    }

    return super.hasFinishedPerson();
  }

  override handleFinishedIdentification(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean | never> {
    if (!finished) {
      navigateCallback([
        ...this.onboardingPathPrefix(this.account.id),
        OnboardingRouteNames.Identification,
        {
          step: 'identification_type',
        },
      ]);

      return EMPTY;
    }

    return this.hasFinishedQuestionnaire([QuestionnaireSubject.KNOWLEDGE_EXPERIENCE]) || this.user?.role === UserRole.Advisor;
  }

  override handleFinishedQuestionnaire(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean | never> {
    if (!finished) {
      this.currentStep.next('questionnaire');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Questionnaire], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return this.hasFinishedOrganisation();
  }

  handleFinishedOrganisation(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean | never> {
    if (!finished) {
      this.currentStep.next('organisation');

      const lastActiveStep = this.organisationService.calculateLastActiveStep(this.account.organisation, this.user);

      navigateCallback(
        [
          ...this.onboardingPathPrefix(this.account.id),
          OnboardingRouteNames.Organisation,
          lastActiveStep?.part,
          lastActiveStep?.step ? { step: lastActiveStep?.step } : {},
        ],
        {
          clearOnboardingNavigationStack: true,
        },
      );

      return EMPTY;
    }

    return this.hasFinishedQuestionnaire([QuestionnaireSubject.RISK_APPETITE, QuestionnaireSubject.SUSTAINABILITY_PREFERENCE]);
  }

  handleFinishedOrganisationQuestionnaire(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean | never> {
    if (!finished) {
      this.currentStep.next('questionnaire');

      navigateCallback(
        [...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Questionnaire, this.account?.organisation?.id],
        {
          clearOnboardingNavigationStack: true,
        },
      );

      return EMPTY;
    }

    return this.hasFinishedStrategy();
  }

  hasFinishedOrganisation(): Observable<boolean> {
    return of(this.account.organisation?.finished ?? false);
  }

  handleFinishedFirstPayment(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean | never> {
    if (!finished) {
      this.currentStep.next('payment');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'first' }], {
        clearOnboardingNavigationStack: true,
      });
      return EMPTY;
    }

    if (this.accountPerson.isInvitee()) {
      return this.hasBeenWidValidated();
    }

    return this.hasFinishedSendingInvitation();
  }

  handleInvitationCompleted(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean | never> {
    if (!finished) {
      this.currentStep.next('invite');

      if (this.account.invitation?.id) {
        navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Invitation, this.account.invitation?.id], {
          clearOnboardingNavigationStack: true,
        });
      } else {
        navigateCallback(['./', MainRouteNames.Onboarding, this.account.id]);
      }

      return EMPTY;
    }

    return this.hasFinishedSigning('other');
  }

  handleInviteeHasSigned(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean | never> {
    if (!finished) {
      return EMPTY;
    }

    return this.hasFinishedSummary();
  }

  handleCompletedWidPayment(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean | never> {
    if (!finished) {
      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Payment, { step: 'wid' }], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return this.hasFinishedSummary();
  }

  override hasFinishedSummary(): Observable<boolean> {
    return of(AccountHelpers.hasSigned(this.account, this.user?.id));
  }

  override handleFinishedSendingInvitation(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean | never> {
    if (!finished) {
      this.currentStep.next('invite');

      navigateCallback([...this.onboardingPathPrefix(this.account.id), OnboardingRouteNames.Invitation, { step: InvitationStep.INVITE }], {
        clearOnboardingNavigationStack: true,
      });

      return EMPTY;
    }

    return this.hasFinishedSigning('other');
  }

  override hasFinishedSendingInvitation(): Observable<boolean> {
    if (
      this.accountAdditionalData?.skipInvitation ||
      this.account?.organisation?.question_sign_authority === SignAuthority.FULLY ||
      AccountHelpers.hasAdvisor(this.account)
    ) {
      return of(true);
    }

    if (!this.account?.invitation) return of(false);

    if (this.account?.invitation?.hasInvitationBeenAccepted()) {
      return of(true);
    }

    return of(this.account?.invitation?.hasOpenInvitation());
  }

  private initiatorOnboarding(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean> {
    return this.shouldCheckInvitation().pipe(
      switchMap((shouldInvite) => (shouldInvite ? this.handleInvitation(navigateCallback, finished) : of(true))),
      switchMap((finished) => this.handleInviteeHasSigned(navigateCallback, finished)),
      switchMap((finished) => this.handleFinishedSummary(navigateCallback, finished)),
      switchMap((finished) => this.handleFinishedSigning(navigateCallback, finished)),
    );
  }

  private inviteeOnboarding(navigateCallback: NavigationCallback, finished: boolean): Observable<boolean> {
    return this.handleCompletedWidPayment(navigateCallback, finished).pipe(
      switchMap((finished) => this.handleFinishedSummary(navigateCallback, finished)),
      switchMap((finished) => this.handleFinishedSigning(navigateCallback, finished)),
    );
  }

  private handleInvitation(navigateCallback: NavigationCallback, finished: boolean) {
    return this.handleFinishedSendingInvitation(navigateCallback, finished).pipe(
      switchMap((finished) => this.handleInvitationCompleted(navigateCallback, finished)),
    );
  }

  private shouldCheckInvitation(): Observable<boolean> {
    if (AccountHelpers.hasAdvisor(this.account)) {
      return of(
        this.account.organisation?.question_sign_authority !== SignAuthority.FULLY &&
          this.account?.organisation?.kvk_form !== KvkForm.eenmanszaak &&
          AccountHelpers.hasMultipleRepresentatives(this.account),
      );
    }
    return of(
      this.account?.organisation?.question_sign_authority !== SignAuthority.FULLY &&
        this.account?.organisation?.kvk_form !== KvkForm.eenmanszaak,
    );
  }
}
