import { Directive, HostListener, Input } from '@angular/core';

import { PlatformService } from '@semmie/services/platform/platform.service';

@Directive({
  selector: '[surpressDoubleTap]',
  standalone: false,
})
export class SurpressDoubleTapDirective {
  @Input() surpressOn: 'ios' | 'android' | null = null;

  private readonly treshold = 350;
  private lastTap = 0;

  constructor(private platformService: PlatformService) {}

  @HostListener('touchstart', ['$event'])
  onTouch($event: TouchEvent) {
    if (!this.platformService.isRunningOn(this.surpressOn ?? 'app')) return;
    // devnote: In a rare edge-case event.timeStamp returns 0, should be revisited later
    const tapTimestamp = Math.floor(Date.now());
    const isDoubleTap = this.lastTap + this.treshold > tapTimestamp;

    if (isDoubleTap) {
      $event.preventDefault();
    }

    this.lastTap = tapTimestamp;
  }
}
