import { ChangeDetectionStrategy, Component, effect, ElementRef, OnDestroy, output, viewChild } from '@angular/core';

@Component({
  selector: 'onyxx-content-to-string',
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <template #template>
    <ng-content></ng-content>
  </template>`,
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
})
export class ContentToStringComponent implements OnDestroy {
  valueChanges = output<string>();

  element$$ = viewChild.required<ElementRef<HTMLTemplateElement>>('template');

  private readonly mutationObserver = new MutationObserver(() => {
    this.onMutation();
  });

  constructor() {
    effect(() => {
      this.mutationObserver.disconnect();
      this.mutationObserver.observe(this.element$$().nativeElement.content, {
        childList: true,
        characterData: true,
        subtree: true,
      });
      this.valueChanges.emit(this.elementValue());
    });
  }

  ngOnDestroy(): void {
    this.mutationObserver.disconnect();
  }

  private onMutation() {
    this.valueChanges.emit(this.elementValue());
  }

  private elementValue() {
    return this.element$$().nativeElement.content.textContent?.trim() ?? '';
  }
}
