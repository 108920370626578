import { HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';

import { DynamoDBMarshallService } from '@semmie/services/aws/dynamodb/dynamodb-marshall.service';

import { environment } from '../../../environments/environment';
import { iConfiguration } from '@semmie/schemas/bi/configuration/configuration';
import { HTTP_IMPLEMENTATION, SKIP_AUTHENTICATION } from '@onyxx/model/http';

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

type PartialConfiguration = DeepPartial<iConfiguration>;

@Injectable()
export class ConfigProvider {
  private readonly http = inject(HTTP_IMPLEMENTATION);
  private readonly dynamoMarshall = inject(DynamoDBMarshallService);

  get() {
    return this.http.get(`${environment.configUrl}`, { context: new HttpContext().set(SKIP_AUTHENTICATION, true) }).pipe(
      map((config) => {
        try {
          // try to unmarshall the configuration, if it fails, it's probably plain JSON
          return this.dynamoMarshall.unmarshall(config) as PartialConfiguration;
        } catch {
          return config as PartialConfiguration;
        }
      }),
    );
  }
}
