import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ProviderToken } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import mustache from 'mustache';

import { iExternalDataFormField } from '@semmie/schemas/components/dynamic-form/form-fields';
import { StoreHelper } from '@semmie/store/store-helper';
import { Utils } from '@onyxx/utility/general';
import { Transformer } from '@semmie/shared/transformer';

import { BaseComponent } from '@semmie/components/_abstract';
import { InterpolateService } from '@semmie/services/interpolate/interpolate.service';

import { environment } from 'environments/environment';

interface HttpResponseType {
  body?: unknown | null;
  headers?: HttpHeaders;
  status?: number;
  statusText?: string;
  url?: string;
}

@Component({
  selector: 'semmie-external-data',
  template: '',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  standalone: false,
})
export class ExternalDataComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() url: iExternalDataFormField['url'];
  @Input() store: iExternalDataFormField['store'];
  @Input() service: iExternalDataFormField['service'];
  @Input() form: UntypedFormGroup;
  @Input() formData: Observable<any>;
  @Input() formStatus: Observable<any>;
  @Input() once: iExternalDataFormField['once'];
  @Input() ignoreValues: iExternalDataFormField['ignoreValues'];

  @Output() onExternalDataChange: EventEmitter<{ data: any; pristine?: boolean; loading: boolean }> = new EventEmitter();
  @Output() onExternalDataError: EventEmitter<any> = new EventEmitter();
  @Output() onExternalLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private requestStream$$: BehaviorSubject<HttpResponse<HttpResponseType | null> | null> = new BehaviorSubject(null);

  private readonly REQUEST_DEBOUNCE_TIME = 800;

  private destroyed: Subject<boolean> = new Subject();

  private sourceData: any = {};
  private interpolatedData: any;
  private relevantFields: Array<string> = [];

  constructor(
    private httpService: HttpClient,
    private injector: Injector,
    private interpolateService: InterpolateService,
    private storeHelper: StoreHelper,
  ) {
    super();
  }

  get requestStream$() {
    return this.requestStream$$.asObservable();
  }

  get ready() {
    return this.form.pristine === false;
  }

  ngOnInit(): void {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  reinit(): void {
    this.destroyed.next(true);
    this.initialize();
  }

  private initialize(): void {
    if (this.url) {
      this.callResource();
    } else if (this.store) {
      this.callStore();
    } else if (this.service) {
      this.callService();
    }
  }

  private callStore() {
    this.getStoreData();

    if (!this.once) {
      this.form.valueChanges
        .pipe(
          filter(() => this.ready),
          debounceTime(100),
          distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
          takeUntil(this.destroyed),
        )
        .subscribe(() => {
          this.getStoreData();
        });
    }
  }

  private getStoreData() {
    if (!this.store) return;

    const { name, source } = this.store;
    const store = this.storeHelper.getStoreByName(name);

    if (store?.[source] === undefined) {
      console.error('Cannot find store for source: ', source);
      return;
    }

    const storeValue = this.store.param ? store[source].value[this.store.param] : store[source].value;

    if (Utils.isNil(storeValue) && store[source] instanceof Observable) {
      store[source]
        .pipe(
          take(1),
          tap((value) => this.onExternalDataChange.emit({ data: value, loading: false })),
        )
        .subscribe();
      return;
    }

    this.onExternalDataChange.emit({ data: storeValue, loading: false });
  }

  private callResource() {
    this.onExternalDataChange.emit({ data: null, loading: true });
    this.relevantFields = mustache
      .parse(this.url ?? '')
      .filter((f: any[]) => f[0] === 'name')
      .map((f: any[]) => f[1] || '')
      .filter((f) => f !== 'apiHost');

    if (this.once) {
      this.sourceData = this.form.getRawValue();
      this.fetchData();
    } else {
      this.formData
        .pipe(
          debounceTime(this.REQUEST_DEBOUNCE_TIME),
          filter(() => this.ready),
          distinctUntilChanged((x, y) => {
            const xData = Object.keys(x)
              .map((key) => {
                if (this.relevantFields.includes(key)) return x[key];
              })
              .filter((v) => !!v);

            const yData = Object.keys(y)
              .map((key) => {
                if (this.relevantFields.includes(key)) return y[key];
              })
              .filter((v) => !!v);

            return Utils.isEqual(xData, yData);
          }),
          filter((data) => this.filterRelevantFormData(data)),
          takeUntil(this.destroyed),
        )
        .subscribe((newData) => {
          this.sourceData = newData;
          this.fetchData();
        });
    }
  }

  private callService() {
    if (!this.service) return;

    const { name, method, params } = this.service;
    const service = this.injector.get(name as unknown as ProviderToken<any>);
    this.executeServiceMethod(service, method, params);
  }

  private catchError() {
    return catchError((err: HttpErrorResponse) => {
      this.setRequestError(err);
      this.onExternalDataError.emit(err.error);
      return EMPTY;
    });
  }

  private executeServiceMethod(service: any, method: string, params?: any): any {
    this.sourceData = this.form.getRawValue();

    const interpolatedParams = params ? this.interpolateService.recursivelyInterpolate(params, this.sourceData) : {};
    const methodResponse = service[method]({ ...interpolatedParams });
    const isObservable = methodResponse instanceof Observable;

    if (isObservable) {
      this.onExternalLoadingChange.emit(true);
      methodResponse
        .pipe(
          take(1),
          tap(() => this.setPendingRequest(this.url)),
          this.catchError(),
        )
        .subscribe((result: any) => {
          this.requestStream$$.next(result);
          this.onExternalDataChange.emit({ data: result, pristine: true, loading: false });
        });
    } else {
      this.onExternalDataChange.emit({ data: methodResponse, pristine: true, loading: false });
    }

    if (!this.once) {
      this.relevantFields = this.service?.relevantFields ?? [];

      this.formData
        .pipe(
          filter(() => this.ready),
          distinctUntilChanged(Utils.isEqual),
          filter((data) => this.compareFormData(data, this.sourceData, this.relevantFields)),
          tap(() => {
            this.onExternalLoadingChange.emit(true);
          }),
          debounceTime(this.REQUEST_DEBOUNCE_TIME),
          tap((formData) => (this.sourceData = formData)),
          tap(() => this.setPendingRequest(this.url)),
          switchMap(() => {
            const latestMethodExecution = service[method]({ ...this.interpolateService.recursivelyInterpolate(params, this.sourceData) });
            return latestMethodExecution instanceof Observable
              ? latestMethodExecution.pipe(this.catchError(), take(1))
              : of(latestMethodExecution);
          }),
          this.catchError(),
          takeUntil(this.destroyed),
        )
        .subscribe((result: any) => {
          this.requestStream$$.next(result);
          this.onExternalDataChange.emit({ data: result, pristine: true, loading: false });
        });
    }
  }

  private fetchData(): void {
    const isAbsoluteUrl = this.url?.startsWith('http');
    const interpolatedUrl = mustache.render(this.url ?? '', this.sourceData);
    const url = isAbsoluteUrl ? interpolatedUrl : `${environment.apiUrl}${interpolatedUrl}`;

    this.setPendingRequest(this.url);

    this.httpService
      .get(url, { observe: 'response' })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.setRequestError(err);
          this.onExternalDataError.emit(err.error);
          return EMPTY;
        }),

        take(1),
      )
      .subscribe((res) => {
        this.requestStream$$.next(res);

        const body = this.ignoreValues ? Transformer.recursivelyFilter(res.body as Record<string, unknown>, this.ignoreValues) : res.body;
        this.interpolatedData = body;

        this.onExternalDataChange.emit({ data: this.interpolatedData, pristine: this.once, loading: false });
      });
  }

  private filterRelevantFormData(data) {
    return this.compareFormData(data, this.sourceData, this.relevantFields);
  }

  private compareFormData(data, sourceData, relevantFields: Array<string> = []) {
    if (Object.keys(data).every((key) => data[key] === '')) {
      return false;
    }

    if (relevantFields.length && sourceData) {
      const hasNoChange = relevantFields.every((f) => sourceData[f] === data[f]);

      if (hasNoChange) {
        return false;
      }
    }

    if (JSON.stringify(sourceData || {}).toLowerCase() === JSON.stringify(data || {}).toLowerCase()) {
      return false;
    }

    if (relevantFields.length && this.form && this.form.controls) {
      const fieldsValidity: boolean[] = [];

      for (const key in this.form.controls) {
        if (relevantFields.includes(key)) {
          fieldsValidity.push(this.form.controls[key].valid);
        }
      }

      return fieldsValidity.every((v) => !!v);
    }

    return true;
  }

  private setRequestError(err: HttpErrorResponse) {
    this.requestStream$$.next(
      new HttpResponse({
        url: err.url ?? undefined,
        status: err.status,
        statusText: err.statusText,
        body: err.error,
        headers: err.headers,
      }),
    );
  }

  private setPendingRequest(url?: string): void {
    this.requestStream$$.next(
      new HttpResponse({
        url,
        status: 0,
        statusText: 'PENDING',
        body: null,
        headers: undefined,
      }),
    );
  }
}
