/* eslint-disable quotes */
import { DynamicField, DynamicFieldType, iDynamicForm, iExternalDataFormField } from '@semmie/schemas/components/dynamic-form';
import {
  ExternalDataSource,
  iDropdownFormField,
  iGroupFormField,
  iTextBoxFormField,
} from '@semmie/schemas/components/dynamic-form/form-fields';

export const PersonEmploymentForm = (): iDynamicForm => ({
  name: 'onboarding_person_employment',
  reference: 'employment',
  descriptionSize: 'base_2',
  titleSize: 'h2',
  legacyEventId: 'person-profession',
  title: $localize`:@@form.person-profession.title:Profession`,
  flat: true,
  fields: [
    {
      name: 'questions_src',
      type: DynamicFieldType.ExternalData,
      once: true,
      store: {
        name: 'QuestionsStore',
        source: ExternalDataSource.Questions,
      },
    } as iExternalDataFormField as DynamicField,
    {
      name: 'question_employment_status',
      type: DynamicFieldType.Dropdown,
      label: $localize`:@@form.field.question-employment-status.label:What is your employment situation? I am...`,
      placeholder: $localize`:@@form.field.question-employment-status.placeholder:Make a choice`,
      options: {
        source: 'questions_src.person.question_employment_status',
        label: 'title',
        value: 'value',
      },
      validations: ['required'],
    } as iDropdownFormField as DynamicField,
    {
      name: 'question_industrial_classification_branch',
      type: DynamicFieldType.Dropdown,
      label: {
        translation: {
          '6': $localize`:@@onboarding.person.employment.fields.industry.past:In which industry were you last employed?`,
          '7': $localize`:@@onboarding.person.employment.fields.industry.past:In which industry were you last employed?`,
          default: $localize`:@@onboarding.person.employment.fields.industry.present:In which industry are you employed?`,
        },
        param: 'question_employment_status',
      },
      placeholder: $localize`:@@form.field.question-industrial-classification-branch.placeholder:Make a choice`,
      valuePath:
        'questions_src.person.question_industrial_classification[?value == `{{ question_industrial_classification }}`].category | [0]',
      options: {
        source: 'questions_src.person.question_industrial_classification[*].category',
        label: '$',
        value: '$',
        projection: ['unique'],
      },
      conditions: ['question_employment_status != null'],
      validations: ['required'],
    } as iDropdownFormField as DynamicField,
    {
      name: 'question_industrial_classification',
      type: DynamicFieldType.Dropdown,
      label: {
        translation: {
          '6': $localize`:@@onboarding.person.employment.fields.sector.past:Did you work in one of these sectors at that time?`,
          '7': $localize`:@@onboarding.person.employment.fields.sector.past:Did you work in one of these sectors at that time?`,
          default: $localize`:@@onboarding.person.employment.fields.sector.present:Are you working in one of these sectors?`,
        },
        param: 'question_employment_status',
      },
      placeholder: $localize`:@@form.field.question-industrial-classification.placeholder:Make a choice`,
      options: {
        source: "questions_src.person.question_industrial_classification[?category == '{{ question_industrial_classification_branch }}']",
        label: 'title',
        value: 'value',
        projection: ['unique'],
      },
      conditions: ['question_industrial_classification_branch != null'],
      hidden: [
        "questions_src.person.question_industrial_classification[?category == '{{ question_industrial_classification_branch }}'] | length(@) > `1`",
      ],
      preselect: true,
      validations: ['required'],
    } as iDropdownFormField as DynamicField,
    {
      name: 'question_industrial_classification_answer',
      type: DynamicFieldType.Text,
      label: $localize`:@@form.field.question-industrial-classification-answer.label:Namely`,
      placeholder: $localize`:@@form.field.question-industrial-classification-answer.placeholder:Fill in an industry`,
      conditions: ["question_industrial_classification_branch == 'Anders'"],
      validations: ['required'],
    } as iTextBoxFormField as DynamicField,
    {
      name: 'question_insider',
      type: DynamicFieldType.Group,
      layout: 'toggle',
      cardStyle: 'neutralElevated',
      label: $localize`:@@form.field.question-insider.label:Insider`,
      description: $localize`:@@form.field.question-insider.description:Do you work in the financial sector or at a publicly traded company, and has your employer designated you as an 'insider'?`,
      nested: false,
      fields: [
        {
          name: 'question_insider_answer',
          type: DynamicFieldType.Text,
          label: $localize`:@@form.field.question-insider.answer.label:Yes, at`,
          placeholder: $localize`:@@form.field.question-insider.answer.placeholder:Yes, at`,
          conditions: ['question_insider'],
          validations: ['required'],
        } as iTextBoxFormField as DynamicField,
      ],
    } as iGroupFormField as DynamicField,
    {
      name: 'question_interest',
      type: DynamicFieldType.Group,
      layout: 'toggle',
      cardStyle: 'neutralElevated',
      label: $localize`:@@form.field.question-interest.label:Interest of more than 1%`,
      description: $localize`:@@form.field.question-interest.description:Do you hold a (direct or indirect) interest of more than 1% in one or more publicly traded companies?`,
      nested: false,
      fields: [
        {
          name: 'question_interest_answer',
          type: DynamicFieldType.Text,
          label: $localize`:@@form.field.question-interest.answer.label:Yes, in`,
          placeholder: $localize`:@@form.field.question-interest.answer.placeholder:Yes, in`,
          conditions: ['question_interest'],
          validations: ['required'],
        } as iTextBoxFormField as DynamicField,
      ],
    } as iGroupFormField as DynamicField,
    {
      name: 'question_pep',
      type: 'group',
      layout: 'toggle',
      cardStyle: 'neutralElevated',
      label: $localize`:@@form.field.question-pep.label:Prominent political position`,
      description: $localize`:@@form.field.question-pep.description:Have you, or one of your relatives, held a prominent political function in the last 12 months?`,
      nested: false,
      fields: [
        {
          name: 'question_pep_name',
          type: DynamicFieldType.Text,
          label: $localize`:@@form.field.question-pep.name.label:Name`,
          placeholder: $localize`:@@form.field.question-pep.name.placeholder:Name`,
          conditions: ['question_pep'],
          validations: ['required', 'nonumbers'],
        } as iTextBoxFormField as DynamicField,
        {
          name: 'question_pep_function',
          type: DynamicFieldType.Text,
          label: $localize`:@@form.field.question-pep.function.label:Position`,
          placeholder: $localize`:@@form.field.question-pep.function.placeholder:Position`,
          conditions: ['question_pep'],
          validations: ['required'],
        } as iTextBoxFormField as DynamicField,
        {
          name: 'question_pep_function_description',
          type: DynamicFieldType.Text,
          label: $localize`:@@form.field.question-pep.function-description.label:Job Description`,
          placeholder: $localize`:@@form.field.question-pep.function-description.placeholder:Job Description`,
          conditions: ['question_pep'],
          validations: ['required'],
        } as iTextBoxFormField as DynamicField,
      ],
    } as iGroupFormField as DynamicField,
  ],
});
