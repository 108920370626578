import { Language } from '@onyxx/model/user-language';

// Return the available formal languages for the country that is given (in iso3 format)
export const formalLanguagesByCountry = (country: string) => {
  switch (country) {
    case 'BEL':
      return [Language.nl, Language.fr];
    default:
      return [Language.nl];
  }
};
