import { inject, Injectable, signal } from '@angular/core';
import { Intercom } from '@capacitor-community/intercom';
import { ChatService } from './chat.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, concatMap, defer, filter, firstValueFrom, map, of } from 'rxjs';
import { UserLanguageStoreFacade } from '@onyxx/store/user-language';

@Injectable({
  providedIn: 'root',
})
export class ChatNativeService implements ChatService {
  private readonly userLanguageStoreFacade = inject(UserLanguageStoreFacade);

  /**
   * TODO: Support intercom push notifications (FET-4269)
   * * https://app.clickup.com/t/2559043/FET-4269
   */

  private readonly userLanguage$ = combineLatest([
    this.userLanguageStoreFacade.ready$,
    this.userLanguageStoreFacade.selectedLanguage$,
  ]).pipe(
    filter(([ready]) => ready),
    map(([, language]) => {
      return language;
    }),
  );

  private readonly chatIsInitialized$$ = signal(false);

  constructor() {
    this.userLanguage$
      .pipe(
        takeUntilDestroyed(),
        concatMap((language) => {
          if (!this.chatIsInitialized$$()) return of(null);

          return defer(async () => {
            await Intercom.updateUser({ languageOverride: language });
          });
        }),
      )
      .subscribe();
  }

  async initialize(intercomUserId: string | null): Promise<void> {
    this.chatIsInitialized$$.set(false);
    await this.logout();

    try {
      if (intercomUserId) {
        await Intercom.registerIdentifiedUser({ userId: intercomUserId });
      } else {
        await Intercom.registerUnidentifiedUser();
      }

      const language = await firstValueFrom(this.userLanguage$);
      await Intercom.updateUser({ languageOverride: language });
    } catch (err) {
      console.warn('Intercom login failed', err);
    }

    this.chatIsInitialized$$.set(true);
  }

  async openChat(): Promise<void> {
    await Intercom.displayMessenger().catch((err) => console.warn('Intercom open chat failed', err));
  }

  async closeChat(): Promise<void> {
    await Intercom.hideMessenger().catch((err) => console.warn('Intercom close chat failed', err));
  }

  async logout(): Promise<void> {
    await Intercom.logout().catch((err) => console.warn('Intercom logout failed', err));
  }
}
