import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MagazineArticle } from '@onyxx/model/magazine';

import { BaseComponent } from '@semmie/components/_abstract';
import { PlatformService } from '@semmie/services/platform/platform.service';

@Component({
  selector: 'semmie-magazine-card',
  templateUrl: './magazine-card.component.html',
  styleUrls: ['./magazine-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MagazineCardComponent extends BaseComponent {
  private readonly platformService = inject(PlatformService);

  @Input({ required: true }) item: MagazineArticle;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  playIcon = this.platformService.resolveAssetPath('img/icons/play.svg');

  onClickHandler(): void {
    this.onClick.emit(this.item);
  }
}
