import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseComponent } from '@semmie/components/_abstract';
import { Icon } from '@semmie/schemas';
import { SharedModule } from '@semmie/shared/shared.module';
import { IconComponent } from '@semmie/components/presentational/core/icon/icon.component';
import { LabelModule } from '@semmie/components/presentational/core/label/label.module';

export enum StatusColor {
  NEUTRAL = 'neutral',
  PRIMARY = 'primary',
  WARNING = 'warning',
  DANGER = 'danger',
}

@Component({
  imports: [SharedModule, IconComponent, LabelModule],
  selector: 'semmie-status',
  template: `<div *ngIf="!hideIndicator" class="status__indicator-holder">
      <span class="status__indicator" [ngClass]="statusColor">
        <semmie-icon [icon]="Icon.DOT" [size]="'xxxxs'"></semmie-icon>
      </span>
    </div>
    <div *ngIf="label" class="status__title" [ngClass]="theme">
      <semmie-label [size]="'base_3'" [text]="label"></semmie-label>
    </div>`,
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent extends BaseComponent {
  @Input() label: string | undefined;
  @Input() statusColor?: StatusColor;
  @Input() theme?: 'light' | 'dark';
  @Input() hideIndicator = false;

  readonly Icon = Icon;
}
