import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseComponent } from '@semmie/components/_abstract';

@Component({
  selector: 'semmie-tile',
  template: '',
  styleUrls: ['./tile.component.scss'],
  standalone: false,
})
export class TileComponent extends BaseComponent {
  @Input() text: string;
  @Input() image: string;

  @Output() onClick: EventEmitter<boolean> = new EventEmitter();

  constructor() {
    super();
  }

  onClickHandler(): void {
    this.onClick.emit(true);
  }
}
