/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Injector, OnDestroy, OnInit, inject } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { createGesture, NavParams, Platform } from '@ionic/angular';

import { COMPONENT_NAME } from '@semmie/tokens/component.tokens';
import { iConfigurationView } from '@semmie/schemas/bi/configuration/configuration';
import { NavigationService } from '@semmie/services';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { EventsService } from '@semmie/services/events/events.service';
import { ConfigStore } from '@semmie/store/config/config.store';

import { iBaseViewComponent } from '@semmie/schemas/components/__abstract/base-view';
import { Utils } from '@semmie/shared/utils';

let uniqueComponentId = 0;

@Component({
  selector: 'semmie-base-view-component',
  template: '<ng-content></ng-content>',
  standalone: false,
})
export abstract class BaseViewComponent implements iBaseViewComponent, OnInit, OnDestroy {
  private readonly onBackPressed$$ = new Subject<boolean>();
  private readonly viewDidLeave$$ = new Subject<void>();
  private readonly viewDidEnter$$ = new Subject<void>();
  private readonly destroyed$$ = new Subject<void>();

  readonly viewDidLeave$ = this.viewDidLeave$$.asObservable();
  readonly viewDidEnter$ = this.viewDidEnter$$.asObservable();
  readonly destroyed$ = this.destroyed$$.asObservable();
  readonly onBackPressed$ = this.onBackPressed$$.asObservable();

  protected componentId = ++uniqueComponentId;
  protected componentName = inject(COMPONENT_NAME, { optional: true });

  readonly configStore = inject(ConfigStore);

  viewConfig?: Record<string, iConfigurationView> = this.configStore?.getViewConfig(
    this.componentName?.replace('semmie-', '').replace('-', '.') ?? '',
  );

  protected _platformService = inject(PlatformService);
  protected swipeGesture;
  protected platform = inject(Platform);
  protected navParams = inject(NavParams, { optional: true });
  protected navService = inject(NavigationService);

  protected eventService = inject(EventsService);

  private disableBack: boolean;

  private backButtonSub$: Subscription;

  // todo: remove this constructor after refactoring all the components that inherit from this
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(injector?: Injector) {}

  // TODO(base): Assess if this is still needed since it's empty. Disabling for now to not break other components.
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.viewDidLeave$$.complete();

    this.viewDidEnter$$.complete();

    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  ionViewDidEnter() {
    this.hookBackEvents();

    this.eventService
      .remapEvent(this.componentName, this)
      ?.pipe(
        takeUntil(this.viewDidLeave$),
        filter(Utils.isNonNullOrUndefined),
        switchMap((e) => this.eventService.post(e)),
      )
      .subscribe();

    this.viewDidEnter$$.next();
  }

  ionViewDidLeave() {
    this.unhookBackEvents();

    this.viewDidLeave$$.next();
  }

  isOpenedInAModal(): boolean {
    return !!this.navParams;
  }

  getModalData(): any {
    return this.navParams?.get('modalData');
  }

  back(): void {
    if (this.navService) {
      this.navService.back();
    }
  }

  private hookBackEvents(): void {
    if (this.disableBack) {
      if (this._platformService.isRunningOn('android')) {
        this.backButtonSub$ = this.platform.backButton.subscribeWithPriority(9999, () => {
          this.onBackPressed$$.next(true);
        });
      } else if (this._platformService.isRunningOn('ios')) {
        const elements = document.querySelectorAll('ion-content');

        const element = Array.from(elements)
          .filter((el) => !!el.parentElement?.offsetParent)
          .pop();

        if (Utils.isNonNullOrUndefined(element)) {
          // this has the potential to interfere with the pull to refresh functionality on a given page (iOS and web for sure)
          this.swipeGesture = createGesture({
            el: element,
            threshold: 0,
            gestureName: 'back-gesture',
            gesturePriority: 40.5, // priority of swipe to go back is 40
            onEnd: () => {
              this.onBackPressed$$.next(true);
            },
          });

          this.swipeGesture.enable(true);
        }
      }
    }
  }

  private unhookBackEvents(): void {
    this.backButtonSub$?.unsubscribe();
    this.swipeGesture?.enable(false);
  }
}
