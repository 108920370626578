import { Language } from '../language.enum';

export const getIso3FromLanguage = (language: Language): string => {
  switch (language) {
    case Language.nl:
      return 'NLD';
    case Language.en:
      return 'ENG';
    case Language.fr:
      return 'FRA';
  }
};
